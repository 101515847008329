import React, { Component } from "react";
import "./criteriaColumnNamesRenderer.less";
import onClickOutside from "react-click-outside";
import { Tooltip } from "@mineral/core";
import Resource from './Resource'
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

//Name
const NAME=Resource.get('Name')

class CriteriaColumnNamesRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false,
      expandAdvanced: false,
      value: this.props.selectedValue,
      prevSelectedValue: this.props.selectedValue,
      doSearch: false,
      isAdvancedValue:
        this.props.data.advancedFilters &&
        this.props.data.advancedFilters.indexOf(this.props.selectedValue) !== -1
          ? true
          : false,
    };
    this.listRef = React.createRef();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedValue !== this.props.selectedValue) {
      this.setState({
        showList: false,
        expandAdvanced: false,
        value: nextProps.selectedValue,
        prevSelectedValue: nextProps.selectedValue,
        doSearch: false,
        isAdvancedValue:
          nextProps.data.advancedFilters &&
          nextProps.data.advancedFilters.indexOf(nextProps.selectedValue) !== -1
            ? true
            : false,
      });
    }
  }
  handleSearchTextChange = (e) => {
    let value = e.target.value.replace(/^\s+/g, "");
    value = value.split("Advanced: ").reverse()[0];
    this.setState({
      value: value,
      doSearch: true,
      isAdvancedValue: false,
      expandAdvanced: true,
    });
  };
  handleOptions = (action) => {
    if (action !== undefined) {
      this.setState({
        showList: action,
        expandAdvanced: !action,
        doSearch: !action,
      });
      if (action) {
        setTimeout(() => {
          document.querySelector("#criteria-col-name-0").focus();
        });
      }
    } else if (!this.state.showList) {
      this.setState({ showList: true, expandAdvanced: false, doSearch: false });
      setTimeout(() => {
        document.querySelector("#criteria-col-name-0").focus();
      });
    }
  };
  handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      this.handleClickOutside();
      e.stopPropagation();
    }
  };
  getSearchResults = (data) => {
    let results = [],
      searchTerm = this.state.value.toLowerCase();
    data.forEach((item) => {
      if (item.toLowerCase().indexOf(searchTerm) !== -1) {
        results.push(item);
      }
    });
    return results;
  };
  getListContent = () => {
    let info = [],
      AdvancedContent = [],
      advancedClasses = ["item", "advanced"],
      advancedText = "Advanced";
    let divName = this.props.data.isCsFilter
      ? "deviceFilter"
      : "interfaceFilters";
    if (
      this.props.data.columnNamesInfo &&
      this.props.data.columnNamesInfo.length > 1
    ) {
      let searchResults = this.state.doSearch
        ? this.getSearchResults(this.props.data.columnNamesInfo)
        : this.props.data.columnNamesInfo;
      let basicContent = searchResults.map((item, index) => {
        let contentClasses = ["content"];
        if (item === this.state.value) {
          contentClasses.push("selected");
        }
        return (
          <button
            className="item"
            id={`criteria-col-name-${index}`}
            style={{
              backgroundColor: "transparent",
              border: "none",
              width: "100%",
              textAlign: "start",
            }}
            onClick={() => {
              this.setState({
                value: item,
                prevSelectedValue: item,
                showList: false,
                expandAdvanced: false,
                isAdvancedValue: false,
              });
              this.props.onChange(item, false);
            }}
            onKeyDown={(event) => {
              if (event.key === "ArrowDown") {
                if (index + 1 < searchResults.length) {
                  document
                    .querySelector(`#criteria-col-name-${index + 1}`)
                    .focus();
                } else {
                  document.querySelector(`#criteria-col-name-0`).focus();
                }
                event.stopPropagation();
                event.preventDefault();
              } else if (event.key === "ArrowUp") {
                if (index - 1 >= 0) {
                  document
                    .querySelector(`#criteria-col-name-${index - 1}`)
                    .focus();
                } else {
                  document
                    .querySelector(
                      `#criteria-col-name-${searchResults.length - 1}`
                    )
                    .focus();
                }
                event.stopPropagation();
                event.preventDefault();
              }
            }}
          >
            <div className={contentClasses.join(" ")}>{item}</div>
          </button>
        );
      });
      info.push(...basicContent);
    }
    if (
      this.props.data.advancedFilters &&
      this.props.data.advancedFilters.length > 1
    ) {
      if (this.state.expandAdvanced) {
        advancedClasses.push("expanded");
        let searchResults = this.state.doSearch
          ? this.getSearchResults(this.props.data.advancedFilters)
          : this.props.data.advancedFilters;
        AdvancedContent = searchResults.map((item) => {
          let contentClasses = ["content"];
          if (item === this.state.value) {
            contentClasses.push("selected");
          }
          return (
            <div
              className="item"
              onClick={() => {
                this.setState({
                  value: item,
                  prevSelectedValue: item,
                  showList: false,
                  expandAdvanced: false,
                  isAdvancedValue: true,
                });
                this.props.onChange(item, true);
              }}
            >
              <div className={contentClasses.join(" ")}>{item}</div>
            </div>
          );
        });
      } else if (
        this.props.data.advancedFilters.indexOf(this.state.value) !== -1
      ) {
        advancedText = advancedText + ": " + this.state.value;
      }
      if (info.length > 0 || AdvancedContent.length > 0) {
        info.push(
          <div
            className={advancedClasses.join(" ")}
            onClick={() => {
              this.setState({ expandAdvanced: !this.state.expandAdvanced });
            }}
          >
            {advancedText}
          </div>
        );
      }

      info.push(...AdvancedContent);
    }
    return info;
  };
  handleClickOutside = (evt) => {
    let value, isAdvancedValue;
    if (this.state.showList) {
      if (
        (this.props.data.columnNamesInfo &&
          this.props.data.columnNamesInfo.indexOf(this.state.value) !== -1) ||
        (this.props.data.advancedFilters &&
          this.props.data.advancedFilters.indexOf(this.state.value) !== -1)
      ) {
        value = this.state.value;
        isAdvancedValue = this.state.isAdvancedValue;
      } else {
        value = this.state.prevSelectedValue;
        isAdvancedValue =
          this.props.data.advancedFilters &&
          this.props.data.advancedFilters.indexOf(value) !== -1
            ? true
            : false;
      }

      this.setState({
        showList: false,
        expandAdvanced: false,
        value: value,
        prevSelectedValue: value,
        isAdvancedValue: isAdvancedValue,
      });
      this.props.onChange(value, isAdvancedValue);
    }
  };

  

  render() {
    let value = this.state.value,
      containerClasses = ["criteria-col-name-container"];
    if (this.props.data.heightInc) {
      containerClasses.push("increase");
    }
    if (this.state.isAdvancedValue) {
      value = "Advanced: " + value;
    }
    return (
      <Tooltip arrow title={NAME}>
        <div className={containerClasses.join(" ")}>
          <div >
            <input style={{height:'24px'}}
              id="select-input"
              aria-label="name"
              spellCheck="false"
              onChange={this.handleSearchTextChange}
              onKeyDown={this.handleKeyDown}
              InputProps={{ sx: { height: 24 } }}
              value={value}
              onClick={this.handleOptions}
              onKeyUpCapture={(event) => {
                if (event.key === "Enter") {
                  this.handleOptions();
                }
              }}
            />
            <div className="arrow-down" style={{top: '-4px',position:'absolute',
    marginLeft: '130px'}}><ArrowDropDownIcon 
style={{ transform: 'scale(1.2)', color: '#3272D9' }}
                          fontSize="large"
                          color="#3272D9"/></div>

          </div>
          {this.state.showList ? (
            <div
              className="list-container"
              tabIndex={0}
              onBlur={(event) => {
                if (!this.listRef.current.contains(event.relatedTarget)) {
                  this.handleOptions(false);
                }
              }}
              ref={this.listRef}
            >
              {this.getListContent()}
            </div>
          ) : (
            ""
          )}
        </div>
      </Tooltip>
    );
  }
}

export default onClickOutside(CriteriaColumnNamesRenderer);
