import { Login as loginApi } from './login';
import {
    START_LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SETPRODUCTLIST,
    SETDOIURL
} from './actionTypes'

export function startLogin() {
    return {
        type: START_LOGIN
    }
}

export function loginSuccess(sid,userName) {
    return {
        type: LOGIN_SUCCESS,
        sid: sid,
        userName: userName
    }
}

export function loginFailure(error) {
    return {
        type: LOGIN_FAILURE,
        errorMessage: error.message
    }
}

export function setProductList(productlist) {
    return {
        type: SETPRODUCTLIST,
        productList: productlist
    }
}

export function setDoiUrl(url) {
    return {
        type: SETDOIURL,
        doiurl: url
    }
}

export function login(userName,password) {
    return dispatch => {
        dispatch(startLogin())
        return loginApi.get({
                userName: userName,
                password: password
            })
            .then(response => response.text())
            .then(sid => dispatch(loginSuccess(sid,userName)))
            .catch(function (error) {
                dispatch(loginFailure(error))
            })
    }
}