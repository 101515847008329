
export class LocalStorageManager {

  //Adds a nameSpace and a storage object to localStorage.
  //For example, given localStorage = {"liveAgentStart": "14869234234"}
  //setStorageForNameSpace("OPERATOR_CONSOLE", {"sid": "123xyz", "showSetupWizardAgain": "false"}) results in localStorage = {"OPERATOR_CONSOLE": {"sid": "123xyz", "showSetupWizardAgain": "false"}, "liveAgentStart": "14869234234"}
  static setStorageForNameSpace = (nameSpace, storageObject) => {
    localStorage.setItem(nameSpace, JSON.stringify(storageObject))
  };

  //Returns a JSON object for the storage item with key=nameSpace or null if there is no item with key=nameSpace.
  //For example, given localStorage = {"OPERATOR_CONSOLE": {"sid": "123xyz", "showSetupWizardAgain": "false"}, "liveAgentStart": "14869234234"}
  //the return value for nameSpace="OPERATOR_CONSOLE" is {"sid": "123xyz", "showSetupWizardAgain": "false"}
  static getStorageForNameSpace = (nameSpace) => {
    return JSON.parse(localStorage.getItem(nameSpace))
  };

  //Sets a key value pair within a storage nameSpace.
  //For example, given localStorage = {"OPERATOR_CONSOLE": {"sid": "123xyz", "showSetupWizardAgain": "false"}, "liveAgentStart": "14869234234"}
  //setItem("OPERATOR_CONSOLE", {showSetupWizardAgain, "true"}) results in localStorage = {"OPERATOR_CONSOLE": {"sid": "123xyz", "showSetupWizardAgain": "true"}, "liveAgentStart": "14869234234"}
  static setItem = (nameSpace, objToStore) => {
    const storageObject = this.getStorageForNameSpace(nameSpace)
    let newObj = Object.assign({}, storageObject, objToStore)
    localStorage.setItem(nameSpace, JSON.stringify(newObj))
  };

  //Returns the value for the key within the storage nameSpace.  Returns null if there is no nameSpace.  Returns undefined if the key is not found.
  //For example, given localStorage = {"OPERATOR_CONSOLE": {"sid": "123xyz", "showSetupWizardAgain": "false"}, "liveAgentStart": "14869234234"}
  //getItem("OPERATOR_CONSOLE", "showSetupWizardAgain") returns "false".
  static getItem = (nameSpace, key) => {
    const item = JSON.parse(localStorage.getItem(nameSpace))
    if (item === null) {
      return null
    }
    return item[key]
  };

  //Removes item within a namespace object.
  //For example, given localStorage = {"OPERATOR_CONSOLE": {"sid": "123xyz", "showSetupWizardAgain": "false"}, "liveAgentStart": "14869234234"}
  //removeItem("OPERATOR_CONSOLE", "sid") results in localStorage = {"OPERATOR_CONSOLE": {"showSetupWizardAgain": "false"}, "liveAgentStart": "14869234234"}
  static removeItem = (nameSpace, key) => {
    let storageObject = this.getStorageForNameSpace(nameSpace)
    if (storageObject) {
      delete storageObject[key]
    }
    return this.setStorageForNameSpace(nameSpace, storageObject)
  };

  //Removes all values within a namespace object.
  //For example, given localStorage = {"OPERATOR_CONSOLE": {"sid": "123xyz", "showSetupWizardAgain": "false"}, "liveAgentStart": "14869234234"}
  //removeStorageForNameSpace("OPERATOR_CONSOLE") results in localStorage = {"liveAgentStart": "14869234234"}
  static removeStorageForNameSpace = (nameSpace) => {
    localStorage.removeItem(nameSpace)
  };
};
