import React, { useEffect, useState } from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { Tooltip } from '@mineral/core';
const CustomDataGrid = (props) => {
  const [rows, setRows] = useState([]);
  const [isShowPercentile, setIsShowPercentile] = useState(false);  
  const [selectedRow, setSelectedRow] = useState({});
  const [sortObj,setSortObj]=useState([{ "field": localStorage.getItem("sortField")===null?"source":localStorage.getItem("sortField"), "sort": localStorage.getItem("sortOrder")!==null?localStorage.getItem("sortOrder"):"desc" }]);
  const apiRef = useGridApiRef();

  useEffect(()=>{

   if(localStorage.getItem("sortField")!==null && localStorage.getItem("sortOrder")!==null)
   {
    setSortObj([{ "field": localStorage.getItem("sortField"), "sort": localStorage.getItem("sortOrder") }]);    
    //console.log("sortObj",{ "field": localStorage.getItem("sortField"), "sort": localStorage.getItem("sortOrder") });
   }   

  },[])

  const blankColumns = [    
    {
      field: "source",
      headerName: "Source",
      // width: isColumnVisible ? 200 : 300,
      flex: 1,
      headerClassName: 'legend-header',
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div style={{ textOverflow: "ellipsis", width: "150px", overflow: "hidden" }}>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "qos",
      headerName: "QoS",
      headerClassName: 'legend-header',
      flex: 1,
      
      // width: isColumnVisible ? 200 : 300,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div style={{ textOverflow: "ellipsis", width: "150px", overflow: "hidden" }}>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "target",
      headerName: "Target",
      headerClassName: 'legend-header',
      flex: 1,

      // width: isColumnVisible ? 200 : 300,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div style={{ textOverflow: "ellipsis", width: "150px", overflow: "hidden" }}>{cellValues.value}</div></Tooltip>
      }
    },    
    {
      field: "dataAvg",
      headerName: "Average",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={Number(cellValues.value).toFixed(2)}><div>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "currentAvg",
      headerName: "Sample",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div>{cellValues.value}</div></Tooltip>
      }
    },
    
  ];

  useEffect(() => {
    //apiRef.selectedRow(0)   
    if (props?.data?.length > 0)
      getRows();

      if (props?.chartData?.length > 0)
      getRows();
    //apiRef.current.getR 
    
  }, [props]);

  useEffect(() => {
    if (props?.percentileValue > 0)
      setIsShowPercentile(true);
    else
      setIsShowPercentile(false);
  }, [props?.percentileValue]);  

  const getRows = () => {
    if(!props.isBlank){
    setRows(props.data.map((currentData, index) => {
      let percentileValue = "";      
      if (Number(props.percentileValue) > 0)
        percentileValue = props.percentileValue + " : " + Number(currentData?.Percentile).toFixed(2) + " %"
      return {
        ...currentData,
        id: index,
        currentAvg: currentData.samplingData.filter((sample) => sample.t === props.referenceData)[0]?.avg === 0 ? 0 : (currentData.samplingData.filter((sample) => sample.t === props.referenceData)[0]?.avg) * parseFloat(props.scaledValue) || null,
        percentileValue: percentileValue,
        baselineValue: (props.baselineValue!==undefined && props.baselineValue!==null && props.baselineValue!==NaN)?Number(props.baselineValue).toFixed(2):null,
        trendLineValue:(props.trendLineValue!==undefined && props.trendLineValue!==null && props.trendLineValue!==NaN)?Number(props.trendLineValue).toFixed(2):null,
      };
    }))
  }
  else{
    setRows(props.chartData.map((currentData, index) => {
      let percentileValue = "";      
      if (Number(props.percentileValue) > 0)
        percentileValue = props.percentileValue + " : " + Number(currentData?.Percentile).toFixed(2) + " %"
      return {
        ...currentData,
        id: index,
        currentAvg: null, 
        dataAvg: null,       
      };
    }))
  }
  }

  return <>{!props.isBlank?<DataGrid
    columnVisibilityModel={{
      dataMin: props.isColumnVisible,
      dataMax: props.isColumnVisible,
      dataStdDev: props.isColumnVisible,
      dataAvg: props.isColumnVisible,
      percentileValue: isShowPercentile,
      baselineValue: props.isBaseline,
      trendLineValue: props.isTrendLine,
    }}
    rows={rows}
    rowHeight={25}
    columns={props.columns}
    hideFooter
    disableColumnMenu
    disableSelectionOnClick={false}
    disableColumnSelector
    experimentalFeatures={{ newEditingApi: true }}
    sx={{
      marginTop: '8px',
      '& .MuiDataGrid-columnHeaders': {
        minHeight: '30px !important'
      },
      '& .MuiDataGrid-virtualScroller': {
        marginTop: '35px !important'
      }
    }}
    onRowClick={(data) => {
      //console.log("ROWDATA",data);
      props.selectNode(data.row);
      setSelectedRow(data.row);
      //setSelected
    }}

    onSortModelChange={(model)=>{
      if(model[0].field!=="" && model[0].sort!=="")
      {
      localStorage.setItem("sortField", model[0].field);
      localStorage.setItem("sortOrder", model[0].sort);
      }      
    }}

    initialState={{
      
      sorting: {
        sortModel: sortObj,
      },
    }}


  />: <DataGrid
  
  rows={rows}
  rowHeight={25}
  columns={blankColumns}
  hideFooter
  disableColumnMenu
  disableSelectionOnClick={false}
  disableColumnSelector
  experimentalFeatures={{ newEditingApi: true }}
  sx={{
    marginTop: '8px',
    '& .MuiDataGrid-columnHeaders': {
      minHeight: '30px !important'
    },
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '35px !important'
    }
  }}

/>}</>;
}

export default CustomDataGrid