import {  
  FETCH_PRODUCT_USAGE_METRICS,
  FETCH_PRODUCT_USAGE_METRICS_SUCCESS,
  FETCH_PRODUCT_USAGE_METRICS_FAILURE  
} from "./actionTypes";
import fetchStatusTypes from "../fetchStatusTypes";

const PRODUCT_USAGE_METRICS = (
  state = {
    isFetching: false,
    fetchStatus: fetchStatusTypes.UNDEFINED,
    didInvalidate: false,
    productUsageMetricsResponse:{},
    errorMessage:null,
   
    },
  action
) => {
  switch (action.type) {
   
    case FETCH_PRODUCT_USAGE_METRICS:
      return Object.assign({}, state, {
        isFetching: true,
        fetchStatus: fetchStatusTypes.FETCHING,
        didInvalidate: false,
        productUsageMetricsResponse: {},
        
      });
    case FETCH_PRODUCT_USAGE_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        didInvalidate: false,
        productUsageMetricsResponse:action.productUsageMetricsData
        
      });
    case FETCH_PRODUCT_USAGE_METRICS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.FAIL,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        productUsageMetricsResponse: {},
       
      });          
    default:
      return state;
  }
};
export default PRODUCT_USAGE_METRICS;
