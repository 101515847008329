import React, { useState } from "react";
import {
  ChipPopover,
  Box,
  TextField,
  Typography,
  Tooltip,
  Button,
} from "@mineral/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
// import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import dayjs from 'dayjs';
import { useDispatch } from "react-redux";

const CalendarPicker = props => {

  const { referenceData, setRefLineData, startTime, endTime } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [newTimeStamp, setNewTimeStamp] = React.useState(0);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(false);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    setIsOpen(false);
  };
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState(dayjs(referenceData.t_0));
  return (
    <Tooltip title={props.tooltipValue} open={isOpen}>
      <div onMouseEnter={() => {
        setIsOpen(true);
      }}

        onMouseLeave={() => {
          setIsOpen(false);
        }}

      >
        <ChipPopover
          {...props}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={open}
          ChipProps={{
            ...props.ChipProps,
            label: (<Typography variant="body2" style={{ maxWidth: 'inherit', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value.$d.toString().substr(0, 24)}</Typography>),
            onClick: handleClick,
            style: {
              maxWidth: `${props.isChartExpanded ? '101%' : '130%'}`
            }
          }}

        >

          <Box display="flex" justifyContent="flex-end" padding={1} >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateTimePicker
                displayStaticWrapperAs="desktop"
                ampm={false}
                value={value}
                onChange={(newValue) => {
                  const epochVal = newValue.$d.getSeconds() / 1000;
                  setValue(newValue);
                  const newTimestamp = {};
                  newTimestamp.t_0 = newValue.$d.getTime();
                  setNewTimeStamp(newTimestamp);
                  //setRefLineData(newTimestamp);
                  //console.log(newValue);
                  //setValue(newValue);
                  /*dispatch(setTimeFrameAction("Custom", {
                    startDate: moment(newValue.toString()).valueOf(),
                    endDate: moment(newValue.toString()).valueOf(),
                  }))*/
                }}
                minDateTime={dayjs(startTime)}
                maxDateTime={dayjs(endTime)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box display="flex" justifyContent="flex-end" padding={1} style={{ zIndex: 999999 }}>
            <Button variant="text" color="primary" onClick={() => {
              handleClose();
            }}>CANCEL</Button>
            <Button variant="text" color="primary" onClick={() => {
              handleClose();
              if (newTimeStamp !== 0)
                setRefLineData(newTimeStamp);
            }}>OK</Button>
          </Box>
        </ChipPopover>
      </div>
    </Tooltip>
  );
};

export default CalendarPicker;
