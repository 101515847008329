import {
  SET_GROUP_SORT_PROP,
  SET_GROUP_ID,
  SET_GROUP_IDS,
  REQUEST_GROUP,
  REQUEST_GROUPS,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUP_FAILURE,
  FETCH_GROUPS_FAILURE,
  REQUEST_CABI_DETAILS,
  FETCH_CABI_DETAILS_SUCCESS,
  FETCH_CABI_DETAILS_FAILURE,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  REQUEST_ADD_GROUP,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  RENAME_GROUP_SUCCESS,
  REMOVE_DEVICES_SUCCESS,
  REMOVE_DEVICES_FAILURE,
  SET_SELECTED_CS_IDS,
  ADD_TO_GROUP_SUCCESS,
  ADD_TO_GROUP_FAILURE,
  POLL_GROUPS_START,
  POLL_GROUPS_STOP,
  REMOVE_INTERFACES_FAILURE,
  REMOVE_INTERFACES_SUCCESS,
  ADD_INTERFACES_TO_GROUP_FAILURE,
  ADD_INTERFACES_TO_GROUP_SUCCESS,
  SET_SELECTED_IF_IDS,
  SWITCH_TREE_VIEW_NAV,
  DISABLE_TREE_VIEW_NAV,
  GROUP_DASHBOARD_COMPONENTS_CHANGE,
  REQUEST_TOP_SUB_GROUP,
  FETCH_TOP_SUB_GROUP,
  FETCH_TOP_SUB_GROUP_SUCCESS,
  FETCH_TOP_SUB_GROUP_FAILURE, 
  POLL_TOP_SUB_GROUP_START,
  POLL_TOP_SUB_GROUP_STOP, 

  FILTERED_GROUPTREE_ROWS,
  SAVEDCOLUMNS_LISTVIEW,
  
} from "./actionTypes";
import { Group as groupApi } from "./groups";

export const setGroupSortProp = (prop, order) => {
  return {
    type: SET_GROUP_SORT_PROP,
    prop: prop,
    order: order,
  };
};

export const switchTreeViewNav = (currentNav) => {
  return {
    type: SWITCH_TREE_VIEW_NAV,
    currentTreeViewNav: currentNav,
  };
};

export const disableTreeViewNav = (disabledTreeViewNavs) => {
  return {
    type: DISABLE_TREE_VIEW_NAV,
    disabledTreeViewNavs: disabledTreeViewNavs,
  };
};
export const setGroupId = (id) => {
  return {
    type: SET_GROUP_ID,
    id: id,
  };
};

export const setGroupIds = (ids) => {
  return {
    type: SET_GROUP_IDS,
    ids: ids,
  };
};

export function requestGroup(params) {
  return {
    type: REQUEST_GROUP,
    params: params,
  };
}

export function requestGroups() {
  return {
    type: REQUEST_GROUPS,
  };
}

export function fetchGroupSuccess(json) {
  return {
    type: FETCH_GROUP_SUCCESS,
    data: json,
  };
}

export function fetchGroupsSuccess(json) {
  return {
    type: FETCH_GROUPS_SUCCESS,
    data: json,
  };
}

export function fetchGroupFailure(error) {
  return {
    type: FETCH_GROUP_FAILURE,
    errorMessage: error.message,
  };
}

export function fetchGroupsFailure(error) {
  return {
    type: FETCH_GROUPS_FAILURE,
    errorMessage: error.message,
    error: error.response,
  };
}

export function requestCabiDetails(id) {
  return {
    type: REQUEST_CABI_DETAILS,
    id: id,
  };
}

export function fetchCabiDetailsSuccess(json) {
  return {
    type: FETCH_CABI_DETAILS_SUCCESS,
    data: json,
  };
}

export function fetchCabiDetailsFailure(error) {
  return {
    type: FETCH_CABI_DETAILS_FAILURE,
    errorMessage: error.message,
  };
}
export function fetchCabiDetails(id) {
  return (dispatch) => {
    dispatch(requestCabiDetails(id));
    return groupApi
      .getCabiDetails(id)
      .then((response) => dispatch(fetchCabiDetailsSuccess(response)))
      .catch((error) => dispatch(fetchCabiDetailsFailure(error)));
  };
}
export function addGroup(params) {
  return (dispatch) => {
    dispatch(requestAddGroup());
    return groupApi
      .post(params)
      .then((response) => response.data)
      .then((json) => dispatch(addGroupSuccess(json)))
      .catch((error) => {
        dispatch(addGroupFailure(error));
      });
  };
}
export function requestAddGroup() {
  return {
    type: REQUEST_ADD_GROUP,
  };
}
export function addGroupSuccess(json) {
  return {
    type: ADD_GROUP_SUCCESS,
    json: json,
  };
}

export function renameGroup(group) {
  return (dispatch) => {
    return groupApi
      .put(group)
      .then((response) => response.data)
      .then((json) => dispatch(renameGroupSuccess(json)));
  };
}
export function renameGroupSuccess(json) {
  return {
    type: RENAME_GROUP_SUCCESS,
    json: json,
  };
}

export function deleteGroupSuccess(id) {
  return {
    type: DELETE_GROUP_SUCCESS,
    data: id,
  };
}

export function addGroupFailure(error) {
  return {
    type: ADD_GROUP_FAILURE,
    errorMessage: error.response.data.error == 'Group creation failedParent group and child group do not share the same account.'?
     'Not_Same_Account_Container':(error.response.data.error.includes("SQL")?error.response.data.error: error.message) ,
 // errorMessage: error.message,
  };
}

export function deleteGroup(id) {
  return (dispatch) => {
    return groupApi
      .delete(id)
      .then((response) => response.data)
      .then((json) => dispatch(deleteGroupSuccess(id)))
      .catch((error) => dispatch(deleteGroupFailure(error)));
  };
}

export function deleteGroupFailure(error) {
  return {
    type: DELETE_GROUP_FAILURE,
    errorMessage: error.message,
  };
}

export function removeDevicesFromGroup(groupId, deviceList) {
  return (dispatch) => {
    return groupApi
      .removeDevicesFromGroup({
        groupId: groupId,
        remove: deviceList,
      })
      .then((response) => response.data)
      .then((json) => dispatch(removeDevicesSuccess(groupId)))
      .catch((error) => dispatch(removeDevicesFailure(error)));
  };
}

export function removeInterfacesFromGroup(groupId, deviceList) {
  return (dispatch) => {
    return groupApi
      .removeInterfacesFromGroup({
        groupId: groupId,
        remove: deviceList,
      })
      .then((response) => response.data)
      .then((json) => dispatch(removeInterfacesSuccess(groupId)))
      .catch((error) => dispatch(removeInterfacesFailure(error)));
  };
}
export function removeDevicesFailure(error) {
  return {
    type: REMOVE_DEVICES_FAILURE,
    errorMessage: error.message,
  };
}

export function removeInterfacesFailure(error) {
  return {
    type: REMOVE_INTERFACES_FAILURE,
    errorMessage: error.message,
  };
}
export function addToGroupFailure(error) {
  return {
    type: ADD_TO_GROUP_FAILURE,
    errorMessage: error.message,
  };
}

export function addInterfacesToGroupFailure(error) {
  return {
    type: ADD_INTERFACES_TO_GROUP_FAILURE,
    errorMessage: error.message,
  };
}

export function removeDevicesSuccess(params) {
  return {
    type: REMOVE_DEVICES_SUCCESS,
    removedDevices: params,
  };
}

export function removeInterfacesSuccess(params) {
  return {
    type: REMOVE_INTERFACES_SUCCESS,
    removedDevices: params,
  };
}
export function setSelectedCsIds(ids) {
  return {
    type: SET_SELECTED_CS_IDS,
    ids: ids,
  };
}
export function setSelectedIfIds(ids) {
  return {
    type: SET_SELECTED_IF_IDS,
    ids: ids,
  };
}
export function addToGroupSuccess(params) {
  return {
    type: ADD_TO_GROUP_SUCCESS,
    newDevices: params,
  };
}

export function addInterfacesToGroupSuccess(params) {
  return {
    type: ADD_INTERFACES_TO_GROUP_SUCCESS,
    newDevices: params,
  };
}
export function addDeviceToGroup(groupId, deviceList) {
  const deviceIds = deviceList.map((entity) => entity.id);
  return (dispatch) => {
    return groupApi
      .addDevicesToGroup({
        groupId: groupId,
        add: deviceIds,
      })
      .then((response) => response.data)
      .then((json) => dispatch(addToGroupSuccess(deviceList)))
      .catch((error) => dispatch(addToGroupFailure(error)));
  };
}

export function addInterfacesToGroup(groupId, deviceList) {
  const deviceIds = deviceList.map((entity) => entity.id);
  return (dispatch) => {
    return groupApi
      .addInterfaceToGroup({
        groupId: groupId,
        add: deviceIds,
      })
      .then((json) => dispatch(addInterfacesToGroupSuccess(deviceList)))
      .catch((error) => dispatch(addInterfacesToGroupFailure(error)));
  };
}

export function pollGroupsStart(id, fromTree) {
  return {
    type: POLL_GROUPS_START,
    id: id,
    fromTree,
  };
}

export function pollGroupsStop(id) {
  return {
    type: POLL_GROUPS_STOP,
    id: id,
  };
}

//----Group Dashboard component

export function groupDashboardComponentsChanged(updatedState) {
  return {
    type:GROUP_DASHBOARD_COMPONENTS_CHANGE,
    currentState:updatedState
  }
} 

//-----------TopSubGroups -------------------------------------------------

export function requestTopSubGroups() {
  return {
    type: REQUEST_TOP_SUB_GROUP
  }
}

export function fetchTopSubGroupsSuccess(json) {
  return {
    type: FETCH_TOP_SUB_GROUP_SUCCESS,
    data: json
  }
}

export function fetchTopSubGroupsFailure(error) {
  return {
    type: FETCH_TOP_SUB_GROUP_FAILURE,
    errorMessage: error.message
  }
}

export function fetchTopSubGroupspoll(groupId) {
  return dispatch => {
    dispatch(requestTopSubGroups())
    dispatch(pollTopSubGroupsStart(groupId))
    return {
      type:FETCH_TOP_SUB_GROUP,
      
    }
  }
}

export function pollTopSubGroupsStart(groupId) {
  // console.log("Start polling TopSubGroups")
  return {
    type:POLL_TOP_SUB_GROUP_START,
    groupId:groupId,
  }
}
export function pollTopSubGroupsStop() {
  // console.log("Stop polling TopSubGroups")
  return {
    type:POLL_TOP_SUB_GROUP_STOP,
    
  }
}

export function setGroupTreeFilteredData(items) {
  return {
    type: FILTERED_GROUPTREE_ROWS,
    items: items,
  }
}

export function sendSavedColumns(columns) {
  return {
    type: SAVEDCOLUMNS_LISTVIEW,
    savedcolumns: columns,
  }
}







