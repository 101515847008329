import config from './../../config'
import axios from 'axios'

export class Agents {
  static get() {
    if (process.env.NODE_ENV == 'development') {
      return axios.get(`${config.mockBaseName}/app-discovery/agents`)
    }
    return axios.get(`${config.basename}/api/v1/app-discovery/agents`)
  };

  static getStatus() {
    if (process.env.NODE_ENV == 'development') {
      return axios.get(`${config.mockBaseName}/app-discovery/status`)
    }
    return axios.get(`${config.basename}/api/v1/app-discovery/status`)
  };

  static getRunningJobs() {
    if (process.env.NODE_ENV == 'development') {
      return axios.get(`${config.mockBaseName}/app-discovery/jobs/count`)
    }
    return axios.get(`${config.basename}/api/v1/app-discovery/jobs/count`)
  };
};
