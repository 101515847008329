import {
  FETCH_OCVIEW,
  FETCH_OCVIEW_SUCCESS,
  FETCH_OCVIEW_FAILURE,
 
} from "./actionTypes";
import { Ocview } from "./ocviews";
import * as IntegrationError from "../../components/settings/integrations/IntegrationError";

function getResponseError(error) {
  if (error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}


export function fetchOcview() {
  return {
    type: FETCH_OCVIEW,
  };
}
export function fetchOcviewSuccess(ocviewinfo) {
  let ocViewsResponse=[];
  if(ocviewinfo && ocviewinfo.length==0)
  ocViewsResponse= [];
  else
  {
    for(let ocView of ocviewinfo)
    ocViewsResponse.push(ocView.viewId);

  }

  return {
    type: FETCH_OCVIEW_SUCCESS,
    ocviewinfo: ocViewsResponse,
  };
}
export function fetchOcviewFailure(error) {
  return {
    type: FETCH_OCVIEW_FAILURE,
    errorMessage: error.message,
  };
}

export function getOcviewData() {
  return (dispatch) => {
    dispatch(fetchOcview());
    return Ocview
      .getUser()
      .then((response) => dispatch(fetchOcviewSuccess(response.data)))
      .catch(function (error) {
        dispatch(fetchOcviewFailure(getResponseError(error)));
      });
  };
}
