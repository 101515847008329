import {
  FETCH_ACCOUNT,
  FETCH_AXA_ACCOUNT,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
  FETCH_ACCOUNT_LIST_FAILURE,
  FETCH_ACCOUNT_LIST_SUCCESS
} from './actionTypes'
import { Account } from './account'

export function fetchAccount() {
  return {
    type: FETCH_ACCOUNT,
  }
}
export function fetchAxaAccount() {
  return {
    type: FETCH_AXA_ACCOUNT,
  }
}
export function fetchAccountSuccess(json) {
  return {
    type: FETCH_ACCOUNT_SUCCESS,
    item: json,
  }
}
export function fetchAccountFailure(error) {
  return {
    type: FETCH_ACCOUNT_FAILURE,
    errorMessage: error.message,
  }
}
export function fetchAccountListSuccess(json) {
  return {
    type: FETCH_ACCOUNT_LIST_SUCCESS,
    accounts: json.sort(sortByProperty('contactName')),
  }
}
export function fetchAccountListFailure(error) {
  return {
    type: FETCH_ACCOUNT_LIST_FAILURE,
    errorMessage: error.message,
  }
}
export function getAccount() {
  return dispatch => {
    dispatch(fetchAccount())
    return Account.getAccounts()
      .then(response => response.data)
      .then(json => dispatch(fetchAccountListSuccess(json)))
      .catch(function(error) {
        dispatch(fetchAccountListFailure(error))
      })
  }
}

function sortByProperty(property){  
  return function(a,b){  
     if(a[property] > b[property])  
        return 1;  
     else if(a[property] < b[property])  
        return -1;   
     return 0;  
  }  
}
