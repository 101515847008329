import React from 'react'
import { Route } from 'react-router-dom'
import Overview from './../components/overview/Overview'
import connect from './../utils/connect'

const OverviewRoute = props => {
  // let element = <Route exact path="/overview" component={OverviewCabi} />
  // if (props.configuration && props.configuration.items.html5Homepage) {
  let  element = <Route exact path="/overview" component={Overview} />
 // }
  return element
}

const mapStateToProps = state => {
  return {
    configuration: state.configuration,
  }
}

export default connect(mapStateToProps, null)(OverviewRoute)
