import React, { Component } from "react";
import Resource from "./Resource";
import { Dialog,DialogTitle,InputLabel,FormControl,DialogActions,Tooltip,DialogContent,Typography } from "@mineral/core";
import "./dialogButtonStyle.less";
import {
  ClearIcon as Clear,
} from "../ui-components/uim-components";
import {Button, IconButton,TextField} from '@mineral/core';
export default class ChangePasswordDialog extends Component {
  state={
    disbaleSaveBtn: true,
    disbaleOldPassword: false,
    disbaleNewPassword: true,
    disbaleConfirmPassword: true,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    successMsg: '',
    errorMsg:'',
    sid: this.props.sid,
    open:this.props.value,
    oldErrorMsgTextField: '',
    newErrorMsgTextField:'',
  }
  //Current Password
  currentPassword = Resource.get("Current Password");
  //New Password
  newpassword = Resource.get("New Password");
  //Confirm Password
  confirmpassword = Resource.get("Confirm Password");
  //Not allowed more than 24 characters.
  ErrorMsgTextCondition = Resource.get("Not allowed more than 24 characters.");
  //Please enter valid password
  ErrorMsgValidPassword = Resource.get('Please enter valid password');
  //The passwords must match.
  ErrorMsgMustMatch = Resource.get("The passwords must match.");
  //current and new passwords cannot be same.
  ErrorMsgMustNotMatch = Resource.get("The current and new passwords cannot be same.");
  //Password must be between 8 and 24 characters.
  ErrorMsgForNewPassword = Resource.get('Password must be between 8 and 24 characters.');
  //Password must contain a upper case letter, a lower case letter, a number and a special character.
  ErrorMsgCondition = Resource.get('Password must contain a upper case letter, a lower case letter, a number and a special character.');
  //Save
  save = Resource.get("Save");
  //Close
  close = Resource.get("Close");
  //Cancel
  cancel = Resource.get("Cancel");
  
  dataChecking(){
    if((this.props.error !== "" && this.props.error !== null) || this.state.oldErrorMsgTextField !== "" || this.state.newErrorMsgTextField !== ""
       || this.state.pwdError !== ""){
        this.setState({
          disbaleSaveBtn: true,
        }); 
    }
  }
  render() {
    const REQUIRED= Resource.get("Required"); //Required
    const actions = [
      <Button
        variant = "outlined"
       
        children={this.cancel}
        title ={this.cancel}
        color = "primary"
        onClick={this.handleCancel}
       
        
      />,
      <Button
        variant = "contained"
        children={this.save}
        title ={this.save}
        color = "primary"
        onClick={this.handleSave}
        disabled={this.state.disbaleSaveBtn}
       
      />,
    ];

    return (
      <Dialog        
        //title={this.props.title}
        actions={actions}
        modal={false}
        open={this.props.value}         
        
        className ="changePasswordDialog"
        onClose={this.handleCancel}
      >
        
        <DialogTitle disableTypography component="div"><Typography variant="h4"  component="h1">{this.props.title} </Typography></DialogTitle>
        <div style={{height:'0px'}}>
        <Tooltip arrow title={this.close} placement="bottom">
        <IconButton tabIndex={0}  title ={this.close} style = {{float:'right',top:'-50px',marginRight:'8px'}} aria-label={this.close} onClick={this.handleCancel}>
          <Clear role= 'figure' />
          </IconButton>
          </Tooltip>
        
        </div>
        <DialogContent>
        <>
        <div style={{color: "rgb(0, 128, 0)", textAlign: "center", fontSize: "12px", }}>{this.props.success}</div>
        <div style={{color: "red", textAlign: "center", fontSize: "12px", }}>{this.props.error && !this.props.error.includes("Current ") && !this.props.error.includes("New ") ? this.props.error : ""}</div>
       
           
            <FormControl fullWidth style={{marginBottom:'16px'}}>
       
        <InputLabel id="txtCurrentPassword"  htmlFor={"old"}>{this.currentPassword} <span className="required-label">{REQUIRED}</span></InputLabel>
       
     
            <TextField
              id="old"
              type="password"
              title={this.currentPassword}
              inputProps={{
                'aria-label' :this.currentPassword,
                'aria-labelledby' :'txtCurrentPassword'
              }}
              fullWidth
              onChange={ this.handleOldPasswordChange}
              autoComplete="off"
              className= "passwordFieldText"
              value={this.state.oldPassword}
              placeholder={this.currentPassword}
              helperText={this.props.error && this.props.error.includes("Current ") ? this.props.error : this.state.oldErrorMsgTextField}
            />
          </FormControl>
          <FormControl fullWidth style={{marginBottom:'16px'}}>
       
     
       <InputLabel id="txtNewPassword" htmlFor={"new"}>{this.newpassword} <span className="required-label">{REQUIRED}</span></InputLabel>
      
   
            <TextField
              id="new"
              type="password"
              title={this.newpassword}
              inputProps={{
                "aria-label" :this.newpassword,
                "aria-labelledby" :"txtNewPassword"
              }}
              fullWidth
              value={this.state.newPassword}
              disabled={this.state.disbaleNewPassword}
              className= "passwordFieldText"
              onChange={this.handleNewPasswordChange}
              onKeyDown= {(e) =>{
                if( e.keyCode === 32){
                  e.preventDefault()}
                }}
              autoComplete="off"
              placeholder={this.newpassword}
              helperText={this.props.error && this.props.error.includes("New ") ? this.props.error : this.state.newErrorMsgTextField}
            />
          </FormControl>
          <FormControl fullWidth style={{marginBottom:'16px'}}>
       
     
       <InputLabel id="txtConfirmPassword" htmlFor={"confirm"}>{this.confirmpassword} <span className="required-label">{REQUIRED}</span></InputLabel>
     
    
            <TextField
              id="confirm"
              title={this.confirmpassword}
              inputProps={{
                "aria-label" :this.confirmpassword,
                "aria-labelledby" :"txtConfirmPassword"
              }}
              fullWidth
              value={this.state.confirmPassword}
              disabled={this.state.disbaleConfirmPassword} type="password"
              className= "passwordFieldText"
              onChange={this.handleConfirmPasswordChange} 
              autoComplete="off"
              placeholder={this.confirmpassword}
              helperText={this.state.pwdError}
            />
          </FormControl>
          
        
        </>
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions> 
      </Dialog>
    );
  }

  handleOldPasswordChange = (e) =>{
  
    this.setState({ 
      oldPassword: e.target.value,
      errorMsg:''
    });      
    if(e.target.value === "" || this.state.newPassword === "" ||this.state.confirmPassword === "" ){
      this.setState({
        disbaleSaveBtn: true,
      }); 
      if(e.target.value === "" ){
        this.setState({
          disbaleNewPassword: true,
          disbaleConfirmPassword: true
        }); 
      } 
      else if(this.state.newPassword !== ""){
        this.setState({
        disbaleConfirmPassword: false
        });
      }
      else{
        this.setState({
          disbaleNewPassword: false,
        }); 
      }
    }
    else{
      this.setState({
        disbaleSaveBtn: false,
        disbaleNewPassword: false,
      });  
      if(this.state.newPassword !== ""){
        this.setState({
        disbaleConfirmPassword: false
        });
      }
    }

    if(e.target.value.length > 24){
      this.setState({oldErrorMsgTextField:this.ErrorMsgTextCondition}, this.dataChecking)
    }
    else{
        this.setState({oldErrorMsgTextField: ''}, this.dataChecking)
    }

    this.props.resetResponse();
  }

  handleNewPasswordChange = (e) =>{
    this.setState({ 
      newPassword: e.target.value,
      errorMsg: '',
      newErrorMsgTextField: null,
      });      

    let valid = true;
    let password = e.target.value;
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{5,})/;
  
    
      if( password.trim() == "" || ! regex.test(password.trim()))
          valid = false;

      if(this.state.oldPassword === "" ||e.target.value === ""||this.state.confirmPassword === "" ){
        this.setState({
          disbaleSaveBtn: true,
        });  
        if(e.target.value === "" ){
          this.setState({
            disbaleConfirmPassword: true,
          }); 
        } 
        else{
          this.setState({
            disbaleConfirmPassword: false,
          }); 
        }
      }
      else{
        this.setState({
          disbaleSaveBtn: false,
          disbaleConfirmPassword: false
        });  
      }
      if(this.state.oldPassword != "" && this.state.oldPassword === e.target.value )
      {
        this.setState({ newErrorMsgTextField: this.ErrorMsgMustNotMatch}, this.dataChecking);        
      } else if (password.length >24){
        this.setState({newErrorMsgTextField: this.ErrorMsgTextCondition}, this.dataChecking);
      }
      else if (password.length < 8 || password.length ==0){
        this.setState({newErrorMsgTextField: this.ErrorMsgForNewPassword}, this.dataChecking);
      }
      else if(!valid){
        this.setState({newErrorMsgTextField: this.ErrorMsgCondition}, this.dataChecking);        
      }
      else{
        this.setState({newErrorMsgTextField: ''}, this.dataChecking);
      }
  }

  handleConfirmPasswordChange = (e) =>{
    // if(e.target.value.length > 24){
    //   this.setState({pwdError:this.ErrorMsgTextCondition})
    // }
    // else{
    //     this.setState({pwdError: ''})
    // }
    this.setState({
      errorMsg: '',
      //pwdError: null,
      confirmPassword: e.target.value,
      disbaleSaveBtn: false,
    });  
    if(this.state.oldPassword === "" ||this.state.newPassword === "" ||e.target.value === "" ){
      this.setState({
        disbaleSaveBtn: true,
      });  
    }
    else{
      this.setState({
        disbaleSaveBtn: false,
      });  
    }
    if(this.state.newPassword != "" && this.state.newPassword !== e.target.value )
    {
      this.setState({ pwdError: this.ErrorMsgMustMatch}, this.dataChecking);        
    }
    else{
      this.setState({ pwdError: ''}, this.dataChecking); 
    }
  }

  handleSave = () =>{
    this.setState({
      errorMsg: '',
      successMsg: ''
    });      
    let newPasswordVal = this.state.newPassword;
    if(newPasswordVal == "") this.setState({newErrorMsgTextField: this.ErrorMsgValidPassword})
    else if(newPasswordVal != "" && newPasswordVal === this.state.oldPassword )
    {
      this.setState({
        newErrorMsgTextField: this.ErrorMsgMustNotMatch,  
            disbaleSaveBtn: true,
            newPassword: '' ,
            confirmPassword: '' ,
            });        
    }else if(newPasswordVal != "" && newPasswordVal !== this.state.confirmPassword )
    {
      this.setState({
            pwdError: this.ErrorMsgMustMatch,  
            disbaleSaveBtn: true,
            newPassword: '' ,
            confirmPassword: '' ,
            });        
    }
    else{
        let passwords ={
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword
          };

        this.props.changePassword(passwords);
        this.setState({
          disbaleSaveBtn: true,
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
    }
  }

  handleCancel = () =>{
    this.props.resetResponse();
    this.props.handleChangePwdClose();
  }
}
