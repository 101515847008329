import React, { Component } from "react";
import {
  ClearIcon as Clear,
  CenterError as Error,
  CenterInfo,
} from "../../ui-components/uim-components";
import {CircularProgress} from "@mineral/core";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  TableContainer,
  IconButton,Button
} from "@mineral/core";

import {Tooltip as MTooltip} from "@mineral/core";
import { sortColumns } from "./../../../utils/tableUtils";
import Resource from "./Resource";
import connect from "./../../../utils/connect";
import {
  fetchTopGroupspoll,
  pollTopGroupsStop,
} from "./../../../api/home-screen/actions";

const chartColors = ["#D9E9EC", "#9EC6D0", "#FEE276", "#F7922F", "#A11D2E"];
const Panel = (props) => {
  const handleDrillDown = (rowNumber, colId) => {
    props.history.push(`groups/0/${props.data[rowNumber].groupId}/dashboard`);
  };
  const normalise = (value, min, max) => ((value - min) * 100) / (max - min);

  return (
    <div className="technologies_grid">
      <TableContainer
        style={{
          maxHeight: `${
            window.innerHeight < 800
              ? "25vh"
              : window.innerHeight == 800
              ? "29vh"
              : window.innerHeight < 1080
              ? "31vh"
              : "34vh"
          }`,
        }}
      >
        <Table>
          <TableBody stripedRows={false} displayRowCheckbox={false}>
            {props.data.map((row, index) => (
              <TableRow
                onClick={() => {
                  handleDrillDown(index, row.groupId);
                }}
                key={row.groupId}
                className="technologies-row"
                role="row"
               
              >
                <TableCell colSpan="1" className="technologies_grid_col_name">
                  {/* <CustomTooltip message={row.groupName}> */}
                  <Button variant="text" style={{padding:0,display:'flex',justifyContent: 'flex-start',width: '100%',color:'#0361D9',
}} ><span style={{
    textOverflow: 'ellipsis',overflow:'hidden', whiteSpace: 'nowrap'}}>{row.groupName}</span></Button>
                  {/* </CustomTooltip> */}
                </TableCell>
                <TableCell
                  colSpan="2"
                  className="technologies_grid_col_progress"
                >
                  {/* <CustomTooltip message={"Alarm Count"}> */}
                
                   <LinearProgress
                    variant="determinate"
                    color="primary"
                    aria-label={"Alarm count linear progress"}
                    value={normalise(row.alarmCount, 0, props.maxCount)}
                    className="technologies-progress-bar"
                    classes={{
                      barColorPrimary: `${"chart-sev" + row.alarmLevel}`,
                    }}
                  />
                  {/* </CustomTooltip> */}
                </TableCell>

                <TableCell className="technologies_grid_col_alarm">
                  {row.alarmTotal}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

class PanelContainer extends Component {
  state = {
    status: "loading",
    groups: [],
  };
  apiError = () => {
    this.setState({
      status: "failure",
    });
  };

  componentDidMount() {
    this.props.fetchTopGroupspoll(this.props.csId ? this.props.csId : null);
  }
  componentWillUnmount() {
    this.props.pollTopGroupsStop();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      groups:nextProps.data,
      status: "complete",
      maxCount:
        nextProps.data.length > 0
          ? sortColumns("alarmTotal", "desc", nextProps.data)[0].alarmTotal
          : 0,
    });
  }
  render() {
    let view = (
      <Panel
        style={{ background: "#f4f4f4" }}
        maxCount={this.state.maxCount}
        className={this.props.className}
        data={this.state.groups}
        history={this.props.history}
      />
    );
    //No Groups Found
    let noGroupsText = Resource.get("No Groups Found");
    //Groups
    let title = Resource.get("Groups");
    //View All
    let viewAllText = Resource.get("View All");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span className="overview_container_subheader">{title}</span>
        <div style={{ display: "flex", alignItems: "center" }}>
        <MTooltip title={viewAllText}>
           <Button variant="text"
            
            style={{height:'16px'}}
            onClick={() => {
              this.props.history.push("groups/0");
            }}
            aria-label="Click to view all groups"
          >
            {viewAllText}
          </Button>
          </MTooltip>
          <span className="overview_header_seprator">|</span>
          <MTooltip title={"Close"}>
          <IconButton
            style={{ padding: 0 }}
            title={"Close"}
            onClick={() => {
              this.props.handleCloseDialog("showTopGroups");
            }}
          >
                       <Clear className="clearIcon" role="figure"
            title="Close" />
          </IconButton>
          </MTooltip>
        </div>
      </div>
    );
    if (this.state.status === "loading" && !this.props.didTopGroupsInvalidate) {
      view = (
        <div
          className="no_data_panel"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.status === "failure" ||
      this.props.didTopGroupsInvalidate
    ) {
      view = (
        <div className="no_data_panel">
          <Error />
        </div>
      );
    } else if (
      this.state.status === "complete" &&
      this.state.groups.length == 0
    ) {
      view = (
        <div className="no_data_panel" >
          <CenterInfo title={noGroupsText} />
        </div>
      );
    }
    return (
      <div
        className={this.props.className}
        style={{marginBottom:"24px"}}       
      >
        {cardHeader}
        
        {view}

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTopGroupspoll: (csId) => dispatch(fetchTopGroupspoll(csId)),
    pollTopGroupsStop: () => dispatch(pollTopGroupsStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    data: state.homeScreen.topGroups,
    isFetching: state.homeScreen.isFetching,
    didTopGroupsInvalidate: state.homeScreen.didTopGroupsInvalidate,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer); //connect so that we can get a handle on history
