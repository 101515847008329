import React, { Component } from "react";
import connect from "./utils/connect";
import axios from "axios";
import get from "lodash/get";
import {CircularProgress} from '@mineral/core';
import KeymapApp from "./KeymapApp";
import { setSid } from "./api/sid/actions";
import { setIsSaas } from "./api/saas/actions";
import { setIsTimedout } from "./api/isTimedOut/actions";
import { fetchConfiguration } from "./api/configuration/actions";
import { fetchUpgradeableRobots } from "./api/robots/actions";
import { openModal } from "./api/modals/actions";
import appConfig from "./api/config";
import { loadCommon, getCookieBaseUrl, getCookie } from "./utils/common";
import { LocalStorageManager as storageManagerApi } from "./utils/LocalStorageManager";
import { Logout as logoutApi } from "./api/logout/api";
import { Account as accountApi } from "./api/account/account";
import { fetchAccount, fetchAxaAccount } from "./api/account/actions";
import { setACL, fetchAclSuccess, getAclData, setSelfCertState } from "./api/acl/actions";
import {getOcviewData} from "./api/ocviews/actions"
import { setProductList } from "./api/login/actions";
import { setDoiUrl } from "./api/login/actions";
import AclTypes from "./api/aclTypes";
import { getSidFromAxaTokens } from "./api/axa/axa";
import { setGroupManagementPermission } from "./api/groupMgmtPermission/actions";
import { Wasp } from './api/wasp/wasp'
import { setEcometerAdmin } from './api/wasp/actions'

import {fetchGlobalAndPersonalUserPreferences} from "./api/userpreferences/actions";


let isSaas = false;


class Bootstrap extends Component {
  state = {
    loggedIn: false,
    isproductfetching: true,
    isTimedout:false,
  };
  _getSid = () => {
    let OPERATOR_CONSOLE = window.OPERATOR_CONSOLE;
    let sid = OPERATOR_CONSOLE["sid"];
    const cookie = getCookie();
    const token = cookie && cookie.token;
    const refreshToken = cookie && cookie.refresh_token;
    sid = sid === "null" ? null : sid;

    if (sid) {
      return new Promise((resolve, reject) => {
        resolve(sid);
      });
    }
    if (token && refreshToken) {
      return getSidFromAxaTokens({
        token: token,
        refresh_token: refreshToken,
      }).then((response) => get(response, "data.sid"));
    }
    return new Promise((resolve, reject) => {
      sid = storageManagerApi.getItem("OPERATOR_CONSOLE", "sid");
      if (!sid) {
        reject("No sid found - sid:", sid);
      }
      resolve(sid);
    });
  };

  _handleSid = (sid) => {
    this.props.setSid(sid);
    this.props.setIsSaas(isSaas);
    axios.defaults.headers.common["Authorization"] = sid;
    axios.interceptors.request.use(req => {
        let overrideSid=axios.defaults.headers.common["Authorization"];

        if(window.OPERATOR_CONSOLE.refreshToken && window.OPERATOR_CONSOLE.refreshToken!=="")
            {
            overrideSid=window.OPERATOR_CONSOLE.refreshToken;
            axios.defaults.headers.common["Authorization"]=window.OPERATOR_CONSOLE.refreshToken;
            window.OPERATOR_CONSOLE.refreshToken="";

            }
        if (req && req.headers)
            {
            if(req.method && req.method=='get')
            req.headers.get["Authorization"] = overrideSid;
            if(req.method && req.method=='delete')
           req.headers.delete["Authorization"] = overrideSid;
            if(req.method && req.method=='post')
            req.headers.post["Authorization"] = overrideSid;
            if(req.method && req.method=='put')
            req.headers.put["Authorization"] = overrideSid;
            req.headers.common["Authorization"] = overrideSid;
            return req;
            }
      }, error => {
        // handle the error
        return Promise.reject(error);
      });
    axios.interceptors.response.use(res => {
        if(window.OPERATOR_CONSOLE.sessionExpired)
        {
            //console.log('session expired in the frame')
            this.props.setIsTimedout(true)

        }
         if(res && res.headers && res.headers.refreshtoken){
            axios.defaults.headers.common["Authorization"] = 'Bearer '+res.headers.refreshtoken;

         }
        return res;

      }, error => {
        // handle the error
          //console.log(error);
          //console.log(error.response);
          //console.log(error.response.status);
          if(
            (error && error.response && error.response.status == 440) ||
            (error && error.response && error.response.status == 401)
          ) {
            this.props.setIsTimedout(true)
          } else {
            return Promise.reject(error);
          }
      });
    // validate sid with an actual request
    this.props.getConfig().then(
      (configRes) => {
        this.props.getOcviewData().then((ocViewsres)=>{
          console.log("Got ocviews here", ocViewsres);

          let landingPageIfNotSet="";
          let canOcviewOverview=!ocViewsres.ocviewinfo.includes("Home");
          let canOcviewAlarms= !ocViewsres.ocviewinfo.includes("Alarms");
          let canOcviewInventory= !ocViewsres.ocviewinfo.includes("Inventory");
          let canOcviewReports= !ocViewsres.ocviewinfo.includes("Reports");
          let canOcviewDashboards= !ocViewsres.ocviewinfo.includes("Dashboard");
          let canOcviewSLA= !ocViewsres.ocviewinfo.includes("SLA");
          let canOcviewSLM= !(ocViewsres.ocviewinfo.includes("SLA"));
          let canOcviewGroups= !(ocViewsres.ocviewinfo.includes("Groups"));
         // let canOcviewGroups= !(ocViewsres.ocviewinfo.includes("Tree") && ocViewsres.ocviewinfo.includes("Badge") && ocViewsres.ocviewinfo.includes("List"));
         /* let canOcviewGroupsTree= !ocViewsres.ocviewinfo.includes("Tree");
          let canOcviewGroupsBadge= !ocViewsres.ocviewinfo.includes("Badge");
          let canOcviewGroupsList= !ocViewsres.ocviewinfo.includes("List");
  */
            if(canOcviewOverview)
             landingPageIfNotSet="/overview";
            else if(canOcviewAlarms)
             landingPageIfNotSet="/uim-alarms" ;
            else if(canOcviewGroups)
             landingPageIfNotSet="/groups/0/treeviewnew" ;
           /* else if(canOcviewGroups && canOcviewGroupsBadge)
             landingPageIfNotSet="/groups/0" ;
            else if(canOcviewGroups && canOcviewGroupsList)
             landingPageIfNotSet="/groups/0/list" ;
            */
           else if(canOcviewInventory)
              landingPageIfNotSet="/inventory" ;
            else if(canOcviewDashboards)
              landingPageIfNotSet="/technologies" ;
            else if(canOcviewReports)
              landingPageIfNotSet="/reports" ;
            else if(canOcviewSLM)
              landingPageIfNotSet="/slm" ;
            else
              landingPageIfNotSet="No access" ;

          const isUserPersonalizationEnabled = get(configRes,
            "data.enableUserPersonalization",false
          );
          //console.log(isUserPersonalizationEnabled);

          if(isUserPersonalizationEnabled && isUserPersonalizationEnabled==true)
          {
            storageManagerApi.setItem("OPERATOR_CONSOLE", {
              sid: sid,
              baseUrl: getCookieBaseUrl(),

            });
            this.getPersonalizedView(landingPageIfNotSet);
          }
          else
          {
            storageManagerApi.setItem("OPERATOR_CONSOLE", {
              sid: sid,
              baseUrl: getCookieBaseUrl(),
              landingPage:landingPageIfNotSet
            });
            this.setState({ loggedIn: true });

          }
        });



      },
      (error) => {
        console.warn(
          "provided SID did not validate - returning to login:",
          error
        );
        const localStoreBaseUrl = storageManagerApi.getItem(
          "OPERATOR_CONSOLE",
          "baseUrl"
        );
        storageManagerApi.removeItem("sid");
        this.logout(localStoreBaseUrl);
      }
    );
    Wasp.isEcometerAdminInstall().then((response) =>
          {
            this.props.setEcometerAdmin(response.data)
          }
      )


      this.props.getAclData();
      this.props.fetchGlobalAndPersonalUserPreferences();

      accountApi.getUser().then((response) => {
        let acl = get(response, "data.roles", "").includes(
          "Portal Administration"
        )
          ? "Administrator"
          : "Operator";
          let canModifyGroup = get(response, "data.roles", "").includes(
              "USM Group Modification"
            );
          let canAddGroup = get(response, "data.roles", "").includes(
              "OC Group Add"
              );
          let canEditGroup = get(response, "data.roles", "").includes(
                "OC Group Edit"
                );
            let canRemoveGroup = get(response, "data.roles", "").includes(
                  "OC Group Delete"
                  );

        this.props.setGroupManagementPermission(canModifyGroup||canAddGroup||canEditGroup||canRemoveGroup);

        if (isSaas && acl !== AclTypes.OPERATOR) {
          this.props.fetchUpgradeableRobots().then((data) => {
            if (get(data, "robots.total", 0) > 0) {
              this.props.openModal("SHOW_ROBOTS_FOR_UPGRADE");
            }
          });


          this.props.getConfig().then((configRes) => {
            const walkMe_environment = get(
              configRes,
              "data.walkMe_environment"
            );


            if (walkMe_environment === "test") {
              // load(
              //   "https://cdn.walkme.com/users/9d163c4e65c146419dea80aec4f8a869/test/walkme_9d163c4e65c146419dea80aec4f8a869_https.js"
              // );
            } else {
              // load(
              //   "https://cdn.walkme.com/users/9d163c4e65c146419dea80aec4f8a869/walkme_9d163c4e65c146419dea80aec4f8a869_https.js"
              // );
            }
          });
        }
        this.props.setACL(acl);
      });
      accountApi.getSelfCertState().then((response) => {
        let selfcert = response.data.selfcert;
        this.props.setSelfCertState(selfcert)
        })


  };
  getProducts = () => {
    loadCommon()
      .then((ca_cloud) => {
        ca_cloud.getProducts().then((response) => {
          let products = response.data.products;

          this.props.setProductList(products);
          var newArray = products.filter(function (el) {
            return el.name === "DOI";
          });
          if (newArray.length !== 0) {
            this.props.setDoiUrl(newArray[0].axaTokenRedirectUrl);
          }
          this.setState({ isproductfetching: false });
        });
      })
      .catch((e) => {
        //console.error("Error fetching products =>", e);
        this.props.setProductList([]);
        this.setState({ isproductfetching: false });
      });
  };
  componentDidMount() {
    window.dispatchEvent(new CustomEvent('updateNavLinks'))
    // OPERATOR_CONSOLE is defined in standalone.jsp
    let OPERATOR_CONSOLE = window.OPERATOR_CONSOLE;
    isSaas = OPERATOR_CONSOLE["isSaas"];
    if (isSaas === "false" || !isSaas) {
      isSaas = false; // changes made to check the saas files
    } else {
      isSaas = true;
    }
    this._getSid().then(
      (sid) => {
        this._handleSid(sid);
        if (isSaas) {
          this.props.fetchAxaAccount();
        } else {
          this.props.fetchAccount();
        }

      },
      (e) => {
        console.warn("no SID returning to login");
        const localStoreBaseUrl = storageManagerApi.getItem(
          "OPERATOR_CONSOLE",
          "baseUrl"
        );
        this.logout(localStoreBaseUrl);
      }

    );


    this.getProducts();
  }
  getPersonalizedView = (landingPageBasedOnOCViews) => {
    console.log("landingPageBasedOnOCViews ",landingPageBasedOnOCViews);
    let slmAdmin = '';
    let slmView = '';
    let sloAccess = '';
    accountApi.getUser().then((response) => {
      slmAdmin = get(response, "data.roles", "").includes("SLM Admin");
      slmView = get(response, "data.roles", "").includes("SLM View");
      sloAccess = get(response, "data.roles", "").includes("SLO Access");
      let landingPageUrl=process.env.NODE_ENV=='development'?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getLandingPageByUser"
      :"/operatorconsole_portlet/api/v1/user/preferences/getLandingPageByUser"

      axios
      .get(
        landingPageUrl
      )
      .then((res) => {
        console.log("view ",res.data );
        let landingPage = "";
        switch (res.data) {

          case "Home":

            if(this.props.ocviews.canOcviewOverview)
             landingPage="/overview";
            else
             landingPage = landingPageBasedOnOCViews;

            break;
          case "Alarms":
            if(this.props.ocviews.canOcviewAlarms)
              landingPage="/uim-alarms";
            else
             landingPage = landingPageBasedOnOCViews;

            break;
          case "Inventory":
            if(this.props.ocviews.canOcviewInventory)
              landingPage="/inventory";
           else
             landingPage = landingPageBasedOnOCViews;

            break;
          case "Reports":
            if(this.props.ocviews.canOcviewReports)
              landingPage="/reports";
            else
              landingPage = landingPageBasedOnOCViews;

            break;
          case "Dashboard":
            if(this.props.ocviews.canOcviewDashboards)
              landingPage="/technologies";
             else
               landingPage = landingPageBasedOnOCViews;

            break;
          case "SLM":
          case "SLA":
             if((slmAdmin || slmView || sloAccess) && this.props.ocviews.canOcviewSLM)
                landingPage="/slm";
            else
              landingPage = landingPageBasedOnOCViews;
            break;
          case "Groups":
            if(this.props.ocviews.canOcviewGroups)
              landingPage="/groups/0/treeviewnew";
            else
              landingPage = landingPageBasedOnOCViews;

            break;
          /*case "Group - Card View":
            if(this.props.ocviews.canOcviewGroups)
               landingPage="/groups/0";
            else
             landingPage = landingPageBasedOnOCViews;

            break;
          case "Group - List View":
            if(this.props.ocviews.canOcviewGroups)
             landingPage="/groups/0/list";
            else
             landingPage = landingPageBasedOnOCViews;

            break;
*/
          default:
            landingPage = "No access";
            break;
        }
        console.log("Final landing ", landingPage);
        if(landingPage == "No access" )
        {
          alert("You don't access to this portal");
          const localStoreBaseUrl = storageManagerApi.getItem(
            "OPERATOR_CONSOLE",
            "baseUrl"
          );
          storageManagerApi.removeItem("sid");
          this.logout("/operatorconsole_portlet/standalone_login.jsp");
        }else{
          let sid=storageManagerApi.getItem(
            "OPERATOR_CONSOLE",
            "sid"
          );
          let baseUrl = getCookieBaseUrl();
          if (!isSaas) {
            baseUrl = "standalone_login.jsp";
          }
          storageManagerApi.setItem("OPERATOR_CONSOLE", {
            sid: sid,
            baseUrl: baseUrl,
            landingPage:landingPage
          });
          this.setState({ loggedIn: true });
        }


      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    });


  };
  logout = (localStoreBaseUrl) => {
    const referrer =
      window.location.pathname.replace(appConfig.basename, "") +
      window.location.search;
    // this will be used to redirect the user back to the last location when logging back in
    storageManagerApi.setItem("OPERATOR_CONSOLE", {
      referrer: referrer,
    });
    loadCommon()
      .then((ca_cloud) => ca_cloud.logout())
      .then((response) => {
        logoutApi.delete();
        window.location = response.data.redirectUrl;
      })
      .catch((error) => {
        let baseUrl = getCookieBaseUrl() || localStoreBaseUrl;
        console.error("Error logging out user:", error);
        return baseUrl;
      })
      .then((baseUrl) => {
        if (baseUrl) {
          console.info("Redirecting to:", baseUrl);
          window.location = baseUrl;
        }
        // get `baseUrl` from configuration 'wasp.cfg'
        return this.props.getConfig();
      })
      .then((response) => response.data)
      .then((json) => {
        const baseUrl = json.baseUrl;
        console.info("Redirecting to:", baseUrl);
        window.location = baseUrl;
      })
      .catch((error) => {
        // last resort we're lost
        let baseUrl = storageManagerApi.getItem(
          "OPERATOR_CONSOLE",
          "baseUrl"
        );
        if (!isSaas) {
          baseUrl = "standalone_login.jsp";
        }
        console.error("Error getting redirect url:", error);
        console.info("Redirecting to:", baseUrl);
        window.location = baseUrl;
      });
  };
  render() {
    return this.state.loggedIn && !this.state.isproductfetching ? (
      <KeymapApp />
    ) : (
      <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ocviews: state.ocviews,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConfig: () => dispatch(fetchConfiguration()),
    setSid: (sid) => dispatch(setSid(sid)),
    setIsSaas: (isSaas) => dispatch(setIsSaas(isSaas)),
    setIsTimedout: (isTimedout) => dispatch(setIsTimedout(isTimedout)),
    fetchUpgradeableRobots: () => dispatch(fetchUpgradeableRobots()),
    openModal: (id) => dispatch(openModal(id)),
    setACL: (acl) => dispatch(setACL(acl)),

    fetchAccount: () => dispatch(fetchAccount()),
    fetchAxaAccount: () => dispatch(fetchAxaAccount()),
    setGroupManagementPermission: (groupMgmt) =>
      dispatch(setGroupManagementPermission(groupMgmt)),
    setProductList: (products) => dispatch(setProductList(products)),
    setDoiUrl: (url) => dispatch(setDoiUrl(url)),
    fetchAclSuccess: (json) => dispatch(fetchAclSuccess(json)),
    getAclData: () => dispatch(getAclData()),
    getOcviewData: () => dispatch(getOcviewData()),
    setEcometerAdmin: ecometerAdmin => dispatch(setEcometerAdmin(ecometerAdmin)),
    setSelfCertState: (selfcert) => dispatch(setSelfCertState(selfcert)),
    fetchGlobalAndPersonalUserPreferences: ()=> dispatch(fetchGlobalAndPersonalUserPreferences())
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Bootstrap);
