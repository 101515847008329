import axios from 'axios'
import config from '../config'

// we have to renew the sid for every request because the alarm api is not under the same namespace

export class HomeScreen {

  static getOpenAlarms(params) {
    let csId = params.csId, groupIds = params.groupIds, probeId = params.probeId;

    let url = [config.basename, 'api/dashboard/component/openAlarmsInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/openAlarms");
    } else {
      if (csId == null && groupIds == null && probeId == null) {
        return axios.get(url);
      } else {
        if (csId != null) {
          url = url.concat("?csId=" + csId)
        }
        if (groupIds != null) {
          url = url.includes("?") ? url.concat("&groupId=" + groupIds) : url.concat("?groupId=" + groupIds)
        }
        if (probeId != null) {
          url = url.includes("?") ? url.concat("&probeId=" + probeId) : url.concat("?probeId=" + probeId)
        }
        return axios.get(url);
      }

    }
  };

  static getTopDevicesByAlarms(params) {
    let groupIds = params.groupIds, probeId = params.probeId;

    let url = [config.basename, 'api/dashboard/component/topDevicesByAlarmInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/topDevices");
    } else {
      if (groupIds == null && probeId == null) {
        return axios.get(url);
      } else {

        if (groupIds != null) {
          url = url.concat("?groupId=" + groupIds)
        }
        if (probeId != null) {
          url = url.includes("?") ? url.concat("&probeId=" + probeId) : url.concat("?probeId=" + probeId)
        }
        return axios.get(url);
      }
    }
    // return axios.get([config.basename, 'api/topDevices'].join('/'));
  };

  static getTopGroupsByAlarms(params) {
    let csId = params.csId;

    let url = [config.basename, 'api/dashboard/component/topGroupsByAlarmInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/topGroups");
    } else {
      if (csId == null) {
        return axios.get(url);
      } else {

        if (csId != null) {
          url = url.concat("?csId=" + csId)
        }

        return axios.get(url);
      }
    }
    //  return axios.get([config.basename, 'api/topGroups'].join('/'));
  };

  static getActiveDevices() {
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/activeDevices");
    } else {
      return axios.get([config.basename, 'api/dashboard/component/activeDevicesInformation'].join('/'));
    }
    //return axios.get([config.basename, 'api/activeDevices'].join('/'));
  };

  static getMonitoredTechnologies(params) {
    let csId = params.csId, groupIds = params.groupIds;

    let url = [config.basename, 'api/dashboard/component/fetchMonitoredTechnologiesInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/technologies");
    } else {
      if (csId == null && groupIds == null) {
        return axios.get(url);
      } else {
        if (csId != null) {
          url = url.concat("?csId=" + csId)
        }
        if (groupIds != null) {
          url = url.includes("?") ? url.concat("&groupId=" + groupIds) : url.concat("?groupId=" + groupIds)
        }

        return axios.get(url);
      }
    }
    // return axios.get([config.basename, 'api/technologies'].join('/'));
  };

  static getDeviceByRole(params) {
    let groupIds = params.groupIds, probeId = params.probeId;

    let url = [config.basename, 'api/dashboard/component/deviceByRoleInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/roleDevices");
    } else {
      if (groupIds == null && probeId == null) {
        return axios.get(url);
      } else {

        if (groupIds != null) {
          url = url.concat("?groupId=" + groupIds)
        }
        if (probeId != null) {
          url = url.includes("?") ? url.concat("&probeId=" + probeId) : url.concat("?probeId=" + probeId)
        }
        return axios.get(url);
      }
    }
    //return axios.get([config.basename, 'api/roleDevices'].join('/'));
  };
};
