export const COMPONENT_NAME = `@uim/operatorconsole-ui`
export const VERSION = `0.1.0`
export const BUILD_DATE = `2024-02-27T02:44:27.884Z`
export const BUILD_NUMBER = `0`
export const BRANCH = `develop-20.4`
export const COMMIT_ID = `b595cfae50dd96e998a615479ef108e64f9dc4d0`
// export const TAGS = '${git.tags}';
// export const DIRTY = '${git.dirty}';
// export const DESCRIBE = '${git.commit.id.describe}';
// export const COMMIT_TIME = '${git.commit.time}';
// export const CLOSEST_TAG_NAME = '${git.closest.tag.name}';
// export const CLOSEST_TAG_COMMIT_COUNT = '${git.closest.tag.commit.count}';

export default {
  COMPONENT_NAME,
  VERSION,
  BUILD_DATE,
  BUILD_NUMBER,
  BRANCH,
  COMMIT_ID,
}
