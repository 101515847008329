/* USAGE:
* Out of the box functionality: Column Resizing, Column Reordering, Dragging column off the screen does NOT hide it, No Row Hover, Cells are not selectable, Header handles sort on view
* All Arguments are able to be overridden by passing in those Arguments
* To sort over multiple 'pages' pass in a columnSort property under the headerComponentParams property on the columnDef of choice.
*/

import React, { useEffect, lazy, Suspense }  from 'react'
import './grid.less'
import GridHeader from './GridHeader'
import CustomSetFilter from './CustomSetFilter'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { EventBus } from "../../../../utils/EventBus";
import { CircularProgress } from '@mineral/core';
const AgGridReact = lazy(() => import('./export-ag-grid'));

const Grid = (props) => {
  useEffect(() => {
    return EventBus.$off("backendSortChanged");
  }, []);
  const backendSortConfig = props.backendSortConfig?.enable
    ? {
        ...props.backendSortConfig,
        callback: (config) => {
          EventBus.$emit("backendSortChanged", config);
          props.backendSortConfig.callback(config);
        },
      }
    : null;
  return (
    <Suspense fallback={<div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
     <CircularProgress style={{ color: "#3272D9" }} />
    </div>}> 
    <AgGridReact
      {...props}
      backendSortConfig={backendSortConfig}
      className={props.customClassName ? props.customClassName : "oc-grid"}
    />
   </Suspense> 
  );
};

Grid.defaultProps = {
  enableBrowserTooltips: true,
  rowHeight: 60,
  groupHeaders: true,
  enableSorting: true,
  enableColResize: true,
 // suppressCellSelection: true,
  suppressRowHoverClass: true,
  suppressDragLeaveHidesColumns: true,
  ensureDomOrder:true,
  defaultColDef : {
    width: 100,
    headerComponentFramework : GridHeader,
    headerComponentParams : {
      enableMenu: false
    }
  },
   backendOnSortChanged: new CustomEvent("backendOnSortChanged"),
  frameworkComponents:{ customSetFilter: CustomSetFilter}
}

export default Grid
