import {
  REQUEST_PROBES,
  FETCH_PROBES,
  FETCH_PROBES_SUCCESS,
  FETCH_PROBES_FAILURE, 
  POLL_PROBES_START,
  POLL_PROBES_STOP
} from './actionTypes'
// import probes from '../../api/mcs/probes'
import { ProbesList } from './probesList'

export function requestProbes() {
  return {
    type: REQUEST_PROBES
  }
}

export function fetchProbesSuccess(json) {
  return {
    type: FETCH_PROBES_SUCCESS,
    probes: json
  }
}

export function fetchProbesFailure(error) {
  return {
    type: FETCH_PROBES_FAILURE,
    errorMessage: error.message
  }
}

export function fetchProbes(count,useEnhancedTemplates,categories) {
  return dispatch => {
    dispatch(fetchProbespoll(useEnhancedTemplates,categories))
    return ProbesList.fetchProbeDetails({count:count,useEnhancedflag:useEnhancedTemplates,categories})
      .then(response => response.data)
      .then(json => dispatch(fetchProbesSuccess(json)))
      .catch(function (error) {
        dispatch(fetchProbesFailure(error))
      })
  }
}
export function fetchProbespoll(useEnhancedTemplates,categories) {
  return {
    type:FETCH_PROBES,
    count:false,
    useEnhancedflag:useEnhancedTemplates,
    categories:categories
  }
}

export function pollProbesStart(useEnhancedTemplates,categories) {
 // console.log("Start polling Probes")
  return {
    type:POLL_PROBES_START,
    count:true,
    useEnhancedflag:useEnhancedTemplates,
    categories:categories
  }
}
export function pollProbesStop() {
 // console.log("Stop polling Probes")
  return {
    type:POLL_PROBES_STOP,
    count:true,
  }
} 