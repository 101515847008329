import axios from 'axios'

export class Account {
  static getUser() {
    const url = process.env.NODE_ENV == "development" ? 
      "http://10.252.25.208:8080/castlemock/mock/rest/project/YAYKiT/application/xxKf0k/api/user":
    '/operatorconsole_portlet/api/user'
    //const url='http://10.252.25.208:8080/castlemock/mock/rest/project/YAYKiT/application/xxKf0k/api/user'
    //const url='http://10.252.25.208:8080/castlemock/mock/rest/project/YAYKiT/application/xxKf0k/api/user/operator'
    return axios.get(url)
  }

  static getAccountProperties() {
    const url = '/operatorconsole_portlet/api/v1/accountinfo/addlproperties'
    return axios.get(url)
  }

  static getAccounts(){
    const url = process.env.NODE_ENV == "development" ?
    "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/account" :
    '/operatorconsole_portlet/api/v1/accountinfo/accounts'
    return axios.get(url)
  }

  static getSelfCertState(){
    const url = process.env.NODE_ENV == "development" ? 
    "http://10.252.25.208:8080/castlemock/mock/rest/project/YAYKiT/application/js3itJ/selfcert" :
    '/operatorconsole_portlet/api/v1/deviceSelfCert/selfcert'
   //const url='http://10.252.25.208:8080/castlemock/mock/rest/project/YAYKiT/application/js3itJ/selfcert'
    return axios.get(url)
  }
};
