import {
  REQUEST_ROBOTS,
  FETCH_ROBOTS_SUCCESS,
  FETCH_ROBOTS_FAILURE,
  CREATE_ROBOTS,
  CREATE_ROBOTS_SUCCESS,
  CREATE_ROBOTS_FAILURE,
  REQUEST_ROBOTS_FOR_UPGRADE,
  FETCH_ROBOTS_FOR_UPGRADE_SUCCESS,
  FETCH_ROBOTS_FOR_UPGRADE_FAILURE,
  DEPLOY_ROBOTS,
  DEPLOY_ROBOTS_SUCCESS,
  DEPLOY_ROBOTS_FAILURE,
} from './actionTypes'
import { Robots as robotsApi } from './robots'

export function requestRobots() {
  return {
    type: REQUEST_ROBOTS,
  }
}

export function fetchRobotsSuccess(json) {
  return {
    type: FETCH_ROBOTS_SUCCESS,
    robots: json,
  }
}

export function fetchRobotsFailure(error) {
  return {
    type: FETCH_ROBOTS_FAILURE,
    errorMessage: error.message,
  }
}

export function fetchRobots() {
  return dispatch => {
    dispatch(requestRobots())
    return robotsApi
      .get()
      .then(response => response.data)
      .then(json => dispatch(fetchRobotsSuccess(json)))
      .catch(function(error) {
        dispatch(fetchRobotsFailure(error))
      })
  }
}

export function pollFetchRobots() {
  return dispatch => {
    return robotsApi
      .get()
      .then(response => response.data)
      .then(json => dispatch(fetchRobotsSuccess(json)))
      .catch(function(error) {
        dispatch(fetchRobotsFailure(error))
      })
  }
}

export function createRobots() {
  return {
    type: CREATE_ROBOTS,
  }
}

export function createRobotsSuccess(json) {
  return {
    type: CREATE_ROBOTS_SUCCESS,
    robots: json,
  }
}

export function createRobotsFailure(error) {
  return {
    type: CREATE_ROBOTS_FAILURE,
    errorMessage: error.message,
    error: error,
  }
}

export function saveRobots(data) {
  return dispatch => {
    dispatch(createRobots())
    const promise = robotsApi.post(data)
    promise
      .then(response => response.data)
      .then(json => {
        return dispatch(createRobotsSuccess(json))
      })
      .catch(function(error) {
        dispatch(createRobotsFailure(error))
      })
    return promise
  }
}
export function deployRobots() {
  return {
    type: DEPLOY_ROBOTS,
  }
}

export function deployRobotsSuccess(json) {
  return {
    type: DEPLOY_ROBOTS_SUCCESS,
    robots: json,
  }
}

export function deployRobotsFailure(error) {
  return {
    type: DEPLOY_ROBOTS_FAILURE,
    errorMessage: error.message,
    error: error,
  }
}
export function deployRobot(data) {
  return dispatch => {
    dispatch(deployRobots())
    const promise = robotsApi.deployRobot(data)
    promise
      .then(response => response.data)
      .then(json => {
        return dispatch(deployRobotsSuccess(json))
      })
      .catch(function(error) {
        dispatch(deployRobotsFailure(error))
      })
    return promise
  }
}

export function requestRobotsForUpgrade() {
  return {
    type: REQUEST_ROBOTS_FOR_UPGRADE,
  }
}

export function fetchRobotsForUpgradeSuccess(json) {
  return {
    type: FETCH_ROBOTS_FOR_UPGRADE_SUCCESS,
    robots: json,
  }
}

export function fetchRobotsForUpgradeFailure(error) {
  return {
    type: FETCH_ROBOTS_FOR_UPGRADE_FAILURE,
    errorMessage: error.message,
  }
}

export function fetchUpgradeableRobots() {
  return dispatch => {
    dispatch(requestRobotsForUpgrade())
    return robotsApi
      .getRobotsForUpgrade()
      .then(response => response.data)
      .then(json => dispatch(fetchRobotsForUpgradeSuccess(json)))
      .catch(function(error) {
        dispatch(fetchRobotsForUpgradeFailure(error))
      })
  }
}
