export class RowSelectionCache {
  constructor() {
    this._data = [];
    this.unselectedData = [];
  };

  static getData() {
    return this._data.slice(0);
  };

  static getUnselectedData() {
    return this.unselectedData.slice(0);
  };

  static clear() {
    this._data = [];
    this.unselectedData = [];
  };

  static onChange(api, isInterface) {

    let unSelectedNodes = [],
      i,
      index,
      len;
    api.forEachNode((node) => {
      let idValue = isInterface ? node.data.masterId : node.data.id;
      if (!node.isSelected()) {
        unSelectedNodes.push(idValue);
      } else {
        index = this._data.indexOf(idValue);
        if (index === -1) {
          this._data.push(idValue);
          let unselectedDataIndex = this.unselectedData.findIndex(id => id == idValue);
          if (unselectedDataIndex > -1) {
            this.unselectedData.splice(unselectedDataIndex, 1)
          }
        }
      }
    });
    len = unSelectedNodes.length;
    for (i = 0; i < len; i++) {
      index = this._data.indexOf(unSelectedNodes[i]);
      if (index !== -1) {
        this._data.splice(index, 1);
        if (this.unselectedData.findIndex(id => id == unSelectedNodes[i]) == -1) {
          this.unselectedData.push(unSelectedNodes[i])
        }

      }
    }
  };

  static updateOnRowDataChanged(api) {
    api.forEachNode((node) => {
      if (this._data.indexOf(node.data.id) !== -1 && !node.isSelected()) {
        node.setSelected(true);
      }
    });
  };
};
