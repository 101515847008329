import config from './../config'
import axios from 'axios'

export class Hubs {
  static get() {
    if(process.env.NODE_ENV=='development'){
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/hubs")
    }
    return axios.get(`${config.basename}/api/v1/hubs`)
  };
};
