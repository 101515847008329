import config from './../config'
import axios from 'axios'

const url = `${config.basename}/api/v1/logout`

export class Logout {
  static delete() {
    return axios.delete(url)
  };
};
