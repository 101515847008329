import React, { Component } from "react";
import {
  Select as SelectField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button as RaisedButton,
  Tooltip,InputLabel,IconButton,Typography,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,Button,TextField
} from "@mineral/core";
import Resource from "./Resource";
import "./criteria.less";
import {
  ClearIcon as DeleteIcon,
} from "../../ui-components/uim-components";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import { Group as groupApi } from "../../../api/groups/groups";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { withStyles } from '@material-ui/core/styles';
import CustomTextArea  from "../../common/customInputs/CustomTextArea"
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  ChevronDownIcon,
} from "./../../ui-components/uim-components";
import CriteriaColumnNamesRenderer from "./criteriaColumnNamesRenderer";
const  DeviceFilterLabel = Resource.get("Device Filter"); //Device Filters

const styles = (theme) => ({
  table: {
    minWidth: 650,
    width: "unset !important",
  },
  tableContainer: {
    borderRadius: "3px",
    margin: "16px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #3272D9",
    overflow: "hidden",
  },
  tableRow: {
    height: 33,
    border: 'none'
  },
  tableBodyRow: {
    height: 34,
    border: 'none !important'
  },
  tableHeaderCell: {
    //position: 'absolute',
    position: "relative",
    height: "16px",
    // maxWidth: '100%',
    padding: "0px 16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",

    color: "#666666",
    backgroundColor: "#FFFFFF",
    //color: theme.palette.getContrastText(theme.palette.primary.light)
    borderBottom: "none !important",
    border: "none !important",
    whiteSpace: "nowrap",
  },

  tableBodyCell: {
    //position: 'absolute',
    position: "relative",
    height: "32px",
    padding: "0px 10px",
    color: "#666666",
    backgroundColor: "#FFFFFF",
    //color: theme.palette.getContrastText(theme.palette.primary.light)
    border: "none !important",
    //smaxWidth: '100%',
    whiteSpace: "nowrap",
  },
  avatar: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  add_remove_btn: {
    position: "relative",
    width: "24px",
    height: "24px",
    color: "#3272D9",
    background: "#FFFFFF",
    border: "1px solid #C8D1E0",
    boxSizing: "border-box",
    borderRadius: "3px",
    marginRight: "8px",
  },
  input: {
    padding: "1px 10px",
  },
});
const getCriteriaRowModel = function () {
  return {
    isCsFilter: true,
    conjugate: "and",
    not: false,
    nameInfo: {
      value: "Name",
      advanced: false,
    },
    like: {
      value: "is",
      disabled: false,
    },
    valueInfo: {
      value: "null",
      type: "text",
      prevValue: null,
    },
  };
};

const getCriteriaColumnsDetails = function () {
  let likeInfo = [
      "is",
      "contains",
      "starts with",
      "ends with",
      "in",
      "undefined",
    ],
    conjugateInfo = ["and", "or"],
    likeInfoToServerMap = {
      is: "EQUALS",
      contains: "CONTAINS",
      "starts with": "STARTSWITH",
      "ends with": "ENDSWITH",
      in: "IN",
      undefined: "UNDEFINED",
    },
    likeInfoToClientMap = {
      EQUALS: "is",
      CONTAINS: "contains",
      STARTSWITH: "starts with",
      ENDSWITH: "ends with",
      IN: "in",
      UNDEFINED: "undefined",
    },
    scalarOperator = ["=", ">", ">=", "<", "<=", "undefined"],
    scalarInfoToServerMap = {
      "=": "EQUALS",
      ">": "GREATERTHAN",
      ">=": "GREATERTHANEQUAL",
      "<": "LESSTHAN",
      "<=": "LESSTHANEQUAL",
      undefined: "UNDEFINED",
    },
    scalarInfoToClientMap = {
      EQUALS: "=",
      GREATERTHAN: ">",
      GREATERTHANEQUAL: ">=",
      LESSTHAN: "<",
      LESSTHANEQUAL: "<=",
      UNDEFINED: "undefined",
    };

  return {
    columnNamesInfo: [],
    clientToServerNamesMap: {},
    serverToClientNamesMap: {},
    selectColumns: [],
    selectColumnsInfo: {},
    likeInfo: likeInfo,
    conjugateInfo: conjugateInfo,
    likeInfoToServerMap: likeInfoToServerMap,
    likeInfoToClientMap: likeInfoToClientMap,
    advancedFilters: [],
    ifColumnNamesInfo: [],
    ifClientToServerNamesMap: {},
    ifServerToClientNamesMap: {},
    ifSelectColumns: [],
    ifSelectColumnsInfo: {},
    ifScalarColumns: [],
    ifAdvancedFilters: [],
    scalarOperator: scalarOperator,
    scalarInfoToServerMap: scalarInfoToServerMap,
    scalarInfoToClientMap: scalarInfoToClientMap,
  };
};

 class Criteria extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      info: [],
      ifInfo: [],
      columnsDetails: getCriteriaColumnsDetails(),
      rowModel: getCriteriaRowModel(),
      clonedInfo: [],
      show: true,
      accountId: props.accountId,
      criteria: props.entity && props.entity.criteria ? Object.assign({},props.entity.criteria) : null,
    };
    this.state = this.initialState;
  }
  ORIndices = [];
  componentDidMount() {
    groupApi.getDynamicGroupFilters().then(
      (response) => {
        this.updateCriteriaColumnDetails(response.data);
        this.convertInitialCriteriaToClientModel(this.props);
      },
      (error) => {
        // console.log("Error retriving DynamicGroupFilters");
      }
    );
    if (this.props.onLoad) {
      this.props.onLoad({ getFilterInfo: this.getFilterInfo });
    }
  }
  componentWillReceiveProps(newProps) {
    if (
      this.props.entity &&
      !isEqual(newProps.entity.criteria, this.state.criteria)
    ) {
      this.setState({
        criteria:newProps?.entity?.criteria? Object.assign({},newProps.entity.criteria):null,
      });
      this.convertInitialCriteriaToClientModel(newProps);
    }
    if (!isEqual(newProps.accountId, this.state.accountId)) {
      this.setState({ accountId: newProps.accountId });
    }
  }

  componentWillUnmount = () => {
    this.setState({ ...this.initialState });
  };
  getFilterInfo = () => {
    return this.getChoosenCriteria();
  };
  updateCriteriaColumnDetails(data) {
    if (data.filters && data.filters.length > 0) {
      data.filters.sort((item1, item2) => {
        if (item1.filterKey.name > item2.filterKey.name) return 1;
        else if (item1.filterKey.name < item2.filterKey.name) return -1;
        else return 0;
      });
      for (let i = 0; i < data.filters.length; i++) {
        this.state.columnsDetails.columnNamesInfo.push(
          data.filters[i].filterKey.name
        );
        this.state.columnsDetails.clientToServerNamesMap[
          data.filters[i].filterKey.name
        ] = data.filters[i].filterKey.dbName;
        this.state.columnsDetails.serverToClientNamesMap[
          data.filters[i].filterKey.dbName
        ] = data.filters[i].filterKey.name;
        if (Array.isArray(data.filters[i].filterValue)) {
          this.state.columnsDetails.selectColumns.push(
            data.filters[i].filterKey.name
          );
          this.state.columnsDetails.selectColumnsInfo[
            data.filters[i].filterKey.name
          ] = data.filters[i].filterValue;
        }
      }
    }
    if (data.masterFilters && data.masterFilters.length > 0) {
      data.masterFilters.sort((item1, item2) => {
        if (item1.filterKey.name > item2.filterKey.name) return 1;
        else if (item1.filterKey.name < item2.filterKey.name) return -1;
        else return 0;
      });
      for (let i = 0; i < data.masterFilters.length; i++) {
        this.state.columnsDetails.ifColumnNamesInfo.push(
          data.masterFilters[i].filterKey.name
        );
        this.state.columnsDetails.ifClientToServerNamesMap[
          data.masterFilters[i].filterKey.name
        ] = data.masterFilters[i].filterKey.dbName;
        this.state.columnsDetails.ifServerToClientNamesMap[
          data.masterFilters[i].filterKey.dbName
        ] = data.masterFilters[i].filterKey.name;
        if (Array.isArray(data.masterFilters[i].filterValue)) {
          this.state.columnsDetails.ifSelectColumns.push(
            data.masterFilters[i].filterKey.name
          );
          this.state.columnsDetails.ifSelectColumnsInfo[
            data.masterFilters[i].filterKey.name
          ] = data.masterFilters[i].filterValue;
        }
        if (data.masterFilters[i].dataType === "scalar") {
          this.state.columnsDetails.ifScalarColumns.push(
            data.masterFilters[i].filterKey.name
          );
        }
      }
    }
    if (data.advancedFilters && data.advancedFilters.length > 0) {
      data.advancedFilters.sort((item1, item2) => {
        if (item1 > item2) return 1;
        else if (item1 < item2) return -1;
        else return 0;
      });
      this.state.columnsDetails.advancedFilters.push(...data.advancedFilters);
    }
    if (data.interfaceAdvFilters && data.interfaceAdvFilters.length > 0) {
      data.interfaceAdvFilters.sort((item1, item2) => {
        if (item1 > item2) return 1;
        else if (item1 < item2) return -1;
        else return 0;
      });
      this.state.columnsDetails.ifAdvancedFilters.push(
        ...data.interfaceAdvFilters
      );
    }
  }
  convertInitialCriteriaToClientModel(props1) {
    let props=props1==null?null:cloneDeep(props1)
    let criteria =
        props.entity && props.entity.criteria ? cloneDeep(props.entity.criteria) : null,
      ORSplitNeeded = false,
      length = 1,
      outerlength = 1,
      ifData = [],
      data = [];

    // //Default Filter
    if (props.entity.criteria == null) {
      let deviceCriteria = {
        isCsFilter: true,
        criteriaOperator: "AND",
        subCriteria: [
          {
            isCsFilter: true,
            negated: false,
            attribute: "DisplayName",
            filterOperator: "EQUALS",
            value: ["null"],
          },
        ],
      };
      let interfaceInnerCriteria = {
        isCsFilter: false,
        criteriaOperator: "AND",
        subCriteria: [
          {
            isCsFilter: false,
            negated: false,
            attribute: "DisplayName",
            filterOperator: "CONTAINS",
            value: [""],
          },
        ],
      };
      let combineCriteria = {
        criteriaOperator: "AND",
        isCsFilter: false,
        subCriteria: [interfaceInnerCriteria, deviceCriteria],
      };
      criteria = this.props.entity.masterElementGroup
        ? combineCriteria
        : deviceCriteria;
    }
    //   criteria = {
    //     "negated": null,
    //     "attribute": null,
    //     "filterOperator": null,
    //     "value": null,
    //     "criteriaOperator": "AND",
    //     "subCriteria": [
    //       {
    //         "negated": false,
    //         "attribute": "DisplayName",
    //         "filterOperator": "EQUALS",
    //         "value": ["null"],
    //         "criteriaOperator": null,
    //         "subCriteria": null,
    //         "attributeType": "STRING",
    //         "csFilter": true,
    //         "singleValue": "null",
    //         "sqlFilter": false,
    //         "filter": true
    //       }
    //     ],
    //     "attributeType": null,
    //     "csFilter": true,
    //     "singleValue": null,
    //     "sqlFilter": false,
    //     "filter": false
    //   }
    // }
    // criteria=JSON.parse(` {
    //   "negated": null,
    //   "attribute": null,
    //   "filterOperator": null,
    //   "value": null,
    //   "criteriaOperator": "OR",
    //   "subCriteria": [{
    //     "negated": null,
    //     "attribute": null,
    //     "filterOperator": null,
    //     "value": null,
    //     "criteriaOperator": "AND",
    //     "subCriteria": [{
    //       "negated": true,
    //       "attribute": "DisplayName",
    //       "filterOperator": "EQUALS",
    //       "value": ["null"],
    //       "criteriaOperator": null,
    //       "subCriteria": null,
    //       "attributeType": "STRING",
    //       "sqlFilter": false,
    //       "singleValue": "null",
    //       "csFilter": true,
    //       "filter": true
    //     }, {
    //       "negated": true,
    //       "attribute": "DisplayName",
    //       "filterOperator": "CONTAINS",
    //       "value": ["10"],
    //       "criteriaOperator": null,
    //       "subCriteria": null,
    //       "attributeType": "STRING",
    //       "sqlFilter": false,
    //       "singleValue": "10",
    //       "csFilter": true,
    //       "filter": true
    //     }],
    //     "attributeType": null,
    //     "sqlFilter": false,
    //     "singleValue": null,
    //     "csFilter": true,
    //     "filter": false
    //   }, {
    //     "negated": null,
    //     "attribute": null,
    //     "filterOperator": null,
    //     "value": null,
    //     "criteriaOperator": "AND",
    //     "subCriteria": [{
    //       "negated": false,
    //       "attribute": "DisplayName",
    //       "filterOperator": "STARTSWITH",
    //       "value": ["a"],
    //       "criteriaOperator": null,
    //       "subCriteria": null,
    //       "attributeType": "STRING",
    //       "sqlFilter": false,
    //       "singleValue": "a",
    //       "csFilter": true,
    //       "filter": true
    //     }],
    //     "attributeType": null,
    //     "sqlFilter": false,
    //     "singleValue": null,
    //     "csFilter": true,
    //     "filter": false
    //   }],
    //   "attributeType": null,
    //   "sqlFilter": false,
    //   "singleValue": null,
    //   "csFilter": true,
    //   "filter": false
    // }`)
    //Interface Criteria - setting isCSFilter for Interface criteria to false
    if (
      !(
        criteria.isCsFilter !== null &&
        typeof criteria.isCsFilter !== "undefined"
      )
    ) {
      Object.assign(criteria, { isCsFilter: criteria.csFilter });
    }
    let csFilterFlag =
      criteria && criteria.isCsFilter
        ? criteria.isCsFilter
        : criteria && criteria.csFilter
        ? criteria.csFilter
        : false;

        //Interface Criteria
    if (criteria && !csFilterFlag) {
      outerlength = criteria.subCriteria.length; // For interface we need to process both like a parent
    }
    for (let count = 0; count < outerlength; count++) {
      let innerORSplitNeeded = false,
        innerSubCriteriaFound = false,
        innerIsCsFilter = false;
      let outerCriteria = criteria; //Device only
      if (criteria && !csFilterFlag) { //interface only
        outerCriteria = criteria.subCriteria[count];
      }
      if (!outerCriteria) {
        continue;
      }
      if (outerCriteria.criteriaOperator === "OR") {
        ORSplitNeeded = true;
        length = outerCriteria.subCriteria.length;
        // length = outerCriteria.subCriteria.length;
      }
      
      for (let i = 0; i < length; i++) {
        let info;
        if (ORSplitNeeded) {
          info = outerCriteria.subCriteria[i];
          if (
            info?.criteriaOperator == undefined &&
            info?.isCsFilter == undefined
          ) {
            info.isCsFilter = true;
            info.criteriaOperator = "AND";
            info.subCriteria = [
              {
                isCsFilter: true,
                negated: info.negated,
                attribute:
                  info.attribute == "ip" ? "ANY_IP_ADDRESS" : info.attribute,
                filterOperator: info.filterOperator,
                value: info.value,
                subCriteria: null,
              },
            ];
            outerCriteria.subCriteria[i].isCsFilter = true;
            outerCriteria.subCriteria[i].criteriaOperator = "AND";
            outerCriteria.subCriteria[i].subCriteria = [
              {
                isCsFilter: true,
                negated: outerCriteria.subCriteria[i].negated,
                attribute:
                  outerCriteria.subCriteria[i].attribute == "ip"
                    ? "ANY_IP_ADDRESS"
                    : outerCriteria.subCriteria[i].attribute,
                filterOperator: outerCriteria.subCriteria[i].filterOperator,
                value: outerCriteria.subCriteria[i].value,
                subCriteria: null,
              },
            ];
          }
        } else {
          info = outerCriteria;
        }
        let innerCriteriaLength = info.subCriteria
          ? info.subCriteria.length
          : 0;
        // if (innerSubCriteriaFound) {
        //   innerCriteriaLength = outerCriteria.subCriteria.length;
        // }

        for (let j = 0; j < innerCriteriaLength; j++) {
          if (
            info.criteriaOperator &&
            outerCriteria.subCriteria[i].subCriteria
          ) {
            innerSubCriteriaFound = true;
            if (info.criteriaOperator === "OR") {
              innerORSplitNeeded = true;
              innerIsCsFilter = info.isCsFilter
                ? info.isCsFilter
                : info && info.csFilter
                ? info.csFilter
                : false;
              info = outerCriteria.subCriteria[j];
              if (
                !(
                  info.isCsFilter !== null &&
                  typeof criteria.isCsFilter !== "undefined"
                )
              ) {
                Object.assign(info, { isCsFilter: info.csFilter });
              }
            }
          }
          let rowModel = getCriteriaRowModel(),
            clientSideName;

          if (innerSubCriteriaFound && innerORSplitNeeded) {
            rowModel.conjugate = "or";
          } else if (i > 0 && ORSplitNeeded && j === 0) {
            rowModel.conjugate = "or";
          }
          if (innerSubCriteriaFound) {
            rowModel.not = info.subCriteria[j].negated;
          } else {
            rowModel.not = info.subCriteria[j].negated;
          }

          let rowCsFilter = true;
          // if (innerSubCriteriaFound) {
          //   rowCsFilter = innerIsCsFilter;
          //   if (info.subCriteria && info.subCriteria[0].csFilter !== null && typeof info.subCriteria[0].csFilter !== "undefined") {
          //     rowCsFilter = info.subCriteria[0].csFilter;
          //     if (!(info.subCriteria[0].isCsFilter !== null && typeof info.subCriteria[0].isCsFilter !== "undefined")) {
          //       Object.assign(info, {"isCsFilter": info.subCriteria[0].csFilter})
          //     }
          //   }
          // } else {
          if (
            info.subCriteria[j].isCsFilter !== null &&
            typeof info.subCriteria[j].isCsFilter !== "undefined"
          ) {
            rowCsFilter = info.subCriteria[j].isCsFilter;
          } else if (
            info.subCriteria[j].csFilter !== null &&
            typeof info.subCriteria[j].csFilter !== "undefined"
          ) {
            rowCsFilter = info.subCriteria[j].csFilter;
            Object.assign(info.subCriteria[j], { isCsFilter: rowCsFilter });
          }
          //   }

          rowModel.isCsFilter = rowCsFilter;
          let criteriaKey = info.subCriteria[j];
          if (rowModel.isCsFilter) {
            if (innerSubCriteriaFound) {
              criteriaKey = info.subCriteria[j];
              if (
                criteriaKey.isCsFilter !== null &&
                typeof criteriaKey.isCsFilter !== "undefined"
              ) {
                rowCsFilter = criteriaKey.isCsFilter;
              } else if (
                criteriaKey.csFilter !== null &&
                typeof criteriaKey.csFilter !== "undefined"
              ) {
                rowCsFilter = criteriaKey.csFilter;
                Object.assign(criteriaKey, { isCsFilter: rowCsFilter });
              }
            }
            rowModel.isCsFilter = rowCsFilter;
            clientSideName =
              this.state.columnsDetails.serverToClientNamesMap[
                criteriaKey.attribute == "ip"
                  ? "ANY_IP_ADDRESS"
                  : criteriaKey.attribute
              ];
            if (
              clientSideName === undefined &&
              this.state.columnsDetails.advancedFilters.indexOf(
                criteriaKey.attribute
              ) !== -1
            ) {
              clientSideName = criteriaKey.attribute;
              rowModel.nameInfo.advanced = true;
            }
            rowModel.nameInfo.value = clientSideName;

            rowModel.like.value =
              this.state.columnsDetails.likeInfoToClientMap[
                criteriaKey.filterOperator
              ];
            if (
              this.state.columnsDetails.selectColumns.includes(clientSideName)
            ) {
              rowModel.like.disabled = true;
              rowModel.valueInfo.type = "select";
              let valuesArray =
                this.state.columnsDetails.selectColumnsInfo[
                  rowModel.nameInfo.value
                ];
              for (let k = 0; k < valuesArray.length; k++) {
                if (valuesArray[k].dbName === criteriaKey.value[0]) {
                  rowModel.valueInfo.value = valuesArray[k].name;
                  break;
                }
              }
            } else {
              if (rowModel.like.value === "in" || clientSideName === "SQL") {
                rowModel.valueInfo.type = "multi-text";
                rowModel.valueInfo.value = criteriaKey.value.join("\n");
              } else {
                rowModel.valueInfo.value = criteriaKey.value[0];
              }
            }
            data.push(rowModel);
          } else {
            if (innerSubCriteriaFound) {
              criteriaKey = info.subCriteria[j];
              if(criteriaKey)
              {
              if (
                criteriaKey.isCsFilter !== null &&
                typeof criteriaKey.isCsFilter !== "undefined"
              ) {
                rowCsFilter = criteriaKey.isCsFilter;
              } else if (
                criteriaKey.csFilter !== null &&
                typeof criteriaKey.csFilter !== "undefined"
              ) {
                rowCsFilter = criteriaKey.csFilter;
                Object.assign(criteriaKey, { isCsFilter: rowCsFilter });
              }
            }
            }
            rowModel.isCsFilter = rowCsFilter;
            if(criteriaKey)
            clientSideName =
              this.state.columnsDetails.ifServerToClientNamesMap[
                criteriaKey.attribute == "ip"
                  ? "ANY_IP_ADDRESS"
                  : criteriaKey.attribute
              ];
              
            if (
              clientSideName === undefined &&  criteriaKey &&
              this.state.columnsDetails.ifAdvancedFilters.indexOf(
                criteriaKey.attribute
              ) !== -1
            ) {
              clientSideName = criteriaKey.attribute;
              rowModel.nameInfo.advanced = true;
            }
            rowModel.nameInfo.value = clientSideName;
            if (
              this.state.columnsDetails.ifScalarColumns.includes(clientSideName) &&  criteriaKey
            ) {
              rowModel.like.value =
                this.state.columnsDetails.scalarInfoToClientMap[
                  criteriaKey.filterOperator
                ];
            } else  if(criteriaKey){
              rowModel.like.value =
                this.state.columnsDetails.likeInfoToClientMap[
                  criteriaKey.filterOperator
                ];
            }
            if (
              this.state.columnsDetails.ifSelectColumns.includes(clientSideName)
            ) {
              rowModel.like.disabled = true;
              rowModel.valueInfo.type = "select";
              let valuesArray =
                this.state.columnsDetails.ifSelectColumnsInfo[
                  rowModel.nameInfo.value
                ];
                 if(criteriaKey)
              for (let k = 0; k < valuesArray.length; k++) {
                if (valuesArray[k].dbName === criteriaKey.value[0]) {
                  rowModel.valueInfo.value = valuesArray[k].name;
                  break;
                }
              }
            } else if (  criteriaKey && (
              rowModel.like.value === "in" ||
              clientSideName === "SQL")
            ) {
              rowModel.valueInfo.type = "multi-text";
              rowModel.valueInfo.value = criteriaKey.value.join("\n");
            } else  if(criteriaKey) {
              rowModel.valueInfo.value = criteriaKey.value[0];
            }
            ifData.push(rowModel);
          }
        }
      }
      let clonedObj = cloneDeep(data);
      let clonedIfObj = cloneDeep(ifData);
      this.setState({
        info: data,
        ifInfo: ifData,
        clonedInfo: clonedObj,
        clonedIfInfo: clonedIfObj,
      });
      // this.findMatchingDevices(criteria);
      setTimeout(() => {
        this.applyFilter();
      }, 100);
    }
  }

  onSelectChange = (i, value, columnType, isAdvancedOption, isCsFilter) => {
    let tempInfo =
      isCsFilter || isCsFilter === undefined
        ? [...this.state.info]
        : [...this.state.ifInfo];
    let item = {
      ...tempInfo[i],
    };
    if (columnType === "conjugate") {
      item[columnType] = value;
    } else if (item[columnType].value === undefined) {
      item[columnType].value = "";
      if (item[columnType].value === "") {
        item[columnType].value = value;
      }
    } else {
      item[columnType].value = value;
    }
    if (columnType === "nameInfo") {
      let selectColumns = isCsFilter
        ? this.state.columnsDetails.selectColumns
        : this.state.columnsDetails.ifSelectColumns;
      if (value === "SQL") {
        item.like.disabled = true;
        item.like.value = this.state.columnsDetails.likeInfo[0];
        item.valueInfo.type = "multi-text";
        item.valueInfo.value = "";
        if (item.valueInfo.prevValue === null) {
          item.valueInfo.prevValue = item.valueInfo.value;
        }
      } else if (selectColumns.indexOf(value) !== -1) {
        item.like.disabled = true;
        item.like.value = this.state.columnsDetails.likeInfo[0];
        if (item.valueInfo.prevValue === null) {
          item.valueInfo.prevValue = item.valueInfo.value;
        }
        item.valueInfo.value = "";
        item.valueInfo.type = "select";
      } else if (item.valueInfo.prevValue !== null) {
        item.like.disabled = false;
        item.valueInfo.value = item.valueInfo.prevValue;
        item.valueInfo.prevValue = null;
        if (item.like.value === "in") {
          item.valueInfo.type = "multi-text";
        } else {
          item.valueInfo.type = "text";
        }
      } else if (item.valueInfo.type === "select") {
        item.valueInfo.type = "text";
        item.valueInfo.value = "null";
        item.like.disabled = false;
      }
      if (isAdvancedOption) {
        item.nameInfo.advanced = true;
      } else {
        item.nameInfo.advanced = false;
      }
    } else if (columnType === "like") {
      if (value === "in") {
        if (item.valueInfo.prevValue === null) {
          item.valueInfo.prevValue = item.valueInfo.value;
        }
        item.valueInfo.value = "";
        item.valueInfo.type = "multi-text";
      } else if (item.valueInfo.prevValue !== null) {
        item.valueInfo.value = item.valueInfo.prevValue;
        item.valueInfo.prevValue = null;
        item.valueInfo.type = "text";
      }
    }

    tempInfo[i] = item;
    this.setState(
      isCsFilter
        ? {
            info: tempInfo,
          }
        : {
            ifInfo: tempInfo,
          }
    );
  };
  ontextValueChange = (i, value, columnType, isCsFilter) => {
    let tempInfo = isCsFilter ? [...this.state.info] : [...this.state.ifInfo],
      item = {
        ...tempInfo[i],
      };

    item[columnType].value = value;
    tempInfo[i] = item;
    this.setState(
      isCsFilter
        ? {
            info: tempInfo,
          }
        : {
            ifInfo: tempInfo,
          }
    );
  };
  getSelectField(
    info,
    value,
    i,
    changeCB,
    customStyle = {},
    columnType,
    disabled,
    isCsFilter
  ) {
    let styleInfo = {
        border: "1px solid #CCCCCC",
        fontSize: "14px",
        borderRadius: "3px",
        display: "inline-block",
        height: "26px",
        width: "100%",
      },
      selectClasses = ["select-field"];
    let data = isCsFilter
      ? this.state.info.length > 0
        ? this.state.info[i]
        : null
      : this.state.ifInfo.length > 0
      ? this.state.ifInfo[i]
      : null;
    if (data == null) {
      if (
        this.state.info &&
        this.state.info[0] &&
        this.state.info[0].valueInfo &&
        this.state.info[0].valueInfo.type === "multi-text"
      ) {
        //selectClasses.push("increase");
      }
    } else if (data.valueInfo.type === "multi-text") {
      //selectClasses.push("increase");
    }

    return (
      <Tooltip arrow title={"is"}>
        <SelectField fullWidth 
         // className={selectClasses.join(" ")}
          //style={Object.assign({}, styleInfo, customStyle)} 
          size="small" role="combobox"
          onChange={(event) =>
            changeCB(i, event.target.value, columnType, false, isCsFilter)
          }
          value={value !== "" ? value : info[0]}
          disabled={disabled === true ? true : false}
          dropDownMenuProps={{
            maxHeight: "200px",
          }} 
          //selectedmenuitemstyle={{ color: "rgb(0, 125, 171)" }}
          variant="outlined" aria-label={"Is at row"+i}
          inputProps={{'aria-label':"Is",
                           role: 'combobox',}}
                           MenuProps={{
       MenuListProps:{
        
         'aria-label' :"Is",
         title:"Is"
        }}}
        >
          {info.map((item) => (
            <MenuItem
              key={item}
              value={item}
              children={item}
              
            />
          ))}
        </SelectField>
      </Tooltip>
    );
  }
  getNameField(value, i, changeCB, columnType, isCsFilter, isReset) {
    let heightInc = false;
    let data = isCsFilter
      ? this.state.info.length > 0
        ? this.state.info[i]
        : null
      : this.state.ifInfo.length > 0
      ? this.state.ifInfo[i]
      : null;
    if (data && data.valueInfo.type === "multi-text") {
     // heightInc = true;
    }
    return (
      <CriteriaColumnNamesRenderer
        data={{
          columnNamesInfo: isCsFilter
            ? this.state.columnsDetails.columnNamesInfo
            : this.state.columnsDetails.ifColumnNamesInfo,
          advancedFilters: isCsFilter
            ? this.state.columnsDetails.advancedFilters
            : this.state.columnsDetails.ifAdvancedFilters,
          heightInc: heightInc,
          isCsFilter: isCsFilter,
        }}
        selectedValue={value}
        onChange={(value, isAdvancedOption) => {
          changeCB(i, value, columnType, isAdvancedOption, isCsFilter);
        }}
      />
    );
  }
  getDependentSelectValues(rowInfo, isCsFilter) {
    let columnName = rowInfo.nameInfo.value,
      values = [];
    let columnInfo = isCsFilter
      ? this.state.columnsDetails.selectColumnsInfo[columnName]
      : this.state.columnsDetails.ifSelectColumnsInfo[columnName];
    if (columnInfo && Array.isArray(columnInfo)) {
      let info = columnInfo;
      for (let i = 0; i < info.length; i++) {
        values.push(info[i].name);
      }
    }
    return values;
  }
  addRow = (i, conjugate, isCsFilter) => {
    let newRowModel = getCriteriaRowModel(),
      tempInfo = isCsFilter ? [...this.state.info] : [...this.state.ifInfo];
    if (conjugate === "or") {
      newRowModel.conjugate = "or";
      if (tempInfo[i + 1]) {
        tempInfo[i + 1].conjugate = "or";
      }
    }
    newRowModel.isCsFilter = isCsFilter;
    tempInfo.splice(i + 1, 0, newRowModel);
    this.setState(
      isCsFilter
        ? {
            info: tempInfo,
          }
        : {
            ifInfo: tempInfo,
          }
    );
  };
  removeRow = (i, isCsFilter) => {
    let tempInfo = isCsFilter ? [...this.state.info] : [...this.state.ifInfo];
    tempInfo.splice(i, 1);
    this.setState(
      isCsFilter
        ? {
            info: tempInfo,
          }
        : {
            ifInfo: tempInfo,
          }
    );
  };
  moveRowDown = (i, isCsFilter) => {
    let tempInfo = isCsFilter ? [...this.state.info] : [...this.state.ifInfo];
    if (
      tempInfo[i].conjugate === "or" &&
      tempInfo[i + 1] &&
      tempInfo[i + 1].conjugate === "and"
    ) {
      tempInfo[i].conjugate = "and";
      tempInfo[i + 1].conjugate = "or";
    }
    let tempRow = tempInfo.splice(i, 1);
    tempInfo.splice(i + 1, 0, tempRow[0]);
    this.setState(
      isCsFilter
        ? {
            info: tempInfo,
          }
        : {
            ifInfo: tempInfo,
          }
    );
  };
  moveRowUp = (i, isCsFilter) => {
    let tempInfo = isCsFilter ? [...this.state.info] : [...this.state.ifInfo];
    if (
      tempInfo[i].conjugate === "and" &&
      tempInfo[i - 1] &&
      tempInfo[i - 1].conjugate === "or"
    ) {
      tempInfo[i].conjugate = "or";
      tempInfo[i - 1].conjugate = "and";
    }
    let tempRow = tempInfo.splice(i, 1);
    tempInfo.splice(i - 1, 0, tempRow[0]);
    this.setState(
      isCsFilter
        ? {
            info: tempInfo,
          }
        : {
            ifInfo: tempInfo,
          }
    );
  };
  moveGroupRow = (i, isCsFilter) => {
    let tempInfo = isCsFilter ? [...this.state.info] : [...this.state.ifInfo],
      pos = this.ORIndices.indexOf(i),
      sourceStartPos = this.ORIndices[pos],
      sourceEndPos,
      destStartPos,
      destEndPos;
    //calculating source end pos
    if (pos === this.ORIndices.length - 1) {
      sourceEndPos = this.state.info.length - 1;
    } else {
      sourceEndPos = this.ORIndices[pos + 1] - 1;
    }
    //calculating dest start and end positions
    destStartPos = this.ORIndices[pos - 1];
    destEndPos = sourceStartPos - 1;

    //modify conjugate info
    if (tempInfo[sourceStartPos] && tempInfo[destStartPos]) {
      tempInfo[destStartPos].conjugate = "or";
      if (destStartPos === 0) {
        tempInfo[sourceStartPos].conjugate = "and";
      } else {
        tempInfo[sourceStartPos].conjugate = "or";
      }
      let data = tempInfo.splice(
        sourceStartPos,
        sourceEndPos - sourceStartPos + 1
      );
      tempInfo.splice(destStartPos, 0, ...data);
      this.setState(
        isCsFilter
          ? {
              info: tempInfo,
            }
          : {
              ifInfo: tempInfo,
            }
      );
    }
  };
  getContent2(){
    const { classes } = this.props;
    let content=
    <Table className={classes.table} aria-label="Criteria table">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderCell}
                  ></TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderCell}
                  ></TableCell>
                  <TableCell align="left" className={classes.tableHeaderCell} id="filter-field">
                    Field
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderCell}
                    id="filter-selectfield-operator-filter"
                  >
                    Operator
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeaderCell}>
                    Value
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderCell}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                displayRowCheckbox={false}
                deselectOnClickaway={false}
                showRowHover={false}
                stripedRows={false}
              ></TableBody>
</Table>
return content;



  }
  getContent() {
    let content = [],
      ifContent = [],
      row,
      notLabel = Resource.get("not"); //not
    this.ORIndices.length = 0;
    let filterTypeLength =
      this.state.ifInfo && this.state.ifInfo.length ? 2 : 1;
    
    
    for (let count = 0; count < filterTypeLength; count++) {
      let data = count === 0 ? this.state.info : this.state.ifInfo;
      for (let i = 0; i < data.length; i++) {
        let increaseRowHeight =data[i].valueInfo.type === "multi-text" ? true : false,
           
          rowInfoClasses = ["row-info"],
          conjugateClasses = ["conjugate"],
          isFirstInGroup = false,
          isLastInGroup = false;
        if (increaseRowHeight) {
          rowInfoClasses.push("increase");
          conjugateClasses.push("increase");
        }
        if (i === 0 || data[i].conjugate.toUpperCase() === "OR") {
          conjugateClasses.push("hide");
          isFirstInGroup = true;
          this.ORIndices.push(i);
        }
        if (
          i === data.length - 1 ||
          (data[i + 1] && data[i + 1].conjugate.toUpperCase() === "OR")
        ) {
          isLastInGroup = true;
        }

        row = (
          <div key={"filters" + count + "_" + i}>
            {isFirstInGroup && i !== 0 ? (
              <div className="row-container">
                <div
                  style={{
                    backgroundColor: "#fff",
                  }}
                  className="row-info"
                >
                  <div
                    style={{
                      width: "10%",
                      minWidth: "10%",
                    }}
                    className="conjugate"
                  >
                    {data[i].conjugate.toUpperCase()}
                  </div>
                </div>
                <div
                  style={{
                    height: "32px",
                    paddingTop: "2px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                  className="row-actions-container"
                >
                  <div
                    className="row-actions arrow"
                    style={{
                      marginRight: "32px",
                    }}
                    onClick={() => this.moveGroupRow(i, "up")}
                  >
                  <Tooltip title="Move row up">
                    <IconButton  aria-label="Move row up" style={{ padding: "1px" }}>
                      <ArrowUpwardIcon />
                    </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    className="row-actions arrow"
                    onClick={() => this.moveGroupRow(i, "down")}
                  >
                   <Tooltip title="Move row down">
                    <IconButton aria-label="Move row down" style={{ padding: "1px" }}>
                      <ArrowDownwardIcon />
                    </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row-container">
              <div className={rowInfoClasses.join(" ")}>
                <div
                  style={{
                    width: "10%",
                    minWidth: "10%",
                  }}
                  className={conjugateClasses.join(" ")}
                >
                  {data[i].conjugate.toUpperCase()}
                </div>
                <span
                  style={{
                    boxSizing: "border-box",
                    height: "20px",
                   
                    marginTop: increaseRowHeight ? "22px" : "6px",
                  }}
                ></span>
                <div
                  style={{
                    width: "10%",'align-items': 'center',
   
                  }}
                >
                  <Tooltip arrow title={notLabel}>
                    <FormControlLabel
                     
                      control={
                        <Checkbox aria-label={notLabel}
                          disabled={
                            data[i].nameInfo.value === "SQL" ? true : false
                          }
                          checked={
                            data[i].nameInfo.value === "SQL"
                              ? false
                              : data[i].not
                              ? true
                              : false
                          }
                          color="primary"
                          disableRipple={true}
                          onChange={(event) => {
                            let tempInfo = [...data],
                              item = {
                                ...tempInfo[i],
                              };
                            item.not = event.target.checked;
                            tempInfo[i] = item;
                            if (count === 0) {
                              this.setState({ info: tempInfo });
                            } else {
                              this.setState({ ifInfo: tempInfo });
                            }
                          }}
                        />
                      }
                      label={notLabel}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    width: "25%",
                  }}
                >
                  {this.getNameField(
                    data[i].nameInfo.value,
                    i,
                    this.onSelectChange,
                    "nameInfo",
                    data[i].isCsFilter
                  )}
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  {this.getSelectField(
                    this.state.columnsDetails.ifScalarColumns.includes(
                      data[i].nameInfo.value
                    )
                      ? this.state.columnsDetails.scalarOperator
                      : this.state.columnsDetails.likeInfo,
                    data[i].like.value,
                    i,
                    this.onSelectChange,
                    {
                      "min-width": "150px",
                    },
                    "like",
                    data[i].like.disabled,
                    data[i].isCsFilter
                  )}
                </div>
                <div
                  style={{
                    width: "35%",
                  }}
                >
                  {data[i].valueInfo.type === "select" ? (
                    this.getSelectField(
                      this.getDependentSelectValues(
                        data[i],
                        data[i].isCsFilter
                      ),
                      data[i].valueInfo.value,
                      i,
                      this.onSelectChange,
                      {
                        "min-width": "220px",
                      },
                      "valueInfo",
                      false,
                      data[i].isCsFilter
                    )
                  ) : data[i].valueInfo.type === "text" ? (
                    <div >
                      <Tooltip arrow title={"value"}>
                        <TextField size="small" fullWidth inputProps={{style:{height:'20px'}}}
                          onChange={(e) => {
                            const value = e.target.value.replace(/^\s+/g, "");
                            this.ontextValueChange(
                              i,
                              value,
                              "valueInfo",
                              data[i].isCsFilter
                            );
                          }}
                          placeholder="null"
                          aria-label="value"
                          value={data[i].valueInfo.value}
                          type="text"
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip arrow title={"value"}>
                    <CustomTextArea
            id="description"
            minRows={1}
            multiline={true}
            size="small"
            InputProps={{style:{maxHeight: '32px', } }}
            value={data[i].valueInfo.value}
           
            onChange={(e) => {
                          const value = e.target.value.replace(/^\s+/g, "");
                          this.ontextValueChange(
                            i,
                            value,
                            "valueInfo",
                            data[i].isCsFilter
                          );
                        }}
            maxRows={3}
          />
                      {/*<TextField size="small" fullWidth inputProps={{style:{height:'20px'}}}
                        name="text-area"
                       // className="multi-text-field"
                        onChange={(e) => {
                          const value = e.target.value.replace(/^\s+/g, "");
                          this.ontextValueChange(
                            i,
                            value,
                            "valueInfo",
                            data[i].isCsFilter
                          );
                        }}
                        value={data[i].valueInfo.value
                      ></TextField>*/}
                    </Tooltip>
                  )}
                </div>
              </div>
              <div
                className="row-actions-container"
                style={{
                  height: increaseRowHeight ? "64px" : "32px",
                  paddingTop: increaseRowHeight ? "2px" : "2px",
                  paddingBottom: increaseRowHeight?"30px":"2px",
                }}
              >
                <Tooltip arrow title={"and"}>
                  <Button variant="outlined" size="small" style={{backgroundColor:'#FFFFFF'}}
                    className=" and"
                    onClick={() => this.addRow(i, "and", data[i].isCsFilter)}
                  >
                    AND
                  </Button>
                </Tooltip>
                <span
                  style={{
                    boxSizing: "border-box",
                    height: "20px",
                    borderLeft: "1px solid #D8D8D8",
                    margin: "6px 8px 0 8px",
                  }}
                ></span>
                <Tooltip arrow title={"or"}>
                  <Button variant="outlined" size="small" style={{backgroundColor:'#FFFFFF'}}
                    className=" or"
                    onClick={() => this.addRow(i, "or", data[i].isCsFilter)}
                  >
                    OR
                  </Button>
                </Tooltip>
                {this.state.info.length > 1 || this.state.ifInfo.length > 1 ? (
                  <Tooltip title="Remove">
                  <IconButton aria-label="Remove"
                    className=" remove"
                    onClick={() => this.removeRow(i, data[i].isCsFilter)}
                  >
                    <div style={{ padding: "4px" }}>
                      <DeleteIcon stroke="red" role="button" title="Remove" />
                    </div>
                  </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )}
                {(this.state.info.length > 1 || this.state.ifInfo.length) > 1 &&
                !isLastInGroup ? (
                  <button
                    className="row-actions arrow"
                    onClick={() => this.moveRowDown(i, data[i].isCsFilter)}
                  >
                    <div style={{ padding: "1px" }}>
                      <ArrowDownwardIcon />
                    </div>
                  </button>
                ) : (
                  ""
                )}
                {(this.state.info.length > 1 || this.state.ifInfo.length) > 1 &&
                !isFirstInGroup ? (
                  <div
                    className="row-actions arrow"
                    onClick={() => this.moveRowUp(i, data[i].isCsFilter)}
                  >
                    <div style={{ padding: "1px" }}>
                      <ArrowUpwardIcon />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        );
        if (count === 0) {
          content.push(row);
        } else {
          ifContent.push(row);
        }
      }
    }
    let InterfaceLabel = Resource.get("Interfaces Filter"); //Interface Filters
    let filterRows = (
      <div
        style={{
          textalign: "center",
        }}
      >
      <div className="row-container">
      <div className="row-info" style={{backgroundColor:'#FFFFFF'}}>
        <div
          style={{
            width: "10%",
            minWidth: "10%",
          }}
          
        >
          <InputLabel style={{marginLeft:'16px'}}>AND/OR</InputLabel>
        </div>
        <span
          style={{
            boxSizing: "border-box",
            height: "20px",
            
            marginTop:  "6px",
          }}
        ></span>
        <div
          style={{
            width: "10%",
          }}
        >
          <Tooltip arrow title={notLabel}>
            <InputLabel >Not</InputLabel>
          </Tooltip>
        </div>
        <div
          style={{
            width: "25%",
          }}
        >
          <InputLabel >Field</InputLabel>
        </div>
        <div
          style={{
            width: "20%",
          }}
        >
        <InputLabel >Operator</InputLabel>
        </div>
        <div
          style={{
            width: "35%",
          }}
        >
            <Tooltip arrow title={"value"}>
              <InputLabel >Value</InputLabel>
               
               
            </Tooltip>
          
        </div>
      </div>
      <div
        className="row-actions-container"
        style={{
          height:  "32px",
          paddingTop: "2px",
          paddingBottom: "2px",
         backgroundColor:'#FFFFFF'
          
        }}
      >
        <Tooltip arrow title={"and"}>
          <InputLabel 
            
           
          >
            Actions
          </InputLabel>
        </Tooltip>
       
      </div>
    </div>
    
    <InputLabel>{DeviceFilterLabel}</InputLabel>
        {content}
        <InputLabel>{InterfaceLabel}</InputLabel>
        {ifContent}
      </div>
    );

    return this.state.ifInfo && this.state.ifInfo.length ? filterRows : content;
  }
  pushRowIntoANDGroup = (ref, info) => {
    let isCsFilter = info.isCsFilter;
    let data = {
      isCsFilter: isCsFilter,
    };
    data.negated = info.not;
    if (info && info.nameInfo && info.nameInfo.advanced) {
      data.attribute = info.nameInfo.value;
    } else if (info.nameInfo) {
      data.attribute = isCsFilter
        ? this.state.columnsDetails.clientToServerNamesMap[info.nameInfo.value]
        : this.state.columnsDetails.ifClientToServerNamesMap[
            info.nameInfo.value
          ];
    }

    if (
      info.like &&
      this.state.columnsDetails.ifScalarColumns.includes(info.nameInfo.value)
    ) {
      data.filterOperator =
        this.state.columnsDetails.scalarInfoToServerMap[info.like.value];
    } else if (info.like) {
      data.filterOperator =
        this.state.columnsDetails.likeInfoToServerMap[info.like.value];
    }
    data.value = [];
    if (info.valueInfo) {
      if (info.valueInfo.type === "multi-text") {
        if (info.nameInfo.value === "SQL") {
          data.value.push(info.valueInfo.value);
        } else {
          let values = info.valueInfo.value.split("\n");
          data.value.push(...values);
        }
      } else if (info.valueInfo.type === "select") {
        let valuesArray = isCsFilter
          ? this.state.columnsDetails.selectColumnsInfo[info.nameInfo.value]
          : this.state.columnsDetails.ifSelectColumnsInfo[info.nameInfo.value];
        for (let i = 0; i < valuesArray.length; i++) {
          if (info.valueInfo.value === "") {
            data.value.push(valuesArray[i].dbName);
            break;
          }
          if (valuesArray[i].name === info.valueInfo.value) {
            data.value.push(valuesArray[i].dbName);
            break;
          }
        }
      } else {
        data.value.push(info.valueInfo.value);
      }
    }
    ref.subCriteria.push(data);
  };
  getChoosenCriteria = () => {
    let subCriteria = [];
    let filterLength =
      this.state.ifInfo && this.state.ifInfo.length > 0 ? 2 : 1;
    for (let count = 0; count < filterLength; count++) {
      let tempInfo =
          count === 0 ? [...this.state.info] : [...this.state.ifInfo],
        firstRow = {
          ...tempInfo[0],
        },
        result;
      if (firstRow) {
        firstRow.conjugate = "and";
        tempInfo[0] = firstRow;
      }
      //construct criteria
      let AND_Groups = [],
        block = {
          isCsFilter: count === 0 ? true : false,
          criteriaOperator: "AND",
          subCriteria: [],
        };
      for (let i = 0; i < tempInfo.length; i++) {
        if (tempInfo[i].conjugate === "or") {
          let temp = {
            ...block,
          };
          AND_Groups.push(temp);
          block = {
            isCsFilter: count === 0 ? true : false,
            criteriaOperator: "AND",
            subCriteria: [],
          };
        }
        this.pushRowIntoANDGroup(block, tempInfo[i]);
      }
      AND_Groups.push({
        ...block,
      });

      if (AND_Groups.length > 1) {
        result = {
          isCsFilter: count === 0 ? true : false,
          criteriaOperator: "OR",
          subCriteria: [...AND_Groups],
        };
      } else if (AND_Groups.length === 1) {
        result = AND_Groups[0];
      }
      subCriteria.push(result);
    }
    if (subCriteria.length > 1) {
      return { criteriaOperator: "AND", subCriteria: subCriteria };
    } else {
      return subCriteria[0];
    }
  };
  applyFilter = () => {
    let criteria = this.getChoosenCriteria();
    this.findMatchingDevices(criteria);
  };
  findMatchingDevices(result) {
    this.props.onBeforeDevicesFind(true);
    groupApi.findMatchingDevices(result, this.state.accountId).then(
      (response) => {
        this.props.onDevicesFind({
          matchingDevices: response.data._items,
          error: null,
          updatingFromCriteria: true,
        });
        this.props.onErrorFlg({
          showErrorMsg: false,
        });
      },
      (error) => {
        this.props.onDevicesFind({
          matchingDevices: null,
          error: error.response.data,
          updatingFromCriteria: true,
        });
        this.props.onErrorMsg({
          errorMsg: error.response.data.errorMsg,
        });
        this.props.onErrorFlg({
          showErrorMsg: true,
        });
      }
    );
  }
  resetClick = () => {
    let clonedObj = cloneDeep(this.state.clonedInfo);
    let clonedIfObj = cloneDeep(this.state.clonedIfInfo);
    this.setState(
      {
        info: clonedObj,
        ifInfo: clonedIfObj,
      },
      () => {
        this.applyFilter();
      }
    );
  };
  render() {
    let criteriaHeader = Resource.get("Define Criteria"), //Define Criteria
      resetLabel = Resource.get("Reset"), //Reset
      hideLabel = Resource.get("Hide"), //Hide
      showLabel = Resource.get("Show"), //Show
      displayBtnName = Resource.get("Find Devices"), //Find Devices
      contentClasses = ["content"];
    if (!this.state.show) {
      contentClasses.push("hide");
    }
    
    return (
      <div id="criteria-container" className="criteria-container"  style={
       this.state.show
          ? {
             
              border: "1px solid #1D5BBF",
              borderRadius: "3px",
            }
          : {
             
              backgroundColor: "#F0F5FC",
              borderRadius: "3px",
            }
      }>
        <div
          className="header"
          style={{ display: "flex", alignItems: "center" , height: "32px",}}
        >
          
          <Tooltip title={this.state.show ? "Collapse":"Expand"}>
           
            <IconButton
            onClick={() => {
              this.props.showCriteria(!this.state.show);
                this.setState({
                  show: !this.state.show,
                });
            }}
            style={{padding:'2px',color:'#1D5BBF',marginLeft:'3px',marginRight:'3px'}}
           aria-label={this.state.show?"Collapse":"Expand"}
          >
            {this.state.show ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </IconButton>
          </Tooltip>

          <Typography className="device_filter_title" component="h2">
            {criteriaHeader}
          </Typography>
          
        </div>
        
        <div className={contentClasses.join(" ")}>
          {" "}
          
          {this.getContent()}
          <div className="actions">
            <Tooltip arrow title={displayBtnName}>
              <RaisedButton
                variant="contained"
                color="primary"
                children={displayBtnName}
                //classes={{ root: "action-button", label: "buttonLabelStyle" }}
                onClick={() => this.applyFilter()}
                disabled={this.props.type == "AUTOMATIC"}
              />
            </Tooltip>
            <Tooltip arrow title={resetLabel}>
              <RaisedButton
                style={{
                  
                  marginLeft: "20px",
                 
                }}
                variant="text"
                name={resetLabel}
                onClick={this.resetClick}
                disabled={this.props.type == "AUTOMATIC"}
              >
               
                  {resetLabel}
               
              </RaisedButton>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Criteria);