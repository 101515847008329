import React, { Component } from "react";
import {
  HomeIcon,
  SetupWizardIcon,
  AlarmsIcon,
  InventoryIcon,
  DashboardsIcon,
  HelpIcon,
  GroupsIcon,
  SettingsIcon,
  CommunityIcon,
  ReportsIcon,
} from "../ui-components/uim-components";
import { Drawer } from "@mineral/core";
import {
  ListItemButton,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,Tooltip,
} from "@mineral/core";
import "./primaryNav.less";
import SLMIcon from "../icons/SLM";
import Resource from "./Resource";
import { getAlarmUrl } from "./../../utils/common";
import get from "lodash/get";

const communityLink =
  "https://community.broadcom.com/enterprisesoftware/communities/communityhomeblogs?CommunityKey=170eb4e5-a593-4af2-ad1d-f7655e31513b";

// const handleHelpItemClick = (location) => {
//   let path = location.pathname;
//   if (path === "/overview") {
//     window.IPCE.open({ search: "Manage Overview" });
//   } else if (path === "/uim-alarms") {
//     window.IPCE.open({ search: "Manage Alarms" });
//   } else if (path === "/groups/0/treeviewnew") {
//     window.IPCE.open({ search: "Manage Groups" });
//   } else if (path === "/inventory") {
//     window.IPCE.open({ search: "Manage Inventory" });
//   } else if (path === "/discover-devices-tiles") {
//     window.IPCE.open({ search: "Discovery devices" });
//   } else if (path === "/settings/alarm-policies") {
//     window.IPCE.open({ search: "Alarm Policies" });
//   } else if (path === "/settings/personalize") {
//     window.IPCE.open({ search: "Personalize" });
//   } else if (path === "/settings/performancereports") {
//     window.IPCE.open({ search: "Performance Reports" });
//   }
// };

const NavListItem = ({ leftIcon: LeftIcon, ...props }) => {
  return (
    <ListItem disableGutters style={{ padding: 0 }}>
     <Tooltip title={props.expandedView?"":props.primaryText} placement="right-start">
      <ListItemButton
        //leftIcon={<LeftIcon className="menu__icon" title={props.title} />}
        // ContainerComponent={
        //   <NavLink
        //
        //   {...props.navLink}
        //   />
        // }
        onClick={() => {
          //  props.navigate(props.navLink.to);
          props.close(props.navLink.to, props.title);
        }}
        classes={{
          root: `list__item ${
            props.selected ? "nav-primary__link--active" : ""
          }`,
        }} aria-roledescription={"Click to open "+props.title}
        alignItems="center"
        role="link"
        {...props}
        style={{ ...props.style, paddingTop: 10, paddingBottom: 10 }}
      >
     
        <ListItemIcon>
          <LeftIcon
            className="menu__icon"
           // title={props.title}
            aria-label={props.primaryText}
            ariaLabel={props.primaryText}
            role="figure"
            aria-hidden={true}
          />
        </ListItemIcon>
        
        <ListItemText
          primary={props.primaryText}
          style={{
            display: props.expandedView ? "block" : "none",
            margin: 0,
          }}
        />
        {/* {text} */}

        {props.children}
      </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

const WIDTH_COLLAPSED = 56;
const WIDTH_EXPANDED = 200;
const TRANSITION_DELAY_LONG = "0s";
const TRANSITION_DELAY_NONE = "0s";

let poll = async (fn, ms) => {
  while (true) {
    await wait(ms);
    await fn();
  }
};

let wait = (ms = 5000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

class PrimaryNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      expanded: false,
      transitionDelay: TRANSITION_DELAY_LONG,
      width: WIDTH_COLLAPSED,
      selectedNode: "Home",
    };

    this.updateNavLink = this.updateNavLink.bind(this);
    window.addEventListener("updateNavLinks", this.updateNavLink);
  }

  updateNavLink(data) {
    const hrefToLocationName = (href) => {
      if (href.includes("/settings")) return "Settings";
      if (href.includes("/getting-started")) return "Setup Wizard";
      if (href.includes("/remote-cloud-tiles")) return "Setup Wizard";
      if (href.includes("/discover-devices-tiles")) return "Setup Wizard";

      if (href.includes("/overview")) return "Home";
      if (href.includes("/uim-alarms")) return "Alarms";
      if (href.match("/groups")) return "Groups";
      if (href.includes("/inventory")) return "Inventory";
      if (href.match(/computer-systems\/(\d+)/)) return "Inventory";
      if (href.includes("/technologies")) return "Dashboards";
      if (href.includes("/mcsDeploymentStatus")) return "Dashboards";
      if (href.includes("/reports")) return "Reports";
      if (href.includes("/slm")) return "SLM";

      if (href.includes("/operatorconsole_portlet/standalone_login"))
        return this.props.landingPage;
    };

    let selectedNode;
    if (data && data.detail) selectedNode = hrefToLocationName(data.detail);
    if (!selectedNode) selectedNode = hrefToLocationName(window.location.href);
    if (!selectedNode) selectedNode = this.props.landingPage;

    if (this.state.selectedNode !== selectedNode)
      this.setState({ selectedNode: selectedNode });
  }

  componentWillMount() {
    this.updateNavLink();

    this.unlisten = this.props.history.listen((location, action) => {});
  }

  componentWillUnmount() {
    this.unlisten();
  }
  componentDidMount() {
    const level = 0; // hard coded as zero to bring level 0 data
    poll(() => {
      if (window.location.pathname.indexOf("treeviewnew") > -1) {
        // console.log("polling tree data after every 5 seconds");
        return Promise.all([
          this.props.fetchGroups(level, true),
          this.props.fetchStates(level, true),
          this.props.fetchCount(level, true),
        ]);
      }
    }, 5000);
  }

  close = (navLink, title) => {
    this.setState({
      selectedNode: title,
    });
    this.navigate(navLink);
    this.props.handleRedux();
  };
  navigate = (navLink) => {
    this.props.history.push(navLink);
  };
  toggle = () => {
    let open = !this.state.open;
    this.setState({
      open: open,
    });
    return this;
  };

  render() {
    this.updateNavLink();
    const expandedView = this.state.expanded;
    let slmAdmin = get(this.props.acl.aclInfo, "roles", "").includes(
      "SLM Admin"
    );
    let slmView = get(this.props.acl.aclInfo, "roles", "").includes("SLM View");
    let sloAccess = get(this.props.acl.aclInfo, "roles", "").includes(
      "SLO Access"
    );
    let isBusUser = get(this.props.acl.aclInfo, "userType", "").includes(
      "nimbus"
    );

    const {
      canManagePolicy,
      canViewPolicy,
      canAdministerAccount,
      isPortalAdministrator,
      canDesignDashboard,
      canScheduleReports,
      canPerformanceReports
    } = this.props.acl;
    let disableSettingsPage = !(
      canManagePolicy ||
      canViewPolicy ||
      canAdministerAccount ||
      isPortalAdministrator ||
      canDesignDashboard ||
      canScheduleReports ||
      canPerformanceReports
    );
    let helpLink =
      "https://techdocs.broadcom.com/bin/gethidpage?hid=UIM_RN&space=UIM204&language=&format=rendered";
    if (!this.props.saas) {
      helpLink =
        "https://techdocs.broadcom.com/bin/gethidpage?hid=OChome&space=UIM204&language=&format=rendered";
    }
    return (
      <nav
        className={
          this.state.expanded ? "nav--primary expanded" : "nav--primary"
        }
        onClick={(evt) => {
          let listItem = evt.target.closest(".list__item");
          if (!listItem) {
            return;
          }
          this.setState({
            expanded: false,
            width: WIDTH_COLLAPSED,
            transitionDelay: TRANSITION_DELAY_NONE,
          });
        }}
        onMouseEnter={(evt) => {
          this.setState({
            open: true,
            expanded: true,
            width: WIDTH_EXPANDED,
            transitionDelay:
              evt.target.offsetWidth === WIDTH_COLLAPSED
                ? TRANSITION_DELAY_LONG
                : TRANSITION_DELAY_NONE,
          });
        }}
        onMouseLeave={(evt) => {
          this.setState({
            width: WIDTH_COLLAPSED,
            transitionDelay: TRANSITION_DELAY_NONE,
            expanded: false,
          });
        }}
        onTransitionEnd={(evt) => {
          if (evt.propertyName === "width") {
            this.setState({
              transitionDelay: TRANSITION_DELAY_NONE,
              expanded: evt.target.offsetWidth === WIDTH_EXPANDED,
            });
          }
        }}
      >
        <Drawer
          classes={{
            modal: "nav-drawer__container",
            paper: "nav-drawer__container",
          }}
          containerStyle={{
            transition: `width 50ms cubic-bezier(0.23, 1, 0.32, 1) ${this.state.transitionDelay}`,
            boxShadow: "1px 0 3px 0 rgba(0,0,0,0.12)",
            height: null,
          }}
          width={this.state.width}
          style={{ width: this.state.width }}
          open={true}
          variant="permanent"
          onClose={(open) => this.setState({ open: false })}
        >
          <List className="nav__list--primary">
            {this.props.ocviews.canOcviewOverview ? (
              <NavListItem
              
                //Home
                primaryText={Resource.get("Home")}
                expandedView={expandedView}
                navLink={{
                  to: "/overview",
                }}
                selected={this.state.selectedNode == "Home"}
                leftIcon={HomeIcon}
                close={this.close}
              />
            ) : null}
            {this.props.ocviews.canOcviewAlarms ? (
              this.props.saas &&
              this.props.doiurl &&
              this.props.featureFlags &&
              this.props.featureFlags.globalAlarmsView ? (
                <ListItem
                
                  innerdivstyle={{
                    padding: "16px",
                  }}
                
                  /* primaryText={
                    expandedView ? (
                      <div className="list-item__text">
                      {Resource.get("Alarms")}
                      </div>
                      ) : null
                    } */
                  //leftIcon={<AlarmsIcon className="menu__icon" title="Help" />}
                  href={getAlarmUrl(this.props.doiurl)}
                >
                  <ListItemIcon>
                    <AlarmsIcon
                      className="menu__icon"
                      title="Alarms"
                      aria-labelledby={"alarm-listItem"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={"alarm-listItem"}
                    primary={
                      //Alarms
                      expandedView ? (
                        <div className="list-item__text">
                          {Resource.get("Alarms")}
                        </div>
                      ) : null
                    }
                  />
                </ListItem>
              ) : (
                <NavListItem
                 
                  //Alarms
                  primaryText={Resource.get("Alarms")}
                  expandedView={expandedView}
                  navLink={{
                    to: "/uim-alarms",
                  }}
                  selected={this.state.selectedNode == "Alarms"}
                  leftIcon={AlarmsIcon}
                  close={this.close}
                />
              )
            ) : null}
            {this.props.ocviews.canOcviewGroups ? (
              <NavListItem
               
                //Groups
                primaryText={Resource.get("Groups")}
                expandedView={expandedView}
                selected={this.state.selectedNode == "Groups"}
                navLink={{
                  to: "/groups/0/treeviewnew",
                }}
                leftIcon={GroupsIcon}
                close={this.close}
              />
            ) : null}
            {this.props.ocviews.canOcviewInventory ? (
              <NavListItem
               
                //Inventory
                primaryText={Resource.get("Inventory")}
                expandedView={expandedView}
                selected={this.state.selectedNode == "Inventory"}
                navLink={{
                  to: "/inventory",
                  isActive: (match, location) => {
                    return (
                      !!match ||
                      (!location.pathname.match("/groups") &&
                        !!location.pathname.match("/computer-systems"))
                    );
                  },
                }}
                leftIcon={InventoryIcon}
                close={this.close}
              />
            ) : null}
            {this.props.ocviews.canOcviewDashboards ? (
              <NavListItem
               
                //Dashboards
                primaryText={Resource.get("Dashboards")}
                expandedView={expandedView}
                selected={this.state.selectedNode == "Dashboards"}
                navLink={{
                  to: "/technologies",
                  isActive: (match, location) => {
                    return !!match || !!location.pathname.match("/uim-cabi");
                  },
                }}
                leftIcon={DashboardsIcon}
                close={this.close}
              />
            ) : null}
            {this.props.ocviews.canOcviewReports ? (
              <NavListItem
                
                //Reports
                primaryText={Resource.get("Reports")}
                selected={this.state.selectedNode == "Reports"}
                expandedView={expandedView}
                navLink={{
                  to: "/reports",
                  isActive: (match, location) => {
                    return !!match;
                  },
                }}
                leftIcon={ReportsIcon}
                close={this.close}
              />
            ) : null}
            {this.props.acl &&
            (slmAdmin || slmView || sloAccess) &&
            this.props.ocviews.canOcviewSLM ? (
              <NavListItem
               
                //SLM
                primaryText={Resource.get("SLM")}
                expandedView={expandedView}
                selected={this.state.selectedNode == "SLM"}
                navLink={{
                  to: "/slm",
                  isActive: (match, location) => {
                    return !!match;
                  },
                }}
                leftIcon={SLMIcon}
                close={this.close}
              />
            ) : null}
            <li style={{ flexGrow: "2" }} />
            {this.props.acl && !disableSettingsPage ? (
              <NavListItem
               
                //Settings
                primaryText={Resource.get("Settings")}
                selected={this.state.selectedNode == "Settings"}
                expandedView={expandedView}
                navLink={{
                  to: "/settings",
                }}
                leftIcon={SettingsIcon}
                close={this.close}
              />
            ) : null}
            {this.props.acl &&
            this.props.acl.isBusUser &&
            (this.props.acl.canManageDiscovery ||
              (this.props.acl.canDeployRobot &&
                this.props.acl.canMonitorConfigService &&
                this.props.acl.canEditMCSTemplates &&
                this.props.acl.canMonitorConfigService)) ? (
              <NavListItem
                
                //Setup Wizard
                primaryText={Resource.get("Setup Wizard")}
                //Setup Wizard
                selected={
                  this.state.selectedNode == Resource.get("Setup Wizard")
                }
                expandedView={expandedView}
                navLink={{
                  to: "/getting-started",
                  isActive: (match, location) => {
                    return (
                      !!match || !!location.pathname.match("/configuration/")
                    );
                  },
                }}
                leftIcon={SetupWizardIcon}
                close={this.close}
              />
            ) : null}
            <NavListItem
              
              //Community
              primaryText={Resource.get("Community")}
              leftIcon={CommunityIcon}
              //Community
              selected={this.state.selectedNode == Resource.get("Community")}
              expandedView={expandedView}
              onClick={() => window.open(communityLink, "_blank").focus()}
            />
            <NavListItem
              
              //Help
              primaryText={Resource.get("Help")}
              leftIcon={HelpIcon}
              //Help
              selected={this.state.selectedNode == Resource.get("Help")}
              expandedView={expandedView}
              onClick={(event) => window.open(helpLink, "_blank").focus()}
            />
          </List>
        </Drawer>
      </nav>
    );
  }
}

export default PrimaryNav;
