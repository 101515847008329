import React, { Component } from 'react'
import { detect } from 'detect-browser'
import { ErrorIcon as Information } from '../ui-components/uim-components'
import {Snackbar} from "@mineral/core"
import './supportedBrowser.less'
import Resource from './Resource'

class SupportedBrowser extends Component {
  state = {
    show: false,
  }
  componentWillMount() {
    const browser = detect()
    const versionNumber = browser.version.split('.')[0]
    if (
      (browser.name === 'firefox' && versionNumber > 54) ||
      (browser.name === 'chrome' && versionNumber > 32)
    ) {
      this.setState({
        show: false,
      })
    } else {
      this.setState({
        show: true,
      })
    }
  }
  handleDismissSnackbarClick = () => {
    this.setState({
      show: false,
    })
  }
  render() {
    let component = null
    if (this.state.show) {
      component = (
        <div className="unsupported-browser__snackbar">
          <Snackbar
            message={
              <div className="snackbar__content">
                <Information className="snackInformation__icon" />
                <p style={{ width: '100%' }}>
                  {// Unsupported browser message
                  Resource.get(
                    'Infrastructure management works best on a select set of browsers. '
                  )}
                  <a
                    href="https://techdocs.broadcom.com/bin/gethidpage?hid=HID_UIM_Matrix&space=UIM204&language=&format=rendered"
                    target="_blank" rel="noreferrer">
                    {//text to link to documentation of unsupported browsers
                    Resource.get('List of browsers')}
                  </a>.
                </p>
              </div>
            }
            action={
              <span
                onClick={this.handleDismissSnackbarClick}
                style={{
                  color: '#00AEEF',
                  lineHeight: '16px',
                  height: '16px',
                  cursor: 'pointer',
                }}>
                DISMISS
              </span>
            }
            open={true}
            style={{
              width: '688px',
              height: '88px',
              backgroundColor: '#333133',
            }}
            bodyStyle={{
              width: '688px',
              height: '88px',
              backgroundColor: '#333133',
            }}
            contentStyle={{
              width: '600px',
              height: '40px',
              paddingTop: '24px',
              lineHeight: '20px',
              backgroundColor: '#333133',
              display: 'inline-flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onRequestClose={this.handleDismissSnackbarClick}
          />
        </div>
      )
    }
    return component
  }
}

export default SupportedBrowser
