
import React, { Component } from "react";

import {
  Box
} from "@mineral/core";
import {
  Button
 } from "@mineral/core";

import AccessibleTooltip from './../common/AccessibleTooltip'
export default class NameColumnCellRendererInterfacesTable extends Component {
 

  handleTableClick() {
    this.props.context.handleDrillDown(this.props);
  }


  render() {
    let valueProp = this.props.data.displayName;
    let view = (
      <div style={{ display: "flex" }} tabIndex={-1}>
       

        <Button role="link" disableRipple={true} style={{display:'flex',justifyContent:'flex-start'}}
                className="mcsdashboard-transparent-btn"
                onClick={this.handleTableClick.bind(this)}
                aria-roledescription={"Click to view details"}
                >
                {/*<span style={{color:"#3272D9"}}> {this.props.data && this.props.data.name?this.props.data.name:""}</span>*/}
                <AccessibleTooltip title={valueProp?valueProp:""}>
      <Box component={'span'} style={{ overflow: 'hidden',color:"#3272D9", whiteSpace: 'nowrap', textOverflow: 'ellipsis' ,textTransform:'none'}}>
     { valueProp!==null && valueProp!==undefined?valueProp:''}
      </Box>
    </AccessibleTooltip>
                </Button>
        </div>
       )

    return view;
  }
}



