import React , { lazy, Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import TitleBar from "./components/titlebar/TitleBarRedux";
import TrialBarRedux from "./components/titlebar/trialbar/TrialBarRedux";
import Navigation from "./components/primaryNav/PrimaryNavRedux";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import SessionExpiry from "./components/pageNotFound/SessionExpiry";
import ConfigApi from "./api/config/ConfigApi";
import Overview from "./routes/Overview";
import { LocalStorageManager as storageManagerApi } from "./utils/LocalStorageManager";
import SupportedBrowser from "./components/supportedBrowser/SupportedBrowser";
import Resource from "./Resource";
import MessageFormat from "message-format";
import QosChartUrl from "./components/settings/portlets/performanceReports/PerformanceLayout/qosData/qos-chart-container/QosChartUrl"
import ListDashboardDesigner from "./components/settings/portlets/ListDashboardDesigner/ListDashboardDesigner";
import { CircularProgress } from '@mineral/core';

const Groups = lazy(() => import('./routes/Groups'));
const Inventory = lazy(() => import('./routes/Inventory'));
const UimAlarms = lazy(() => import('./routes/UimAlarms'));
const UimCabi = lazy(() => import('./routes/UimCabi'));
const AdminConsole = lazy(() => import('./routes/AdminConsole'));
const Configuration = lazy(() => import('./routes/Configuration'));
const ManageGroups = lazy(() => import('./routes/ManageGroups'));
const RobotDeploy = lazy(() => import('./routes/RobotDeploy'));
const Wizard = lazy(() => import('./routes/Wizard'));
const DeviceDiscovery = lazy(() => import('./routes/DeviceDiscovery'));
const SetupWizard = lazy(() => import('./routes/SetupWizard'));
const DashboardPicker = lazy(() => import('./routes/Dashboard'));
const Reports = lazy(() => import('./routes/Reports'));
const Settings = lazy(() => import('./routes/Settings'));
const AlarmPolicies = lazy(() => import('./routes/AlarmPolicies'));
const Unauthorized = lazy(() => import('./routes/Unauthorized'));
const Inaccessible = lazy(() => import('./routes/Inaccessible'));
const Footer = lazy(() => import ('./components/footer/FooterBar'));
const ModalManagerRedux = lazy(() => import('./components/modals/ModalManagerRedux'));
const Slm = lazy(() => import('./routes/Slm'));

let fallbackSpinner = (
  <div style={{
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}>
    <CircularProgress style={{color: '#3272D9'}} />
  </div>
);

//Infrastructure Monitoring
const infrastructureMonitoring = Resource.get('Infrastructure Monitoring');
//Overview
const overview = Resource.get('Overview');
//Alarms
const alarms = Resource.get('Alarms');
//Groups
const groups = Resource.get('Groups');
//Inventory
const inventory = Resource.get('Inventory');
//Device Details
const deviceDetails = Resource.get('Device Details');
//Service Level Management
const slm = Resource.get('Service Level Management');
//App Discovery
const appDiscovery = Resource.get('App Discovery');
//Personalize
const personalize = Resource.get('Personalize');
//Groups Maintenance Schedules
const groupsMaintenanceSchedules = Resource.get('Groups Maintenance Schedules');
//Settings
const settings = Resource.get('Settings');
// Report scheduler
const REPORT_SCHEDULER = Resource.get('Report Scheduler')
// Dashboards
const DASHBOARDS = Resource.get("Dashboards")

const App = (props) => {
  let saas = props.saas;
 /* const [titlelist, setTitleList] = useState(
    [ {path:"overview", title: overview},
      {path:"uim-alarms", title:alarms},
      {path:"groups", title: groups},
      {path:"inventory", title: inventory},
      {path:"dashboard", title:deviceDetails},
      {path:"alarms", title:alarms},
      {path:"slm", title:slm},
      {path:"administration", title:appDiscovery},
      {path:"personalize", title:personalize},
      {path:"treeviewnew", title:groupsMaintenanceSchedules},
      {path:"reportscheduler", title:REPORT_SCHEDULER},
    ]);
    //const [title, setTitle] = useState("");*/

  const onHomeComponentChanged=(data)=>{
  }
  const referrer = storageManagerApi.getItem(
    "OPERATOR_CONSOLE",
    "referrer"
  );
  let landingPage = storageManagerApi.getItem(
    "OPERATOR_CONSOLE",
    "landingPage"
  );
  if (referrer) {
    landingPage=referrer;
  }
  /*useEffect(() => {
    titlelist.map((list) => {
      if(window.location.pathname.includes(list.path))
      setTitle(infrastructureMonitoring +" - "+ list.title)
    })
    if(title !== ""){
      document.title = title
    }
    else{
      document.title = infrastructureMonitoring
    }
    
  })*/
  let component = (
    <div className="react__root operator-console__application">
      <SupportedBrowser />
      <TrialBarRedux />

      <div style={{ flexGrow: "2" }}>
        <ConfigApi />
        <Route
          exact
          path={`${"/(standalone.jsp|standalone_login.jsp|axalogin)*"}`}
          render={(props) => {
            const referrer = storageManagerApi.getItem(
              "OPERATOR_CONSOLE",
              "referrer"
            );
            const landingPage = storageManagerApi.getItem(
              "OPERATOR_CONSOLE",
              "landingPage"
            );
            if (referrer) {
              storageManagerApi.removeItem("OPERATOR_CONSOLE", "referrer");
              return <Redirect to={referrer} />;
            }
            //below code will effect the loading page on login.
            // else if (
            //   storageManager.getItem(
            //     'OPERATOR_CONSOLE',
            //     'showSetupWizardAgain'
            //   ) !== 'false' && saas ) {
            //   return <Redirect to="/getting-started" />
            // }
            else if (
              props.location.pathname.endsWith("standalone.jsp") ||
              props.location.pathname.endsWith("standalone_login.jsp") ||
              props.location.pathname.endsWith("axalogin")
            ) {
              if(landingPage && landingPage!="")
              return <Redirect to={landingPage}/>
              else
              {
               return <Redirect to="/noaccess"/>
              }
              
            }
            if(landingPage && landingPage!="")
              return <Redirect to={landingPage}/>
              else
              {
                return <Redirect to="/noaccess"/>
              }
          }}
        />
        <TitleBar/>
        <Navigation landingPage={landingPage} />
      <Overview />
      <Suspense fallback={fallbackSpinner}>
        <Groups />
        <Inventory />
        <UimAlarms />
       
        <UimCabi />
        <AdminConsole />
        <Configuration />
        <ManageGroups />
        <RobotDeploy />
        <Wizard />
        <DeviceDiscovery />
        <SetupWizard />
        
       <DashboardPicker saas={saas} />
        <Reports />
        <Settings />
        <AlarmPolicies />
        
        <Unauthorized />
        <Inaccessible/>
        <Footer />
        <ModalManagerRedux />
       <Slm />
       </Suspense> 
      </div>
    </div>
  );

  if (window.location.href.includes('prdChart')) {
    component = (<div className="react__root operator-console__application prdChartUrlContainer">
      <SupportedBrowser />
      <TrialBarRedux />
      <Route path='/prdChart' component={QosChartUrl} />
    </div>)
  };

  if (window.location.href.includes('clonedListview')) {
    component = (
      <div>
        <SupportedBrowser />
        <TrialBarRedux />
        <Route
          path='/clonedListview'
          render={() => {
            return <ListDashboardDesigner copyURL={true} />;
          }}
        />
      </div>
    )
  };

  const hostIp = window.location;
  let message;

  if(props.isTimedout) {
    message = Resource.get("Your current session is expired. Click on the below link to re-login"); // expired msg
  } else if(!props.heartbeat) {
    message = Resource.get("Unable to connect to Operator Console. Restore the connection to {0} and verify that wasp is running. Attempting to reestablish connection...");  // help msg
  }

  let result = new MessageFormat(message).format({ hostIp });

  if (props.isTimedout) {
    component = (
      <section>
        <SessionExpiry
          //Session Expired
          title={Resource.get("Session Expired")}
          message={result}
        />
      </section>
    );
  } else if (!props.heartbeat) {
    component = (
      <section>
        <PageNotFound
          //Unable to connect to server
          title={Resource.get("Unable to connect to server")}
          message={result}
          returnLink={null}
        />
      </section>
    );
  }
  return component;
};

export default App;
