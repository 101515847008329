import {
  call,
  put,
  takeEvery,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import {
  FETCH_PROBE_METRICS_DEVICES_CHART,
  POLL_METRIC_DEVICE_CHART_START,
  POLL_METRIC_DEVICE_CHART_STOP,

} from './actionTypes'
import {
  fetchMetricDeviceChartSuccess,
  fetchMetricDeviceChartFailure,

} from './actions'
import { DashboardMetric } from './dashboardMetric';

//------------------------------------ Metric Chart Polling Functions----------------------------
function* fetchMetricDeviceChartPoll(params) {

  try {
    const response = yield call(DashboardMetric.getAllMetricDevicesChart, params)
    yield put(fetchMetricDeviceChartSuccess(response.data))
  } catch (error) {
    yield put(fetchMetricDeviceChartFailure(error))
  }
}

function* pollMetricDeviceChart(action) {
  while (true) {
    yield call(fetchMetricDeviceChartPoll, {
      deviceIds: action.deviceIds,
      metric: action.metric,
      hours: action.hours,
      selectedCIs: action.selectedCIs,
      isInterface: action.isInterface,
      endDate: action.endDate
    })
    yield delay(30000)

  }
}

export function* metricDeviceChartRequestSaga() {
  yield takeEvery(FETCH_PROBE_METRICS_DEVICES_CHART, fetchMetricDeviceChartPoll)
}

export function* metricDeviceChartPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_METRIC_DEVICE_CHART_START, pollMetricDeviceChart)
    yield take(POLL_METRIC_DEVICE_CHART_STOP)
    yield cancel(watcherInstance)
  }
}