import React from "react";
import * as _ from "lodash";
import isEqual from "lodash/isEqual";
import InterfaceTable from "./InterfaceTable";

const isArrayEqual = function (x, y) {
  return _(x).differenceWith(y, isEqual).isEmpty();
};

function areEqual(prevProps, nextProps) {
  if (
    !isArrayEqual(prevProps.interfaceData, nextProps.interfaceData) ||
    !isEqual(prevProps.fromTreeView, nextProps.fromTreeView) ||
    !isEqual(prevProps.fromGroupsView, nextProps.fromGroupsView) ||
    !isEqual(prevProps.openAlarmViewForInterface, nextProps.openAlarmViewForInterface) ||
    !isArrayEqual(prevProps.groupData, nextProps.groupData) ||
    !isEqual(prevProps.isTreeOpen, nextProps.isTreeOpen) ||
    !isEqual(prevProps.openDetailedViewInterface, nextProps.openDetailedViewInterface) ||
    !isEqual(prevProps.openDetailInterfaceFromTreeView, nextProps.openDetailInterfaceFromTreeView) ||
    !isEqual(prevProps.showInterfaceDetail, nextProps.showInterfaceDetail)
  ) {
    return false;
  }
  return true;
}

const InterfaceTableMemoized = React.memo((props) => {
  return (
    <InterfaceTable
      interfaceData={props.interfaceData}
      fromTreeView={props.fromTreeView}
      fromGroupsView={props.groupId !== null ? props.fromGroupsView : false}
      openAlarmViewForInterface={props.openAlarmViewForInterface}
      groupData={props.groupData}
      isTreeOpen={props.openTree}
      openDetailedViewInterface={props.openDetailedViewInterface}
      openDetailInterfaceFromTreeView={props.openDetailInterfaceFromTreeView}
      showInterfaceDetail={props.showInterfaceDetail}
      {...props}
    />
  );
}, areEqual);

export default InterfaceTableMemoized;
