import {
  FETCH_API_COMPONENT_INFO,
  FETCH_API_COMPONENT_INFO_SUCCESS,
  FETCH_API_COMPONENT_INFO_FAILURE,
  SET_SHOWING_COMPONENT_INFO
} from './actionTypes'
import { Info } from './info';

export function setShowingComponentInfo(showingComponentInfo) {
  return (dispatch, getState) => {
    dispatch(
        {
          type:SET_SHOWING_COMPONENT_INFO,
          isShowingComponentInfo:showingComponentInfo,
        }
    );
  }
}

export function fetchApiComponentInfo() {
  return {
    type: FETCH_API_COMPONENT_INFO
  }
}
export function fetchApiComponentInfoSuccess(json) {
  return {
    type: FETCH_API_COMPONENT_INFO_SUCCESS,
    info: json
  }
}
export function fetchApiComponentInfoFailure(error) {
  return {
    type: FETCH_API_COMPONENT_INFO_FAILURE,
    errorMessage: error.message,
    error: error.response,
  }
}
export function getApiComponentInfo() {
  return dispatch => {
    dispatch(fetchApiComponentInfo())
    return Info.get()
               .then(response => response.data)
               .then(json => dispatch(fetchApiComponentInfoSuccess(json)))
               .catch(function(error) {
                 dispatch(fetchApiComponentInfoFailure(error))
               })
  }
}
