import {
  REQUEST_CONFIGURATION,
  FETCH_CONFIGURATION_SUCCESS,
  FETCH_CONFIGURATION_FAILURE,
} from './actionTypes'
import { Configuration } from './../../api/configuration/configuration'

export function requestConfiguration() {
  return {
    type: REQUEST_CONFIGURATION,
  }
}

export function fetchConfigurationSuccess(json) {
  return {
    type: FETCH_CONFIGURATION_SUCCESS,
    configuration: json,
  }
}

export function fetchConfigurationFailure(error) {
  return {
    type: FETCH_CONFIGURATION_FAILURE,
    errorMessage: error.message,
  }
}

export function fetchConfiguration() {
  return dispatch => {
    dispatch(requestConfiguration())
    let promise = Configuration.get()
    promise
      .then(response => response.data)
      .then(json => dispatch(fetchConfigurationSuccess(json)))
      .catch(function(error) {
        dispatch(fetchConfigurationFailure(error))
      })
    return promise
  }
}
