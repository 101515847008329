import {
	GET_WMI_CREDENTIALS,
	FETCH_WMI_CREDENTIALS_SUCCESS,
	FETCH_WMI_CREDENTIALS_FAILURE,
	POLL_WMI_START,
	POLL_WMI_STOP,
	DELETE_WMI_CRED,
	UPDATE_WMI_CRED,
	ADDED_WMI_CRED,
	ADDED_SNMP_CRED,
	GET_SNMP_CREDENTIALS,
	FETCH_SNMP_CREDENTIALS_FAILURE,
	FETCH_SNMP_CREDENTIALS_SUCCESS,
	POLL_SNMP_START,
	POLL_SNMP_STOP,
	DELETE_SNMP_CRED,
	UPDATE_SNMP_CRED
} from './actionTypes'

//Get WMI Credentials
export function getWMI() {
	  return {
	    type: GET_WMI_CREDENTIALS,
	  }
	}

export function fetchWMISuccess(json) {
	  return {
	    type: FETCH_WMI_CREDENTIALS_SUCCESS,
	    credentials: json
	  }
}

 export function fetchWMIFailure(error) {
	  return {
	    type: FETCH_WMI_CREDENTIALS_FAILURE,
	    errorMessage: error.message
	  }
}
 
 export function pollWmiCredStart() {
	  return {
	    type:POLL_WMI_START
	  }
	}

	export function pollWmiCredStop() {
	  return {
	    type:POLL_WMI_STOP
	  }
	}
	//get snmp 
	export function getSNMP() {
		return {
		  type: GET_SNMP_CREDENTIALS,
		}
	  }
  
  export function fetchsnmpSuccess(json) {
		return {
		  type: FETCH_SNMP_CREDENTIALS_SUCCESS,
		  credentials: json
		}
  }
  
   export function fetchsnmpFailure(error) {
		return {
		  type: FETCH_SNMP_CREDENTIALS_FAILURE,
		  errorMessage: error.message
		}
  }
   
   export function pollsnmpCredStart() {
		return {
		  type:POLL_SNMP_START
		}
	  }
  
	  export function pollsnmpCredStop() {
		return {
		  type:POLL_SNMP_STOP
		}
	  }
	//end
	export function deleteWmiCred(deletedItem) {
		  return {
		    type:DELETE_WMI_CRED,
		    deletedCredential: deletedItem
		  }
		}
	export function updateWmiCred(updatedItem) {
		  return {
		    type:UPDATE_WMI_CRED,
		    updatedCredential: updatedItem
		  }
		}
	
	export function addWmiCred(addedItem) {
		  return {
		    type:ADDED_WMI_CRED,
		    addedCredential: addedItem
		  }
		}
		export function addSNMP(addedItem) {
			return {
			  type:ADDED_SNMP_CRED,
			  addedCredentialSNMP: addedItem
			}
		  }
		  export function deleteSNMPCred(deletedItem) {
			return {
			  type:DELETE_SNMP_CRED,
			  deletedCredentialSNMP: deletedItem
			}
		  }
		  export function updateSNMPCred(updatedItem) {
			return {
			  type:UPDATE_SNMP_CRED,
			  updatedCredential: updatedItem
			}
		  }
		
		  