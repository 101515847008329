import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 0C21.1046 0 22 0.89543 22 2V16C22 17.1046 21.1046 18 20 18H3.346L3.28921 18.0574L3.232 18H2C0.945638 18 0.0818349 17.1841 0.00548577 16.1493L0 16V2C0 0.89543 0.89543 0 2 0H20ZM16.595 2H2V16H2.517L7.009 11.509L5.5 10H10V14.5L8.423 12.923L5.346 16H20V2H19.423L14.962 6.462L16.5 8H12V3.5L13.548 5.048L16.595 2Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Icon;
