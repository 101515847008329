import axios from "axios";
import config from "./../config";

export class TreeView {
  static getAllGroups(id) {
    var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/CHrXQ3/groups" :
      [config.basename, "api/v2/groups/stack", id].join("/");

    return axios.get(url);
  };

  static getAlarmState(id) {
    var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/children" :
      [config.basename, "api/v2/groups/stack", id, "child-details"].join("/");
    return axios.get(url);
  };

  static getGroupById(id) {
    var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/groups/id" :
      [config.basename, "api/v2/groups", id].join("/");
    return axios.get(url);
  };

  static getDeviceData(id) {
    var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/devices" :
      [config.basename, `api/v1/computer-systems?groupId=${id}`].join("/");

    return axios.get(url);
  };

  static getInterfaceData(id) {
    return axios.get(
      [config.basename, `api/v1/interface?groupId=${id}`].join("/")
    );
  };

  static getChildDetails(id) {
    var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/children" :
      [config.basename, `api/v2/groups/${id}/child-details`].join("/");

    return axios.get(url);
  };

  static getCountDetails(id) {
    var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/CHrXQ3/count" :
      [config.basename, `api/v2/groups/stack/${id}/group-child-count`].join("/");

    return axios.get(url);
  };
};
