import {
  REQUEST_COMPUTER_SYSTEM,
  REQUEST_COMPUTER_SYSTEMS,
  FETCH_COMPUTER_SYSTEM_SUCCESS,
  FETCH_COMPUTER_SYSTEMS_SUCCESS,
  FETCH_COMPUTER_SYSTEM_FAILURE,
  FETCH_COMPUTER_SYSTEMS_FAILURE,
  DELETE_DEVICES_SUCCESS,
  DELETE_DEVICES_FAILURE,
  DELETE_DEVICES_FROM_INVENTORY_SUCCESS,
  DELETE_DEVICES_FROM_INVENTORY_FAILURE,
  FETCH_INVENTORY_TREE_SUCCESS,
  FETCH_INVENTORY_TREE_FAILURE,
  FILTERED_INVENTORY_ROWS,
  SET_COMPUTER_SYSTEMS_TOTAL_ITEMS,
  SET_COMPUTER_SYSTEMS_SORT,
  SET_COMPUTER_SYSTEMS_FILTER,
  SET_COMPUTER_SYSTEMS_COLUMN_FILTER,
  SET_COMPUTER_SYSTEMS_QUICK_FILTER,
  SET_COMPUTER_SYSTEMS_REFRESH_GRID_DATA,
  SET_COMPUTER_SYSTEMS_TREE,
  FETCH_LIST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  UPDATE_COMPUTER_SYSTEM,
} from "./actionTypes";
import { ComputerSystems as computerSystemsApi } from "./computerSystems";
import { setEntities } from "../../components/entities/actions";
import get from "lodash/get";
import entityTypes from "./../../api/entityTypes";

export function updateComputerSystem(payload) {
  return {
    type: UPDATE_COMPUTER_SYSTEM,
    refreshGridData: payload,
  };
}

export function requestComputerSystem() {
  return {
    type: REQUEST_COMPUTER_SYSTEM,
  };
}

export function requestComputerSystems() {
  return {
    type: REQUEST_COMPUTER_SYSTEMS,
  };
}

export function fetchComputerSystemSuccess(json) {
  return {
    type: FETCH_COMPUTER_SYSTEM_SUCCESS,
    data: json,
  };
}

export function fetchComputerSystemsSuccess(json) {
  return {
    type: FETCH_COMPUTER_SYSTEMS_SUCCESS,
    data: json,
  };
}

export function fetchComputerSystemsFailure(error) {
  return {
    type: FETCH_COMPUTER_SYSTEMS_FAILURE,
    errorMessage: error.message,
  };
}

export function fetchComputerSystemFailure(error) {
  return {
    type: FETCH_COMPUTER_SYSTEM_FAILURE,
    errorMessage: error.message,
  };
}

// adding this to not conflict with the current fetchComputerSystem method
export function searchComputerSystems(
  nextPage,
  sortConfig,
  filterConfig,
  inventoryTreeConfig,
  newColumnFilterConfig
) {
  return (dispatch, getState) => {
    let isRefresh = !nextPage || nextPage === 0 ? true : false;
    dispatch(updateComputerSystem(isRefresh));
    if (filterConfig) {
      dispatch(setColumnFilter(filterConfig));
    }
    if (sortConfig) {
      dispatch(setSort(sortConfig));
    }
    if (inventoryTreeConfig) {
      dispatch(setnventiroyTreeConfig(inventoryTreeConfig));
    } else {
      var params = { daId: null, scopeId: null };
      dispatch(setnventiroyTreeConfig(params));
    }
    if (newColumnFilterConfig) {
      dispatch(setFilter(newColumnFilterConfig));
    }
    const quickFilter = getState().inventoryEntityFilter.quickFilter;

    if (quickFilter !== getState().computerSystems.config.quickFilter) {
      dispatch(setQuickFilter(quickFilter));
    }

    const payload = {
      ...getState().computerSystems.config,
      pageNumber: nextPage ? nextPage : 0,
    };
    if (!payload.sortOrder) {
      payload.sortColumn = "Discovered";
      payload.sortOrder = "DESC";
    }
    return computerSystemsApi
      .search(payload)
      .then((json) => {
        dispatch(fetchComputerSystemsSuccess(json?.data));
        if (!nextPage || nextPage === 0) {
          const items = get(json?.data, "_items", []).map((item) => {
            return Object.assign({}, item, {
              entityType: entityTypes.COMPUTER_SYSTEM,
            });
          });
          dispatch(setEntities(items));
        }
        return {
          data: json?.data,
          total: json?.data?.total,
        };
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchComputerSystemFailure(error));
      });
  };
}

export function fetchAllComputerSystems(totalItems) {
  return (dispatch, getState) => {
    return computerSystemsApi
      .search({
        ...getState().computerSystems.config,
        pageNumber: 0,
        pageSize: getState().computerSystems.totalItems,
      })
      .then((response) => response.data)
      .then((json) => {
        return {
          data: json,
          total: json.total,
        };
      });
  };
}

export function fetchComputerSystem(params) {
  return (dispatch) => {
    dispatch(requestComputerSystem());
    return computerSystemsApi
      .get(params)
      .then((response) => response.data)
      .then((json) => dispatch(fetchComputerSystemSuccess(json)))
      .catch((error) => {
        dispatch(fetchComputerSystemFailure(error));
      });
  };
}

export function fetchComputerSystems(params) {
  return (dispatch) => {
    dispatch(requestComputerSystems());
    return computerSystemsApi
      .get(params)
      .then((response) => response.data)
      .then((json) => dispatch(fetchComputerSystemsSuccess(json)))
      .catch(function (error) {
        dispatch(fetchComputerSystemsFailure(error));
      });
  };
}

export function fetchComputerSystemsForAdd() {
  return (dispatch) => {
    dispatch(requestComputerSystems());
    return computerSystemsApi
      .get()
      .then((response) => response.data)
      .then((json) => dispatch(fetchComputerSystemsSuccess(json)))
      .catch(function (error) {
        dispatch(fetchComputerSystemsFailure(error));
      });
  };
}

export function pollFetchComputerSystems(groupId) {
  if (!groupId) {
    throw new Error(
      "Computer Systems actions - a groupId must be provided. groupId:",
      groupId
    );
  }
  return (dispatch) => {
    return computerSystemsApi
      .get({ groupId: groupId })
      .then((response) => response.data)
      .then((json) => dispatch(fetchComputerSystemsSuccess(json)))
      .catch(function (error) {
        dispatch(fetchComputerSystemsFailure(error));
      });
  };
}

export function exportComputerSystem() {
  return (dispatch, getState) => {
    return computerSystemsApi
      .export({
        ...getState().computerSystems.config,
        pageNumber: null,
        pageSize: null,
      })
      .then((response) => response.data);
  };
}

export function deleteDevices(ids) {
  return (dispatch) => {
    return computerSystemsApi
      .delete(ids)
      .then((response) => response.data)
      .then((json) => dispatch(deleteDevicesSuccess()))
      .catch((error) => dispatch(deleteDevicesFailure(error)));
  };
}
export function deleteDevicesSuccess() {
  return {
    type: DELETE_DEVICES_SUCCESS,
  };
}
export function deleteDevicesFailure(error) {
  return {
    type: DELETE_DEVICES_FAILURE,
    errorMessage: error.message,
  };
}
export function deleteDevicesFromInventorySuccess() {
  return {
    type: DELETE_DEVICES_FROM_INVENTORY_SUCCESS,
  };
}
export function deleteDevicesFromInventoryFailure(error) {
  return {
    type: DELETE_DEVICES_FROM_INVENTORY_FAILURE,
    errorMessage: error.message,
  };
}
export function deleteDevicesFromInventory(ids, options) {
  return (dispatch) => {
    return computerSystemsApi
      .deleteFromInventory(ids, options)
      .then((response) => response.data)
      .then((json) => dispatch(deleteDevicesFromInventorySuccess()))
      .catch((error) => dispatch(deleteDevicesFromInventoryFailure(error)));
  };
}

export function getInventory() {
  return (dispatch) => {
    dispatch(requestComputerSystem());
    return computerSystemsApi
      .getInventory()
      .then((response) => response.data)
      .then((json) => dispatch(fetchInventorySuccess(json)))
      .catch((error) => {
        dispatch(fetchInventoryFailure(error));
      });
  };
}
export function fetchInventorySuccess(json) {
  return {
    type: FETCH_INVENTORY_TREE_SUCCESS,
    data: json,
  };
}

export function fetchInventoryFailure(error) {
  return {
    type: FETCH_INVENTORY_TREE_FAILURE,
    errorMessage: error.message,
  };
}

export function setInvetoryFilteredData(items) {
  return {
    type: FILTERED_INVENTORY_ROWS,
    items: items,
  };
}

export function setTotalItems(count) {
  return {
    type: SET_COMPUTER_SYSTEMS_TOTAL_ITEMS,
    totalItems: count,
  };
}

export function setFilter(filters) {
  return {
    type: SET_COMPUTER_SYSTEMS_FILTER,
    filters: filters,
  };
}

export function setColumnFilter(filters) {
  return {
    type: SET_COMPUTER_SYSTEMS_COLUMN_FILTER,
    colFilters: filters,
  };
}

export function setSort(sort) {
  return {
    type: SET_COMPUTER_SYSTEMS_SORT,
    sort: sort,
  };
}

export function setQuickFilter(filter) {
  return {
    type: SET_COMPUTER_SYSTEMS_QUICK_FILTER,
    filter: filter,
  };
}

export function setRefreshGridUpdate(isUpdate) {
  return {
    type: SET_COMPUTER_SYSTEMS_REFRESH_GRID_DATA,
    refreshGridData: isUpdate,
  };
}
export function setnventiroyTreeConfig(config) {
  return {
    type: SET_COMPUTER_SYSTEMS_TREE,
    config: config,
  };
}

export function fetchLookupList(lookupType) {
  return {
    type: FETCH_LIST,
  };
}

export function fetchLookupListSuccess(json) {
  return {
    type: FETCH_LIST_SUCCESS,
    data: json,
  };
}

export function fetchLookupListFailure(error) {
  return {
    type: FETCH_LIST_FAILURE,
    errorMessage: error.message,
  };
}

export function getLookupList(lookupType) {
  return (dispatch) => {
    dispatch(fetchLookupList(lookupType));
    return computerSystemsApi
      .getLookupList(lookupType)
      .then((response) => response.data)
      .then((json) => dispatch(fetchLookupListSuccess(json)))
      .catch((error) => {
        dispatch(fetchLookupListFailure(error));
      });
  };
}
