import axios from 'axios';
import config from './../../config';

export class UnixCredentials {
  static getWMI() {
    if (process.env.NODE_ENV === "development")
      return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/wmi');
    return axios.get([config.basename, 'api/v1/credentials/wmi'].join('/'));
  };

  static getUNIX() {
    if (process.env.NODE_ENV == "development")
      return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/shell');
    return axios.get([config.basename, 'api/v1/credentials/shell'].join('/'));
  };

  static saveWMI(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  };

  static editWMI(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  };

  static saveUNIX(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  };

  static editUNIX(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  };
};
