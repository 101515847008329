import {
	GET_SCOPES,
	FETCH_SCOPES_SUCCESS,
	FETCH_SCOPES_FAILURE,
	ADDED_SCOPES,
    DELETE_SCOPES,
	UPDATE_SCOPES,
} from './actionTypes'
import isEqual from 'lodash/isEqual'


const scopes = (
  state = {
    didInvalidate: false,
    isFetching: false,
    items: [],
  },
  action,
) => {
  switch (action.type) {
    case GET_SCOPES:
      return Object.assign({}, state, {
    	    isFetching: true,
      	    didInvalidate: false,
     })
    case FETCH_SCOPES_SUCCESS:
        const items = action.scopes
        const updatedState = Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          items: items
        })
        return isEqual(updatedState, state) ? state : updatedState
      case FETCH_SCOPES_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          didInvalidate: true,
          errorMessage: action.errorMessage,
          items: []
        })
     case DELETE_SCOPES:
    	const deletedCred = action.deletedScopes
    	let filteredList=[];
            filteredList=state.items.filter(item => {
                return item.id !== deletedCred
            })
        const updatedItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          items: filteredList
        })
        return isEqual(updatedItem, state) ? state : updatedItem
    case UPDATE_SCOPES:
	const updatedCred = action.updatedScopes
    	let updatedCatalog = state.items
    	for (let i = 0; i < updatedCatalog.length ; i++){
            if(updatedCred.id === updatedCatalog[i].id){
                updatedCatalog[i]=updatedCred;
                break;
            }
        }
        const updatedCatalogItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          items: updatedCatalog
        })
        return isEqual(updatedCatalogItem, state) ? state : updatedCatalogItem
    case ADDED_SCOPES:
	const addedCred = action.addedScopes
    	let addedCatalog = state.items
    	let isNewCredential=true
        for (let i = 0; i < addedCatalog.length ; i++){
            if(addedCred.id === addedCatalog[i].id){
               	isNewCredential=false
				break;
            }
        }
        if(isNewCredential){
        	addedCatalog.push(addedCred);
		}
        const addCatalogItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          items: addedCatalog
        })
        return isEqual(addCatalogItem, state) ? state : addCatalogItem
     default:
      return state
  }
}

export default scopes
