import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.91861 12.4186L10.5 14H6V9.5L7.5044 11.0044L13.0044 5.5044L11.5 4H16V8.5L14.4186 6.91861L8.91861 12.4186ZM2 0H20C21.1046 0 22 0.89543 22 2V16C22 17.1046 21.1046 18 20 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0ZM2 2V16H20V2H2Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Icon;
