// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Infrastructure Monitoring
  content["Infrastructure Monitoring"] =
  "Infrastructure Monitoring";

  //Overview
  content["Overview"] =
  "Overview";

  //Alarms
  content["Alarms"] =
  "Alarms";

  //Groups
  content["Groups"] =
  "Groups";

  //Inventory
  content["Inventory"] =
  "Inventory";

  //Device Details
  content["Device Details"] =
  "Device Details";

  //Service Level Management
  content["Service Level Management"] =
  "Service Level Management";

  //App Discovery
  content["App Discovery"] =
  "App Discovery";

  //Personalize
  content["Personalize"] =
  "Personalize";

  //Groups Maintenance Schedules
  content["Groups Maintenance Schedules"] =
  "Groups Maintenance Schedules";

  //Settings
  content["Settings"] =
  "Settings";

  // Report scheduler
  content["Report Scheduler"] =
  "Report Scheduler";

  // Dashboards
  content["Dashboards"] =
  "Dashboards";

  // expired msg
  content["Your current session is expired. Click on the below link to re-login"] =
  "Your current session is expired. Click on the below link to re-login";

  // help msg
  content["Unable to connect to Operator Console. Restore the connection to {0} and verify that wasp is running. Attempting to reestablish connection..."] =
  "Unable to connect to Operator Console. Restore the connection to {0} and verify that wasp is running. Attempting to reestablish connection...";

  //Session Expired
  content["Session Expired"] =
  "Session Expired";

  //Unable to connect to server
  content["Unable to connect to server"] =
  "Unable to connect to server";

  // END OF LOCALIZATION

export default content;
