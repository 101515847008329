import axios from 'axios';
import config from './../../config';

export class Credentials {
  static getWMI() {

    if (process.env.NODE_ENV == "development")
      return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/wmi');

    return axios.get([config.basename, 'api/v1/credentials/wmi'].join('/'));
  };

  static getSNMP() {
    if (process.env.NODE_ENV == "development")
      return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/snmp');
    return axios.get([config.basename, 'api/v1/credentials/snmp'].join('/'));
  };

  static getUNIX() {
    if (process.env.NODE_ENV == "development")
      return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/shell');

    return axios.get([config.basename, 'api/v1/credentials/shell'].join('/'));
  };

  static saveWMI(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  };

  static saveSNMP(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/snmp'].join('/'), credentialObj);
  };

  static editWMI(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  };

  static editSNMP(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/snmp'].join('/'), credentialObj);
  };

  static saveUNIX(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  };

  static editUNIX(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  };

  static deleteWMI(id) {
    return axios.delete([config.basename, 'api/v1/credentials/wmi/' + id].join('/'));
  };

  static deleteSNMP(id) {
    return axios.delete([config.basename, 'api/v1/credentials/snmp/' + id].join('/'));
  };

  static deleteUNIX(id) {
    return axios.delete([config.basename, 'api/v1/credentials/shell/' + id].join('/'));
  };

  static deleteCredential(id, type) {
    if (type === 'WMI') {
      return this.deleteWMI(id)
    } else if (type === 'UNIX') {
      return this.deleteUNIX(id)
    } else if (type === 'SNMP') {
      return this.deleteSNMP(id)
    }
  };
};
