import {
  FETCH_OCVIEW,
  FETCH_OCVIEW_SUCCESS,
  FETCH_OCVIEW_FAILURE,
  
} from "./actionTypes";
import fetchStatusTypes from "./../fetchStatusTypes";

const OCVIEW = (
  state = {
    isFetching: false,
    fetchStatus: fetchStatusTypes.UNDEFINED,
    didInvalidate: false,
   
    canOcviewOverview: true,
    canOcviewAlarms: true,
    canOcviewInventory: true,
    canOcviewReports: true,
    canOcviewDashboards: true,
    canOcviewSLA: true,
    canOcviewSLM: true,
    canOcviewGroups: true,
    unAccessibleList:[],
    },
  action
) => {
  switch (action.type) {
   
    case FETCH_OCVIEW:
      return Object.assign({}, state, {
        isFetching: true,
        fetchStatus: fetchStatusTypes.FETCHING,
        didInvalidate: false,
        ocviewInfo: {},
        unAccessibleList:[],
      });
    case FETCH_OCVIEW_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        didInvalidate: false,
        
        canOcviewOverview: !action.ocviewinfo.includes("Home"),
        canOcviewAlarms: !action.ocviewinfo.includes("Alarms"),
        canOcviewInventory: !action.ocviewinfo.includes("Inventory"),
        canOcviewReports: !action.ocviewinfo.includes("Reports"),
        canOcviewDashboards: !action.ocviewinfo.includes("Dashboard"),
        canOcviewSLA: !action.ocviewinfo.includes("SLA"),
        canOcviewSLM: !(action.ocviewinfo.includes("SLA")),
        canOcviewGroups: !(action.ocviewinfo.includes("Groups")),
       // canOcviewGroups: !(action.ocviewinfo.includes("Tree") && action.ocviewinfo.includes("Badge") && action.ocviewinfo.includes("List")),
        /*canOcviewGroupsTree: !action.ocviewinfo.includes("Tree"),
        canOcviewGroupsBadge: !action.ocviewinfo.includes("Badge"),
        canOcviewGroupsList: !action.ocviewinfo.includes("List"),   */
        unAccessibleList:action.ocviewinfo ,    
        
      });
    case FETCH_OCVIEW_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.FAIL,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        ocviewinfo: {},
        unAccessibleList:[],
      });          
    default:
      return state;
  }
};
export default OCVIEW;
