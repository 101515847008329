class _EventBus {
  constructor() {
    this.bus = {};
  }

  $off(id) {
    delete this.bus[id];
  };

  $on(id, callback) {
    if (!this.bus[id]) {
      this.bus[id] = [];
    }
    this.bus[id].push(callback);
  };

  $emit(id, ...params) {
    if (this.bus[id])
      this.bus[id].forEach((callback) => {
        callback(...params);
      });
  };
};

export const EventBus = new _EventBus();