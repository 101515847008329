import { call, put, take, takeEvery, takeLatest, cancel } from "redux-saga/effects";
import { delay } from "redux-saga/effects";
import {
  POLL_GROUPS_START,
  POLL_GROUPS_STOP,
  REQUEST_GROUP,
  FETCH_TOP_SUB_GROUP,
  POLL_TOP_SUB_GROUP_START,
  POLL_TOP_SUB_GROUP_STOP,
} from "./actionTypes";
import entityTypes from "./../entityTypes";
import { Group } from "./groups";
import { fetchGroupsSuccess, fetchGroupsFailure ,fetchTopSubGroupsFailure,
  fetchTopSubGroupsSuccess  } from "./actions";
import { setEntities, setEntity } from "./../../components/entities/actions";

const isComputerSystemContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER &&
    !entity.masterElementGroup
  );
};
const isInterfaceContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER &&
    entity.masterElementGroup
  );
};
const getEntities = (group) => {
  let entityKey = isComputerSystemContainer(group)
    ? "computer-systems"
    : isInterfaceContainer(group)
    ? "interfaceSystems"
    : "groups";
  if (entityKey == "interfaceSystems" && !group.interfaceSystems) {
    entityKey = "interfaceSystem";
  }
  const entityType = isComputerSystemContainer(group)
    ? entityTypes.COMPUTER_SYSTEM
    : isInterfaceContainer(group)
    ? entityTypes.INTERFACES
    : entityTypes.GROUP;
  const items =
    group[entityKey] &&
    group[entityKey].map((item) => {
      return Object.assign({}, item, {
        entityType: entityType,
      });
    });
  return items;
};
const getEntity = (group) =>
  Object.assign({}, group, {
    entityType: entityTypes.GROUP,
  });

function* fetchGroups(params) {
  try {
    const response = yield call(Group.get, params);
    yield put(fetchGroupsSuccess(response.data));
    const entities = getEntities(response.data);
    yield put(setEntities(entities));
    //if (params.fromTree) {
      yield put(setEntity(getEntity(response.data)));
    //}
    return response;
  } catch (error) {
   // console.log("error in fetching data", error);
    yield put(fetchGroupsFailure(error));
  }
}

function* pollGroups(action) {
  if (action.fromTree) {
    yield call(fetchGroups, { id: action.id, fromTree: action.fromTree });
  }
  while (true) {
    yield delay(30000);
    yield call(fetchGroups, { id: action.id });
  }
}

export function* groupsPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_GROUPS_START, pollGroups);
    // cancel polling on POLL_GROUPS_STOP
    yield take(POLL_GROUPS_STOP);
    yield cancel(watcherInstance);
  }
}

function* fetchGroupSetEntities(action) {
  try {
    const response = yield call(Group.get, action.params);
    const group = response.data;
    yield put(fetchGroupsSuccess(group));
    yield put(setEntities(getEntities(group)));
    yield put(setEntity(getEntity(group)));
    return response;
  } catch (error) {
    yield put(fetchGroupsFailure(error));
  }
}

export function* fetchGroupSetEntitiesSaga() {
  yield takeLatest(REQUEST_GROUP, fetchGroupSetEntities);
}


 //------------------------------------ TopSubGroups Polling Functions----------------------------
 function* fetchTopSubGroupspoll(params) {
      
  try {
    const response = yield call(Group.getTopSubGroupsByDeviceCount, params)
    yield put(fetchTopSubGroupsSuccess(response.data))
  } catch (error) {
    yield put(fetchTopSubGroupsFailure(error))
  }
}

function* pollTopSubGroups(action) {
  while (true) {
    yield call(fetchTopSubGroupspoll,{ 
      groupId:action.groupId,
    })
    yield delay(30000)
    
  }
}

export function* topSubGroupsRequestSaga() {
  yield takeEvery(FETCH_TOP_SUB_GROUP, fetchTopSubGroupspoll)
}

export function* topSubGroupsPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_TOP_SUB_GROUP_START, pollTopSubGroups)
    // cancel polling on POLL_HUBS_STOP
    yield take(POLL_TOP_SUB_GROUP_STOP)
    yield cancel(watcherInstance)
  }
}