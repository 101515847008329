import {
    REQUEST_DEVICES,
    REQUEST_DEVICE_PROFILES, REQUEST_PROFILE_BY_PROFILEID
} from './actionTypes'
import {
    call,
    put,
    takeEvery,
} from 'redux-saga/effects'
import { Devices as deviceApi } from './devices'
import {
    fetchDevicesSuccess, fetchDevicesFailure,
    fetchDeviceProfilesFailure, fetchDeviceProfilesSuccess,
    fetchProfileByProfileIdSuccess, fetchPofileByProfileIdFailure

} from './actions'


function* fetchDevicesForSaga(action) {
    try {
        const response = yield call(deviceApi.getDevices, action.options, action.groupId)
        yield put(fetchDevicesSuccess(response.data))
    } catch (error) {
        yield put(fetchDevicesFailure(error));
    }
}

export function* devicesRequestSaga() {
    yield takeEvery(REQUEST_DEVICES, fetchDevicesForSaga);
}

function* fetchDeviceProfilesForSaga(action) {
    try {
        const response = yield call(deviceApi.getDeviceProfiles, action.options, action.groupId)
        yield put(fetchDeviceProfilesSuccess(response.data))
    } catch (error) {
        yield put(fetchDeviceProfilesFailure(error));
    }
}

export function* deviceProfilesRequestSaga() {
    yield takeEvery(REQUEST_DEVICE_PROFILES, fetchDeviceProfilesForSaga);
}


function* fetchDeviceProfilesByProfileId(action) {
    try {
        const response = yield call(deviceApi.getDeviceProfileByProfileId, action.profileId)
        yield put(fetchProfileByProfileIdSuccess(response.data))
    } catch (error) {
        yield put(fetchPofileByProfileIdFailure(error));
    }
}

export function* deviceProfilesByProfileIdRequestSaga() {
    yield takeEvery(REQUEST_PROFILE_BY_PROFILEID, fetchDeviceProfilesByProfileId);
}

