import { call, put, takeEvery, takeLatest, take, cancel } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { GET_WMI_CREDENTIALS,POLL_WMI_START,POLL_WMI_STOP } from './actionTypes'
import { fetchWMISuccess, fetchWMIFailure } from './actions'
import { Credentials } from './credentials'

// worker Saga: will be fired on GET_WMI_CREDENTIALS actions
function* fetchWMICreds() {
  try {
    const response = yield call(Credentials.getWMIs)
    yield put(fetchWMISuccess(response.data))
  } catch (error) {
    yield put(fetchWMIFailure(error))
  }
}

function* pollWMICreds() {
  while (true) {
    yield delay(30000)
    yield call(fetchWMICreds)
  }
}

/*
  Starts fetchWMICreds on each dispatched `GET_WMI_CREDENTIALS` action.
  Allows concurrent fetches of user.
*/
export function* getWmiSagaDetails() {
  yield takeEvery(GET_WMI_CREDENTIALS, fetchWMICreds)
}


export function* wmiPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_WMI_START, pollWMICreds)
    // cancel polling on POLL_HUBS_STOP
    yield take(POLL_WMI_STOP)
    yield cancel(watcherInstance)
  }
}

