import axios from "axios";
import config from "./../config";

export class Maintenance {
  static getMaintenance() {
    const url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getMaintenanceSchedules" :
      [config.basename, "api/schedule/getMaintenanceSchedules"].join("/");

    return axios.get(url);
  };

  static getDevicesFromSchedule(scheduleId) {
    const url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getComputerSytem_scheduleId" :
      [config.basename, `api/schedule/getComputerSystems?scheduleId=${scheduleId}`,].join("/");
    return axios.get(url);
  };

  static saveMaintenance(credentialObj) {
    return axios.post(
      [config.basename, "api/schedule/addMaintenanceSchedule"].join("/"),
      credentialObj
    );
  };

  static saveMaintenanceSystems(credentialObj) {
    return axios.post(
      [config.basename, "api/schedule/addMaintenanceSystems"].join("/"),
      credentialObj
    );
  };

  static editMaintenance(credentialObj) {
    return axios.post(
      [config.basename, "api/schedule/editMaintenanceSchedule"].join("/"),
      credentialObj
    );
  };

  static deleteMaintenance(id) {
    return axios.delete(
      [config.basename, "api/schedule/deleteMaintenanceSchedule/" + id].join(
        "/"
      )
    );
  };

  static deleteMaintenanceSystem(scheduleId) {
    return axios.delete(
      [
        config.basename,
        "api/schedule/removeMaintenanceSystems?scheduleId=" + scheduleId,
      ].join("/")
    );
  };

  static endMaintenance(scheduleId) {
    let requestBody = {
      schedule_id: scheduleId,
      hierarchy: "parent",
    };
    return axios.delete(
      [config.basename, "api/schedule/endMaintenance"].join("/"),
      { data: requestBody }
    );
  };

  static getTimeZones() {
    const url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getTimezones" :
      [config.basename, "api/schedule/getTimeZones"].join("/");

    return axios.get(url);
  };

  static getScheduleForDevices(csId) {
    var url = process.env.NODE_ENV == "development" ?
      'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getScheduleForDevices' :
      [config.basename, `api/schedule/computerSystemMaintenanceSchedules/${csId}`,].join("/");

    return axios.get(url);
  };

  static removeDeviceForSchedule(scheduleId, csId) {
    return axios.get(
      [
        config.basename,
        `api/schedule/removeComputerSystemMaintenanceSchedule/${scheduleId}/${csId}`,
      ].join("/")
    );
  };
};
