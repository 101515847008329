import axios from 'axios'
import configPath from './config.JSON'

let url = configPath

// for test and prod
if (process.env.NODE_ENV !== 'development') {
  url = configPath.replace('./', './jsp/')
}

export class Config {
  static get() {
    return axios.get(url)
  };
};
