import React, { Component } from "react";
import {
  CenterError as Error,
  ClearIcon as Clear,
} from "../../ui-components/uim-components";
import { CircularProgress } from "@mineral/core";
import { Card, CardContent, IconButton } from "@mineral/core";
import Resource from "./Resource";
import {
  fetchActiveDevicespoll,
  pollActiveDevicesStop,
} from "./../../../api/home-screen/actions";
import connect from "./../../../utils/connect";

import { Tooltip as MTooltip } from "@mineral/core";
//Monitored Devices
const title = Resource.get("Monitored Devices");
const Panel = (props) => {
  const monitoredDevices = props.monitoredDevices;
  //Last 30 minutes
  let minuteText = Resource.get("Last 30 minutes");

  return (
    <CardContent
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f4f4f4",
        padding: "0px",
        flex: "90%",
      }}
    >
      <span role="text" aria-label={monitoredDevices + " " + title} className="monitored__devices--count">{monitoredDevices}</span>
      <label aria-label={minuteText} className="monitored__devices--label">{minuteText}</label>
    </CardContent>
  );
};

class PanelContainer extends Component {
  state = {
    status: "loading",
    discoveredDevices: 0,
    monitoredDevices: 0,
  };
  apiError = () => {
    this.setState({
      status: "failure",
    });
  };

  componentDidMount() {
    this.props.fetchActiveDevicespoll();
  }
  componentWillUnmount() {
    this.props.pollActiveDevicesStop();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      discoveredDevices:
        nextProps.data.length > 0
          ? nextProps.data[0].totalDiscoveredDeviceCount
          : 0,
      monitoredDevices:
        nextProps.data.length > 0
          ? nextProps.data[0].activelyMonitoredDeviceCount
          : 0,
      status: "complete",
    });
  }
  render() {
    let view = (
      <Panel
        className={this.props.className}
        monitoredDevices={this.state.monitoredDevices}
      />
    );


    //Discovered
    let discoveredText = Resource.get("Discovered");
    let cardHeader = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flex: "10%",
        }}
      >
        <h2 className="overview_container_title">{title}</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ margin: "0px 10px", fontSize: "14px" }} aria-label={discoveredText}>
            {discoveredText}
          </span>
          <span style={{ fontSize: "14px", fontWeight: "bold" }} aria-label={this.state.discoveredDevices}>
            {this.state.discoveredDevices}
          </span>
          <span className="overview_header_seprator" aria-label="Separator">|</span>
          <MTooltip title="Close">
            <IconButton
              style={{ padding: 0 }}
              aria-label="Close Monitored Devices"
              onClick={() => {
                this.props.handleCloseDialog("showActiveDevices");
              }}
            >

              <Clear className="clearIcon" role="button"
                title="Close" />
            </IconButton>
          </MTooltip>
        </div>
      </div>
    );
    if (
      this.state.status === "loading" &&
      !this.props.didActiveDevicesInvalidate
    ) {
      view = (
        <div
          className="no_data_panel"
          style={{
            height: `${this.props.homeScreenComponents.showDevicesByRoles ? "43%" : "80%"
              }`,
            fontSize: "0.5rem",
            flex: "90%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.status === "failure" ||
      this.props.didActiveDevicesInvalidate
    ) {
      view = (
        <div
          className="no_data_panel"
          style={{
            height: `${this.props.homeScreenComponents.showDevicesByRoles ? "43%" : "80%"
              }`,
            fontSize: "0.5rem",
            flex: "90%",
          }}
        >
          <Error />
        </div>
      );
    }
    return (
      <Card
        className={this.props.className}
        // style={{
        //   // height: null,
        //   width: null,
        //   margin: null,
        // }}
        style={{
          height: this.props.homeScreenComponents.showDevicesByRoles
            ? "15vh"
            : window.innerHeight < 800
              ? "36vh"
              : window.innerHeight == 800
                ? "38vh"
                : window.innerHeight < 1080
                  ? "39vh"
                  : "40vh",
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
          padding: "16px",
          boxSizing: "border-box"
        }}
      >
        {cardHeader}
        {view}
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActiveDevicespoll: () => dispatch(fetchActiveDevicespoll()),
    pollActiveDevicesStop: () => dispatch(pollActiveDevicesStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    data: state.homeScreen.activeDevices,
    isFetching: state.homeScreen.isFetching,
    didActiveDevicesInvalidate: state.homeScreen.didActiveDevicesInvalidate,
    homeScreenComponents: state.homeScreen.homeScreenComponents,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer); //connect so that we can get a handle on history
