import {
	GET_PERFORMANCE_REPORTS_HOSTS_BY_TARGET,
	GET_PERFORMANCE_REPORTS_HOST_BY_HOST,
	GET_PERFORMANCE_REPORTS_QOS_BY_HOST,
	GET_PERFORMANCE_REPORTS_QOS_BY_TARGET,
	GET_PERFORMANCE_REPORTS_TARGETS_BY_HOST,
	GET_PERFORMANCE_REPORTS_TARGET_BY_TARGET,
	SET_PRD_SOURCE,
	SET_TIME_FRAME,
	CLEAR_QOS_SELECTION_DATA,
	EXPAND_CHART,
	ADD_NEW_SERIES,
	ADD_NEW_CHART,
	SET_CHART_TITLE,
	SET_REPORT_TITLE,
	DELETE_CHART,
	SET_TIME_INTERVAL,
	SET_AGGREGATION_LEVEL,
	SET_IS_INTERPOLATION,
	UPDATE_IMPORTED_XML_DATA,
	SET_NEW_PREPARED_CHARTS,
	SET_REPORT_PATH_VISIBILITY,
	SET_CHART_FILTER,
	SET_SAVE_STATUS,
	SET_MIN_MAX_YAXIS,
	SET_NEW_PREPARED_FILTER_CHARTS,
	SET_STATUS_MESSAGE,
	UPDATE_CHART_ORDER,
	SET_DATA_COUNT,
} from "./actionTypes";
import { cloneDeep } from "lodash";
import { uniqueChartId } from "../../components/settings/portlets/performanceReports/PerformanceLayout/qosData/PerformanceQosData";

const initialState = {
	byHost: {
		host: "",
		qos: "",
		targets: [],
	},
	byTarget: {
		qos: "",
		target: "",
		hosts: [],
	},
	selectedSource: "host",
	saved: false,
	chartData: {
		State: {
			helpPath: "QoS Performance Data",
			path: "QoS Performance Data",
			visibility: {
				type: "private",
				owner: "private",
			},
			title: "QoS Performance Data",
			timeFrame: "Last Hour",
			TimeStart: (Math.round(new Date().getTime() / 1000) - 1 * 3660) * 1000,
			TimeStop: Math.round(new Date().getTime() / 1000 - 60) * 1000,
			liveData: "true",
			aggLevel: "Automatic",
			aggregationInterval: "0",
			isInterpolation:false,
			lastPeriod: "NaN",
			TimeSliders: "2,28,0,5",
			dynVals: "true",
			GraphMaximized: "-1",
			groups: {
				group: [],
			},
			selectedPage: "0",
			page: {
				row: [
					{
						chart: [
							{
								minYAxis1: "0",
								maxYAxis1: "0",
								minYAxis2: "0",
								maxYAxis2: "0",
								trend: "false",
								baseline: "false",
								stacked: "false",
								showLegend: "false",
								legend: {
									table: "true",
									source: "true",
									target: "true",
									min: "true",
									max: "true",
									last: "false",
									total: "false",
									average: "true",
									dev: "true",
									percentile: "true",
								},
								title: "Chart Title-1",
								chartId: uniqueChartId(),
								infoText: "",
								series: [],
								rowId: 0,
							},
						],
					},
				],
			},
			_ver: "204",
		},
	},
	newPreparedCharts: null,
	filteredCharts: null,
	dataCount: 0,
	selectedCombos: [],
};

const performanceReports = (state = cloneDeep(initialState), action) => {
	switch (action.type) {
		case SET_PRD_SOURCE:
			return {
				...state,
				selectedSource: action.payload,
			};
		case GET_PERFORMANCE_REPORTS_HOST_BY_HOST:
			return {
				...state,
				byHost: {
					...state.byHost,
					host: action.payload,
				},
			};

		case GET_PERFORMANCE_REPORTS_QOS_BY_HOST:
			return {
				...state,
				byHost: {
					...state.byHost,
					qos: action.payload,
				},
			};

		case GET_PERFORMANCE_REPORTS_TARGETS_BY_HOST:
			return {
				...state,
				byHost: {
					...state.byHost,
					targets: action.payload,
				},
			};

		case GET_PERFORMANCE_REPORTS_QOS_BY_TARGET:
			return {
				...state,
				byTarget: {
					...state.byTarget,
					qos: action.payload,
				},
			};
		case GET_PERFORMANCE_REPORTS_TARGET_BY_TARGET:
			return {
				...state,
				byTarget: {
					...state.byTarget,
					target: action.payload,
				},
			};
		case GET_PERFORMANCE_REPORTS_HOSTS_BY_TARGET:
			return {
				...state,
				byTarget: {
					...state.byTarget,
					hosts: action.payload,
				},
			};
		case SET_TIME_FRAME:
			return {
				...state,
				// timeFrame:action.payload.timeFrame,
				chartData: {
					State: {
						...state.chartData.State,
						timeFrame: action.payload.timeFrame,
						TimeStart: action.payload.timeRange
							? action.payload.timeRange.startTime
							: (Math.round(new Date().getTime() / 1000) - 1 * 3600) * 1000,
						TimeStop: action.payload.timeRange
							? action.payload.timeRange.stopTime
							: Math.round(new Date().getTime() / 1000) * 1000,
					},
				},
			};
		case CLEAR_QOS_SELECTION_DATA:
			const newState = cloneDeep(initialState);
			newState.chartData.State.TimeStart =
				(Math.round(new Date().getTime() / 1000) - 1 * 3600) * 1000;
			newState.chartData.State.TimeStop =
				Math.round(new Date().getTime() / 1000) * 1000;
			return newState;
		case EXPAND_CHART:
			return {
				...state,
				chartData: {
					State: { ...state.chartData.State, GraphMaximized: action.payload },
				},
			};
		case UPDATE_IMPORTED_XML_DATA:
			return {
				...state,
				chartData: {
					State: {
						...action.payload.State,
						page: action.payload.State.page,
						timeFrame: action.payload.State.timeFrame
							? action.payload.State.timeFrame
							: "Last Hour",
						aggLevel: action.payload.State.aggLevel
							? action.payload.State.aggLevel
							: "Automatic",
					},
				},
			};
		case SET_TIME_INTERVAL:
			return {
				...state,
				chartData: {
					State: {
						...state.chartData.State,
						TimeStart: action.payload.startTime,
						TimeStop: action.payload.stopTime,
					},
				},
			};
		case ADD_NEW_SERIES:
			return {
				...state,
				chartData: {
					State: { ...state.chartData.State, page: { row: action.payload } },
				},
			};
		case ADD_NEW_CHART:
			return {
				...state,
				chartData: {
					State: { ...state.chartData.State, page: { row: action.payload } },
				},
			};

		case SET_CHART_TITLE:
			return {
				...state,
				chartData: {
					State: { ...state.chartData.State, page: { row: action.payload } },
				},
			};
		case UPDATE_CHART_ORDER:
			return {
				...state,
				chartData: {
					State: { ...state.chartData.State, page: { row: action.payload } },
				},
			};
		case DELETE_CHART:
			return {
				...state,
				chartData: {
					State: { ...state.chartData.State, page: { row: action.payload } },
				},
			};
		case SET_REPORT_TITLE:
			return {
				...state,
				chartData: {
					State: {
						...state.chartData.State,
						title: action.payload.title,
						path: action.payload.path,
						helpPath: action.payload.helpPath,
					},
				},
			};
		case SET_REPORT_PATH_VISIBILITY:
			return {
				...state,
				chartData: {
					State: {
						...state.chartData.State,
						path: action.payload.path,
						helpPath: action.payload.helpPath,
						visibility: action.payload.visibility,
					},
				},
			};
		case SET_AGGREGATION_LEVEL:
			return {
				...state,
				// aggLevel:action.payload.aggLevel,
				chartData: {
					State: {
						...state.chartData.State,
						aggregationInterval: action.payload.aggLevelSeconds,
						aggLevel: action.payload.aggLevel,
					},
				},
			};
			case SET_IS_INTERPOLATION:
				return {
					...state,
					// aggLevel:action.payload.aggLevel,
					chartData: {
						State: {
							...state.chartData.State,
							isInterpolation: action.payload.isInterpolation,
						},
					},
				};			
		case SET_MIN_MAX_YAXIS:
			return {
				...state,
				// aggLevel:action.payload.aggLevel,
				chartData: {
					State: {
						...state.chartData.State,
						minYAxis: action.payload.minYAxis,
						maxYAxis: action.payload.maxYAxis,
					},
				},
			};
		case SET_NEW_PREPARED_CHARTS:
			return {
				...state,
				newPreparedCharts: action.newPreparedCharts,
			};
		case SET_NEW_PREPARED_FILTER_CHARTS:
			return {
				...state,
				filteredCharts: action.filteredCharts,
			};
		case SET_CHART_FILTER:
			return {
				...state,
				chartData: {
					State: { ...state.chartData.State, groups: action.payload },
				},
			};
		case SET_SAVE_STATUS: {
			return {
				...state,
				saved: action.saved,
			};
		}
		case SET_STATUS_MESSAGE:
			return {
				...state,
				message: action.payload
			}
		case SET_DATA_COUNT: {			
			return {
				...state,
				dataCount: action.action.dataCount,
				selectedCombos: action.action.selectedCombos,
			};
		}
		default:
			return state;
	}
};

export default performanceReports;
