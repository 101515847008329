import React, { Component } from "react";
import {
  CenterError as Error,
  Grid,
  FilterIcon,
} from "../ui-components/uim-components";
import { TextField, Tooltip } from "@mineral/core";
import {CircularProgress} from "@mineral/core";

import filter from "lodash/filter";
import get from "lodash/get";
import entityTypes from "./../../api/entityTypes";
import isEqual from "lodash/isEqual";
import { sortColumns } from "../../utils/tableUtils";
import { RowSelectionCache as selectionCacheApi } from "../../utils/rowSelectionCache";
import Resource from "./Resource";
import "./paginates.less";
import "./StaticGroupGrid.less";
import { Group as groupApi } from "./../../api/groups/groups";
//Origin Text
const OriginText = Resource.get("Origin");
class AddDeviceToGroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      totalSet: [],
      disableAddButton: true,
      pageNumber: 0,
      matchingDevices: [],
      value: "",
      entities: [],
      elementType: props.elementType,
      accountId: props.accountId,
      tooltipShowDelay: 0,
    };
    this.rowData = [];
    this.updateRowSelectionCache = true;
    this.masterColumns = [
      {
        headerName: "",
        field: "checkbox",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressMenu: true,
        suppressSorting: true,
        suppressResize: true,
        suppressSizeToFit: true,
        width: 50,
        suppressMovable: true,
        lockPosition: true,
        suppressNavigable:true,
      },

      {
        //Name
        headerName: Resource.get("Name"),
        field: "attributes.DisplayName",
        tooltipField: "Name",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //Alias message
        headerName: Resource.get("Alias"),
        field: "attributes.DisplayAlias",
        tooltipField: "Display Alias",
        resizable: true,
        width: 150,
        sortable: true,
        suppressNavigable:true,
      },
      {
        //Device
        headerName: Resource.get("Device"),
        field: "attributes.name",
        tooltipField: "attributes.name",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },

      {
        //If Index
        headerName: Resource.get("If Index"),
        field: "attributes.IfIndex",
        resizable: true,
        tooltipField: "attributes.IfIndex",
        suppressMovable: true,
        suppressResize: false,
        width: 150,
        suppressSizeToFit: false,
        sortable: true,
        suppressNavigable:true,
      },
      {
        //IfPhysAddress
        headerName: Resource.get("If Physical Address"),
        field: "attributes.IfPhysAddress",
        tooltipField: "attributes.IfPhysAddress",
        suppressMovable: true,
        suppressResize: false,
        width: 150,
        suppressSizeToFit: false,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //IfType
        headerName: Resource.get("If Type"),
        field: "attributes.IfType",
        suppressMovable: true,
        suppressResize: false,
        width: 150,
        sortable: true,
        suppressSizeToFit: false,
        resizable: true,
        suppressNavigable:true,
      },
      {
        headerName: OriginText,
        field: "attributes.Origin",
        tooltipField: "attributes.Origin",
        suppressMovable: true,
        suppressResize: false,
        width: 150,
        suppressSizeToFit: false,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //instanceId
        headerName: Resource.get("Instance Id"),
        field: "instanceId",
        tooltipField: "instanceId",
        width: 150,
        suppressSizeToFit: false,
        resizable: true,
        suppressNavigable:true,
      },
    ];
    this.csColsCheckbox = [
      {
        headerName: "",
        //field: "",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        suppressMenu: true,
        suppressSorting: true,
        suppressResize: true,
        suppressSizeToFit: true,
        width: 50,
        lockPosition: true,
      },
      {
        //Name
        headerName: Resource.get("Name"),
        field: "attributes.DisplayName",
        tooltipField: "Name",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //Alias message
        headerName: Resource.get("Alias"),
        field: "attributes.DisplayAlias",
        tooltipField: "Display Alias",
        sortable: true,
        width: 150,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //IPaddress message
        headerName: Resource.get("IP address"),
        field: "attributes.PrimaryIPV4Address",
        width: 150,
        resizable: true,
        valueGetter: (model) => {
          let data = model.data;
          let ips = "";
          if(data.attributes?.PrimaryIPV4Address)
          for (let ip in data.attributes.PrimaryIPV4Address) {
            if (data.attributes.PrimaryIPV4Address.hasOwnProperty(ip))
              ips += data.attributes.PrimaryIPV4Address[ip] + ", ";
          }
          if (ips.length > 0) ips = ips.substring(0, ips.length - 2);
          return ips;
        },
        tooltipField: "attributes.PrimaryIPV4Address",
        sortable: true,
        suppressNavigable:true,
      },
      {
        //Caption message
        headerName: Resource.get("Caption"),
        field: "attributes.caption",
        tooltipField: "Caption",
        sortable: true,
        width: 150,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //Description message
        headerName: Resource.get("Description"),
        field: "attributes.description",
        tooltipField: "Description",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //Role message
        headerName: Resource.get("Role"),
        field: "role",
        tooltipField: "role",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //OSType message
        headerName: Resource.get("OS Type"),
        field: "attributes.os_type",
        tooltipField: "attributes.os_type",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //OSName message
        headerName: Resource.get("OS Name"),
        field: "attributes.os_name",
        tooltipField: "osName",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //OSVersion message
        headerName: Resource.get("OS Version"),
        field: "attributes.os_version",
        tooltipField: "osVersion",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //OSDescription message
        headerName: Resource.get("OS Description"),
        field: "attributes.OSDescription",
        tooltipField: "attributes.OSDescription",
        sortable: true,
        width: 150,
        resizable: true,
        suppressNavigable:true,
      },
      {
        headerName: OriginText,
        field: "origins",
        tooltipField: "origins",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //MacAddress message
        headerName: Resource.get("MacAddress"),
        field: "attributes.PrimaryMacAddress",
        tooltipField: "attributes.PrimaryMacAddress",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //User Tag 1 message
        headerName: Resource.get("User Tag 1"),
        field: "attributes.user_tag_1",
        tooltipField: "attributes.user_tag_1",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
      {
        //User Tag 2 message
        headerName: Resource.get("User Tag 2"),
        field: "attributes.user_tag_2",
        tooltipField: "attributes.user_tag_2",
        width: 150,
        sortable: true,
        resizable: true,
        suppressNavigable:true,
      },
    ];
    this.prevSelection = [];
  }
  componentDidMount() {
    this.props.rowSelectionRef(selectionCacheApi);
    if (
      this.props.elementType == "INTERFACES" &&
      !(this.props.mode == "ADD" || this.props.mode == "ADD Group")
    ) {
      this.findInterfaces(this.props.accountId);
    } else {
      this.setData(this.props);
    }
  }
  findInterfaces(accountId) {
    groupApi
      .findInterfaces({ groupId: this.props.entity.id.toString() }, accountId)
      .then(
        (response) => {
          this.setState({
            interfaceEntities: response.data._items,
          });
          setTimeout(() => {
            this.setData(this.props);
          }, 50);
        },
        (error) => {
          this.setState({
            interfaceEntities: [],
          });
          setTimeout(() => {
            this.setData(this.props);
          }, 50);
        }
      );
  }
  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.accountId, this.state.accountId)) {
      this.setState({ accountId: nextProps.accountId });
    }
    if (
      !isEqual(
        nextProps.matchingDevicesInfo.matchingDevices,
        this.state.matchingDevices
      )
    ) {
      if (
        nextProps.elementType == "INTERFACES" &&
        !(nextProps.mode == "ADD" || nextProps.mode == "ADD Group")
      ) {
        this.findInterfaces(nextProps.accountId);
      } else {
        this.setData(nextProps);
      }
    }
  }

  setData(currentProps) {
    this.criteriaDevices = currentProps.matchingDevicesInfo.matchingDevices;
    if (this.criteriaDevices == null || this.criteriaDevices == undefined) {
      this.criteriaDevices = [];
    }
    if (currentProps.mode == "ADD" || currentProps.mode == "ADD Group") {
      if (this.state.items.length !== this.criteriaDevices.length) {
        this.setState({
          items: this.criteriaDevices,
          totalSet: this.criteriaDevices,
          entities: currentProps.entities,
          matchingDevices: this.criteriaDevices,
        });
        setTimeout(() => {
          this.sortColumns("attributes.DisplayName", "asc");
        }, 50);
      }
    } else {
      if (this.criteriaDevices.length == 0) {
        if (currentProps.elementType == "INTERFACES") {
          this.items = this.state.interfaceEntities;
        } else {
          this.items = currentProps.entities;
        }
        if (this.state.items.length !== this.items.length) {
          this.setState({
            items: this.items,
            totalSet: this.items,
            entities:
              currentProps.elementType == "INTERFACES"
                ? this.state.interfaceEntities
                : currentProps.entities,
            matchingDevices: this.criteriaDevices,
          });
        }
      } else {
        if (currentProps.elementType == "INTERFACES") {
          this.setInterfaceDetails("", this.state.interfaceEntities);
          this.setState({
            entities: this.state.interfaceEntities,
            matchingDevices: this.criteriaDevices,
          });
        } else {
          this.items = currentProps.entities;
          var differentDevices = this.criteriaDevices.filter(
            (data) => this.items.findIndex((cd) => cd.id == data.id) == -1
          );
          this.items = [...this.items, ...differentDevices];
          if (this.state.items.length !== this.items.length) {
            this.setState({
              items: this.items,
              totalSet: this.items,
              entities: currentProps.entities,
              matchingDevices: this.criteriaDevices,
            });
          }
        }
      }
      // setTimeout(() => {
      //   this.sortColumns("attributes.DisplayName", "asc");
      // }, 50);
    }
    setTimeout(() => {
      this.api &&
        this.api.forEachNode((node) => {
          if (
            this.state.entities.findIndex(
              (entity) =>
                entity.id === node.data.id && entity.entityType !== "GROUP"
            ) !== -1 &&
            !selectionCacheApi.getUnselectedData().includes(node.data.id)
          ) {
            node.setSelected(true);
          }
        });
    }, 0);
  }
  setInterfaceDetails(filter, entities) {
    this.items = entities;

    var differentDevices = this.criteriaDevices.filter(
      (data) => this.items.findIndex((cd) => cd.id == data.id) == -1
    );
    this.items = [...this.items, ...differentDevices];
    if (this.state.items.length !== this.items.length) {
      this.setState({
        items: this.items,
        totalSet: this.items,
      });
    }
  }
  getEntities = (computerSystems) => {
    const items = get(computerSystems, "_items", []).map((item) => {
      return Object.assign({}, item, {
        entityType: entityTypes.COMPUTER_SYSTEM,
      });
    });
    return items;
  };
  getInterfaceEntities = (Interfaces) => {
    const items = get(Interfaces, "_items", []).map((item) => {
      return Object.assign({}, item, {
        entityType: entityTypes.INTERFACES,
      });
    });
    return items;
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value });
    this.updateRowSelection();
  };
  rowDataChanged = () => {
    this.updateRowSelection();
  };
  onKeyUp = (evt) => {
    if (evt.which === 27) {
      this.setState({ value: "" });
    } else {
      const value = evt.target.value;
      // if (this.props.elementType=="INTERFACES") {
      //   this.setInterfaceDetails(value, this.state.entities);
      // } else {
      let filteredItems = filter(this.state.totalSet, (card) => {
        var stringToCheckAgainst = "";
        let ipAddressString = card.attributes?.PrimaryIPV4Address
          ? card.attributes?.PrimaryIPV4Address[0]
          : "";
        stringToCheckAgainst =
          card.attributes.DisplayName[0] + " " + ipAddressString;
        return (
          stringToCheckAgainst.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });
      this.setState({
        items: filteredItems,
        pageNumber: 0,
      });
      //  }
    }
  };

  onGridReady = (params) => {
    this.api = params.api;
    this.api.sizeColumnsToFit();
    setTimeout(() => {
      this.api.forEachNode((node) => {
        if (
          this.state.entities.findIndex(
            (entity) => entity.id == node.data.id
          ) != -1
        ) {
          node.setSelected(true);
        }
      });
    }, 500);
    this.updateRowSelection();
  };

  onSelectionChanged = () => {
    if (this.updateRowSelectionCache) {
      this.handleRowSelectionCache();
    }
  };

  sortColumns = (column, order) => {
    const newSortedData = sortColumns(column, order, this.state.items);
    this.setState({
      items: newSortedData || [],
      pageNumber: 0,
    });
    this.updateRowSelection();
  };

  updateRowSelection = () => {
    setTimeout(
      function () {
        if (this.api) {
          this.updateRowSelectionCache = false;
          selectionCacheApi.updateOnRowDataChanged(this.api);
          this.updateRowSelectionCache = true;
        }
      }.bind(this),
      0
    );
  };

  handleRowSelectionCache = () => {
    if (this.api) {
      selectionCacheApi.onChange(this.api);
      if (
        !isEqual(this.prevSelection.sort(), selectionCacheApi.getData().sort())
      ) {
        this.prevSelection = [...selectionCacheApi.getData()];
        if (selectionCacheApi.getData().length > 0) {
          this.props.onSelectedDevicesChanged(
            selectionCacheApi.getData(),
            selectionCacheApi.getUnselectedData()
          );
          this.setState({ disableAddButton: false });
        } else {
          this.props.onSelectedDevicesChanged(
            selectionCacheApi.getData(),
            selectionCacheApi.getUnselectedData()
          );
          this.setState({ disableAddButton: true });
        }
      }
    }
  };
  handlePageClick = (page) => {
    this.setState({
      pageNumber: page.selected,
    });
    setTimeout(() => {
      this.api &&
        this.api.forEachNode((node) => {
          if (
            this.state.entities.findIndex(
              (entity) =>
                entity.id === node.data.id && entity.entityType !== "GROUP"
            ) !== -1
          ) {
            node.setSelected(true);
          }
        });
    }, 0);
  };
  render() {
    let dialogContent;

    if (this.props.didInvalidate) {
      dialogContent = <Error />;
    } else if (this.props.isFetching) {
      dialogContent = (
        <div
          style={{
            height: "calc(100vh - 205px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else {
      let heightPx = this.props.showCriteria
        ? "calc(100vh - 478px)"
        : "calc(100vh - 370px)";
      this.rowData = this.state.items;
      //Matching Devices
      let matchingDevicesLabel = Resource.get("Matching Devices");
      //  let overlayMessageTemplate = this.buildErrorMessageTemplate();
      let found = Resource.get("Found"); //Found
      let noOfDevices = this.state.items ? this.state.items.length : 0;
      let countLabel = noOfDevices + " " + found;
      let filterContent = (
        <div className="addDevice__filterBox">
          <div>
            
            <Tooltip arrow title={"Search for Computer Systems"}>
              <TextField variant="outlined"
                name="q"
                //SearchforComputerSystems message
                placeholder={Resource.get("Search for Computer Systems")}
                value={this.state.value}
                onChange={this.handleChange}
                onKeyUp={(evt) => this.onKeyUp(evt)}
                style={{ width: "300px" ,marginLeft:'3px'}}
                inputProps={{
                  "aria-label": Resource.get("Search for Computer Systems"), //SearchforComputerSystems message
                }}
                sx={{
   input: {
    
    "&::placeholder": {  // <----- Add this.
      color: 'black !important',
      opacity:0.8
     
    },
   }}}
                InputProps={{endAdornment:(<FilterIcon className="addDevice__filterBox--icon" />)}}
              />
            </Tooltip>
          </div>
          <div
            className="matching-devices-table"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {noOfDevices > 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  id="matching-devices-label"
                  className="section-header"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {matchingDevicesLabel}
                </div>

                <div
                  id="matching-devices-count-label"
                  className="matching-devices-count-header"
                >
                  {countLabel}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );

      dialogContent = (
        <div className="static-group-grid">
          {filterContent}
          <div 
            id="matching-devices-ag-grid"
            className="ag-material tablecontainer"
            style={{ height: heightPx, width: "100%", marginLeft: "16px" }}
          >
            <Grid
              rowHeight={36}
              headerHeight={32}
              rowData={this.rowData}
              // quickFilterText={this.state.value}
              columnDefs={
                this.props.elementType == "INTERFACES"
                  ? this.masterColumns
                  : this.csColsCheckbox
              }
              enableColResize={true}
              onGridReady={this.onGridReady}
              onRowDataChanged={this.rowDataChanged}
              rowSelection="multiple"
              suppressRowHoverClass={false}
              onSelectionChanged={this.onSelectionChanged}
              suppressRowClickSelection={true}
              tooltipShowDelay={this.state.tooltipShowDelay}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          width: "100%",
          margin: "0 auto",
          pointerEvents: this.props.type == "AUTOMATIC" && 'none',
          opacity: this.props.type == "AUTOMATIC" && '0.5'
        }}
      >
        <div>{dialogContent}</div>
      </div>
      // </Dialog>
    );
  }
}

export default AddDeviceToGroupDialog;
