import {
  call,
  put,
  takeEvery,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import {
  FETCH_PROBES,
  POLL_PROBES_START,
  POLL_PROBES_STOP,
} from './actionTypes'
import { fetchProbesSuccess, fetchProbesFailure } from './actions'
import { ProbesList } from './probesList'
// worker Saga: will be fired on REQUEST_HUBS actions
function* fetchProbespoll(params) {
 
  try {
    const response = yield call(ProbesList.fetchProbeDetails, params)
    yield put(fetchProbesSuccess(response.data))
  } catch (error) {
    yield put(fetchProbesFailure(error))
  }
}

function* pollProbes(action) {
  while (true) {
    yield delay(30000)
    yield call(fetchProbespoll,  {count:action.count,useEnhancedflag: action.useEnhancedflag ,categories:action.categories})
  }
}

/*
  Starts fetchHubs on each dispatched `REQUEST_HUBS` action.
  Allows concurrent fetches of user.
*/
export function* probesRequestSaga() {
  yield takeEvery(FETCH_PROBES, fetchProbespoll,{count:false,useEnhancedflag: false ,categories:'NON_LOCAL'})
}

export function* probesPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_PROBES_START, pollProbes)
    // cancel polling on POLL_HUBS_STOP
    yield take(POLL_PROBES_STOP)
    yield cancel(watcherInstance)
  }
}
