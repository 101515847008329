import axios from 'axios';
import config from './../config';

export class Credentials {
  static getWMI() {
    return axios.get([config.basename, 'api/v2/groups/Tree'].join('/'));
  };

  static getUNIX() {
    return axios.get([config.basename, 'api/v1/credentials/shell'].join('/'));
  };

  static saveWMI(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  };

  static editWMI(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  };

  static saveUNIX(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  };

  static editUNIX(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  };

  static deleteWMI(id) {
    return axios.delete([config.basename, 'api/v1/credentials/wmi/' + id].join('/'));
  };

  static  deleteUNIX(id) {
    return axios.delete([config.basename, 'api/v1/credentials/shell/' + id].join('/'));
  };

  static deleteCredential(id, type) {
    if (type === 'WMI') {
      return this.deleteWMI(id)
    } else if (type === 'UNIX') {
      return this.deleteUNIX(id)
    }
  };
};
